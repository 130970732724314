import styled from 'styled-components';
import StyledCollapse from 'src/styles/styledComponents/styledCollapse';

const PlanCollapse = styled(StyledCollapse)`
  width: 100%;
  .rc-collapse-item {
    box-shadow: none;
    border-radius: 0;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(46, 42, 42, 0.41);
    }
    .rc-collapse-content-box > * {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 17px;
      gap: 10px;

      .gatsby-image-wrapper {
        margin-right: 10px;
        min-width: 40px;
        width: 40px !important;
        height: 40px !important;
      }

      > div {
        display: flex !important;
        align-items: center;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
`;
export default PlanCollapse;
