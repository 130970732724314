import React from 'react';
import styled from 'styled-components';

const InputWrapper = styled.div`
  position: relative;
  label {
    position: absolute;
    color: rgba(0, 0, 0, 0.3);
    font-size: ${(props) => (props.isFocus ? '16px' : '22px')};
    top: ${(props) => (props.isFocus ? '15px' : '50%')};
    transform: translateY(-50%);
    left: 40px;
    transition: all 0.3s;
  }
`;
export default InputWrapper;
