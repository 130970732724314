/* eslint-disable prettier/prettier */
import React from 'react';
import styled from 'styled-components';
import Collapse from 'rc-collapse';
import { responsiveWidth } from 'src/styles/constants';
import 'rc-collapse/assets/index.css';

const StyledCollapse = styled(Collapse)`
  border: none;
  font-size: 22px;
  transition: 0.5s;
  text-align: left;
  & > .rc-collapse-item > .rc-collapse-header {
    line-height: 26px !important;
    &:focus {
      outline: unset !important;
    }

    color: #000;
  }
  .rc-collapse-item {
    border: none;
    margin-bottom: ${(props) => props.bottom || '10px'};
    border-radius: 8px;
    box-shadow: ${(props) =>
    props.shadow || '0 12px 24px 0 rgba(0, 0, 0, 0.1)'};
    background-color: #fff;
    padding: 15px 20px;
    position: relative;

    &.rc-collapse-item-active {
      .collapse-arrow {
        transform: rotate(180deg);
      }
    }
  }
  .rc-collapse-content {
    max-height: 0;
    transition: max-height 0.3s;
    color: #000;

    &.rc-collapse-content-hidden {
      display: block;
    }

    &.rc-collapse-content-active {
      max-height: 500px;
      .rc-collapse-content-box > div {
        font-size: 18px;
        line-height: 21px;
        color: #515459;
      }
    }
  }
  .collapse-arrow {
    position: absolute;
    right: 10px;
    top: 25px;
    transition: 0.3s;
  }
  @media ${responsiveWidth.mobile} {
    font-size: 17px;
    .rc-collapse-item {
      padding: 10px 15px;
      .rc-collapse-header {
        padding: 0;
      }
      .collapse-arrow {
        top: 10px;
        right: 5px;
      }
    }
    .rc-collapse-content {
      padding: 0;

      &.rc-collapse-content-active {
        .rc-collapse-content-box > div {
          font-size: 14px;
          line-height: 16px;
          
        }
      }
    }
  }
`;
export default StyledCollapse;
