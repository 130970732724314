import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatterOf } from 'src/utility/helpers';
import Card from 'src/styles/styledComponents/card';
import Topic from 'src/styles/styledComponents/topic';
import NoteWithQuote from 'src/styles/styledComponents2/noteWithQuote';

const InfoSection = ({ of }) => {
  const data = useStaticQuery(graphql`
      {
        web: allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "products_web_info" } } }
        ) {
          nodes {
            id
            frontmatter {
              title
              note
              bottom_text
              image {
                childImageSharp {
                  gatsbyImageData(width: 230)
                }
              }
            }
          }
        }
        mobile: allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "products_mobile_info" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              note
              bottom_text
              image {
                childImageSharp {
                  gatsbyImageData(width: 230)
                }
              }
            }
          }
        }
        webClipper: allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "products_webclipper_info" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              note
              bottom_text
              image {
                childImageSharp {
                  gatsbyImageData(width: 230)
                }
              }
            }
          }
        }
      }
    `),
    { note, bottom_text, title } = getFrontmatterOf(data, of);
  return (
    <Card align={'center'} padding={'74px 100px'}>
      <div className={'container-info'}>
        <Topic
          size={'45px'}
          color={'#000'}
          bottom={'0'}
          dangerouslySetInnerHTML={{ __html: bottom_text }}
        />
        <div className={'info-quotes'}>
          <Topic
            color={'#000'}
            size={'36px'}
            bottom={'30px'}
            dangerouslySetInnerHTML={{ __html: title }}
            style={{ textAlign: 'left', marginLeft: '60px' }}
          />
          <NoteWithQuote
            bottom={'-34px'}
            margin={'20px 0'}
            dangerouslySetInnerHTML={{ __html: note }}
          />
        </div>
      </div>
    </Card>
  );
};

InfoSection.defaultProps = {
  of: 'mobile',
};

export default InfoSection;
