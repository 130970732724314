import React from 'react';
import Helmet from 'react-helmet';

const PageHelmet = ({ title, description, children }) => (
  <Helmet htmlAttributes={{ lang: 'en-GB' }}>
    {/*HTML Meta Tags*/}
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta
      name="keywords"
      content="Spark PiCO, sparkpico, sparkPico, Sparkpico"
    />
    <meta name="title" content={title} />
    <meta name="description" content={description} />
    <meta name="language" content="en-GB" />
    <meta name="url" content={process.env.GATSBY_LANDING_URL} />
    <meta name="image" content={'../images/logo-light.png'} />

    <meta name="og:title" content={title} />
    <meta name="og:url" content={process.env.GATSBY_LANDING_URL} />
    <meta name="og:image" content="../images/logo-light.png" />
    <meta name="og:site_name" content={title} />
    <meta name="og:description" content={description} />

    {/*Facebook Meta Tags*/}
    <meta property="og:url" content={process.env.GATSBY_LANDING_URL} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content="../images/logo-light.png" />

    {/*Twitter Meta Tags*/}
    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content={process.env.GATSBY_LANDING_URL} />
    <meta property="twitter:url" content={process.env.GATSBY_LANDING_URL} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content="../images/logo-light.png" />

    <meta
      name="facebook-domain-verification"
      content={process.env.FACEBOOK_DOMAIN_VERIFICATION_CODE}
    />
    <meta name="apple-itunes-app" content="app-id=1064216828" />
    {/* ToDo: For page indexing issues detected - https://nmblsoft.atlassian.net/browse/SP1XD-966*/}
    {process.env.META_ROBOTS === 'true' && (
      <meta name="robots" content="nofollow"></meta>
    )}
    <title>{title}</title>
    {children}
  </Helmet>
);

export default PageHelmet;
