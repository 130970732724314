import styled from 'styled-components';
import { breakPoints, responsiveWidth } from 'src/styles/constants';

const HeaderWrapper = styled.div`
  padding: ${(props) => (props.scrolled ? '10px 70px' : '15px 70px')};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.scrolled
      ? '0 6px 6px rgba(10,16,20,.15), 0 0 52px rgba(10,16,20,.12) !important'
      : 'none'};
  background-color: ${(props) =>
    props.scrolled ? '#fbf8f5' : props.color || 'transparent'};
  transition: all 0.5s;
  font-weight: 600;
  color: #70757f;
  font-size: 17px;
  & + div {
    padding-top: 160px;
    &.product-web {
      padding: 70px 140px 0px;
      background-color: #fbf8f5;
    }
    &.why-spark {
      padding: 70px 140px 0px;
      background-color: #ffffff;
    }
    &.product-mobile {
      padding: 70px 140px 0px;
      background-color: #ffffff;
    }
    &.pricing {
      padding: 70px 140px 0px;
      background-color: #fbf8f5;
    }
  }
  .mobile-actions {
    display: none;
  }
  .actions {
    display: flex;
    align-items: center;
  }
  @media (max-width: 1440px) {
    & + div {
      &.pricing,
      &.why-spark,
      &.product-mobile,
      &.product-web {
        padding: 70px 140px 0px;
      }
    }
  }
  @media (min-width: ${breakPoints.pcLarge}) {
    & + div {
      &.product-mobile,
      &.product-web,
      &.pricing,
      &.why-spark {
        padding: 70px 230px 0px;
      }
    }
  }
  @media (max-width: 1024px) {
    & + div {
      &.product-web,
      &.product-mobile,
      &.pricing,
      &.why-spark {
        padding: 70px 70px 0px;
      }
    }
  }
  @media (max-width: 992px) {
    padding: 10px 15px;
    & + div {
      padding-top: 80px;
    }
    .mobile-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      & > *:not(:first-child) {
        margin-left: 10px;
      }
    }
    .actions {
      display: none;
    }
  }
`;
export default HeaderWrapper;
