import React, { useEffect, useState } from 'react';
import {
  GlobalStyle,
  GlobalStyleV2,
  GlobalStylePolicy,
} from 'src/styles/globalStyles';
import PageTitle from 'src/components/pageTitle';
import Header from 'src/components/header';
import Footer from 'src/components/footer';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import PageHelmet from 'src/components/pageHelmet';
import Content from '../components/pages/subprocessors/content';
import { Statuses } from '../utility/constants';

import { InitialLoader } from '../components/preloader/initialLoader';

const SubprocessorsPage = () => {
  const data = useStaticQuery(graphql`
    {
      mainPage: allMarkdownRemark(
        filter: {
          frontmatter: {
            id: { eq: "page-subprocessors" }
            status: { eq: "Published" }
          }
        }
      ) {
        nodes {
          frontmatter {
            title
            updated_at
          }
          html
        }
      }
      subprocesses: allMarkdownRemark(
        filter: {
          frontmatter: {
            id: { eq: "subprocessors-item" }
            status: { eq: "Published" }
          }
        }
      ) {
        nodes {
          frontmatter {
            title
            activities
            license_name
            license_link
            location
            device
            status
          }
        }
      }
    }
  `);

  if (!data.mainPage.nodes || data.mainPage.nodes.length < 1) {
    navigate('/');
    return null;
  }

  // const { title, status, updated_at } = data.mainPage.nodes[0].frontmatter;

  const title = data.mainPage.nodes[0].frontmatter.title;
  const updated_at = data.mainPage.nodes[0].frontmatter.updated_at,
    [loader, setLoader] = useState(true);
  let headless =
    typeof window !== 'undefined' &&
    window.location.search.includes('headless');

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 900);
  }, []);

  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} />
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStylePolicy />
      {!headless && <Header color={'#fbf8f5'} />}
      <Content
        title={title}
        updateDate={updated_at}
        html={data.mainPage.nodes[0].html}
        subprocesses={data.subprocesses.nodes
          .map((e) => e.frontmatter)
          .filter((e) => e.status == Statuses.PUBLISHED)}
      />
      {!headless && <Footer />}
    </>
  );
};

export default SubprocessorsPage;
