import React, { useEffect, useState } from 'react';
import Card from 'src/styles/styledComponents/card';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import VerticalTabs from '../../styles/styledComponents/verticalTabs';
import FlashCard from '../../styles/styledComponents/flashCard';
import { storeColors } from '../../styles/constants';
import { TabPane } from 'rc-tabs';
import MobileTabs from '../../styles/styledComponents2/mobileTabs';

const TabHead = ({ head, description, image, indexCount }) => (
  <div>
    <div className={'tab-head-image'}>
      <img
        alt={'use-case'}
        src={require(`../../../static/img/${image}`).default}
        width={'100%'}
      />
    </div>
    <div className={'title'} dangerouslySetInnerHTML={{ __html: head }} />
    {description.map((description, index) => (
      <div className={'description-card'} key={index}>
        <img
          src={
            require(indexCount === 0
              ? '../../images/spark-fill.svg'
              : indexCount === 1
                ? '../../images/spark-fill-yellow.svg'
                : '../../images/spark-fill-pink.png').default
          }
          className={'spark-icon'}
          alt={'spark-fill'}
        />
        <span
          className={'description'}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    ))}
  </div>
);
const ShareAndSpark = ({ data }) => {
  const { title, description, content_list } = data;
  const [index, setIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth),
    [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    function reportWindowSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, []);

  return (
    <div id={'share'}>
      <Card
        className={'features'}
        align={'center'}
        padding={'40px 30px'}
        color={'#fcf8f5'}
      >
        <Title
          className={'share'}
          size={'50px'}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Description
          className={'features-description'}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Card>
      <div className={'arrow'} />
      {windowWidth > 768 ? (
        <VerticalTabs
          defaultActiveKey="0"
          active={storeColors[index]}
          tabbarposition={'left'}
          onChange={(index) => {
            setIndex(index);
          }}
          top={'0px'}
          padding={'60px'}
          color={'#ffffff'}
          className={'share-spark'}
        >
          {content_list.map((content, index) => (
            <TabPane
              tab={
                <TabHead
                  head={content.title}
                  indexCount={index}
                  description={Object.values(
                    content?.detailed_description_in_points
                  ).filter((value) => value !== '')}
                  image={content.image?.base}
                />
              }
              key={index}
            >
              <FlashCard
                className={'tab'}
                shadow={'none'}
                color={'transparent'}
              >
                <img
                  alt={'use-case'}
                  src={
                    require(`../../../static/img/${content.image?.base}`)
                      .default
                  }
                  width={'100%'}
                />
              </FlashCard>
            </TabPane>
          ))}
        </VerticalTabs>
      ) : (
        <div className={'mobile-tabs'}>
          {content_list.map((content, index) => (
            <MobileTabs
              key={index}
              className={tabIndex === index && 'active-tab'}
              onClick={() => setTabIndex(index)}
              active={storeColors[index]}
            >
              {tabIndex === index ? (
                <>
                  <div className={'tab-head-image'}>
                    <img
                      alt={'use-case'}
                      src={
                        require(`../../../static/img/${content.image?.base}`)
                          .default
                      }
                      width={'100%'}
                    />
                  </div>
                  <div
                    className={'title'}
                    dangerouslySetInnerHTML={{ __html: content.title }}
                  />
                  {Object.values(content?.detailed_description_in_points)
                    .filter((value) => value !== '')
                    .map((descriptions, index) => (
                      <div className={'description-card'} key={index}>
                        <img
                          src={
                            require(tabIndex === 0
                              ? '../../images/spark-fill.svg'
                              : tabIndex === 1
                                ? '../../images/spark-fill-yellow.svg'
                                : '../../images/spark-fill-pink.png').default
                          }
                          className={'spark-icon'}
                          alt={'spark-fill'}
                        />
                        <span
                          className={'description'}
                          dangerouslySetInnerHTML={{ __html: descriptions }}
                        />
                      </div>
                    ))}
                </>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.title,
                  }}
                />
              )}
            </MobileTabs>
          ))}
        </div>
      )}
    </div>
  );
};

export default ShareAndSpark;
