import React from 'react';
import PlanComparison from 'src/components/plans/planComparison';
import { getFrontmatter } from 'src/utility/helpers';
import { graphql, useStaticQuery } from 'gatsby';
import ComparisonTabs from './comparisonTabs';

import {
  PlanTitle,
  Container,
  Wrapper,
  PlanTable,
  PlanCollapse,
} from '../../styles/components/plan/plansDetailed';

const PlansDetailed = (currentPlan = null) => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "plans_detailed" } } }
        ) {
          nodes {
            frontmatter {
              title
              topic
              description
              comparison_list {
                name
                features_list {
                  basic
                  feature_detail
                  premium
                  tooltip_text
                }
              }
              basic_plan
              premium_plan
              action_buttons
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
              basic_modal_data {
                cancel_button
                content
                ok_button
                title
              }
              premium_modal_data {
                cancel_button
                content
                ok_button
                title
              }
              basic_features_list_mobile {
                name
                features_list {
                  bold_text
                  normal_text
                }
              }
              premium_features_list_mobile {
                name
                features_list {
                  bold_text
                  normal_text
                }
              }
            }
          }
        }
      }
    `),
    {
      title,
      comparison_list,
      basic_plan,
      premium_plan,
      basic_features_list_mobile,
      premium_features_list_mobile,
    } = getFrontmatter(data);

  return (
    <Container id={'plans-detailed'}>
      <Wrapper>
        <PlanTitle>
          <h2>{title}</h2>
        </PlanTitle>

        <PlanTable>
          <PlanComparison
            list={comparison_list}
            basicPlan={basic_plan}
            premiumPlan={premium_plan}
          />
        </PlanTable>

        <PlanCollapse>
          <ComparisonTabs
            list={comparison_list}
            basicFeatureList={basic_features_list_mobile}
            premiumFeatureList={premium_features_list_mobile}
          />
        </PlanCollapse>
      </Wrapper>
    </Container>
  );
};

export default PlansDetailed;
