import styled from 'styled-components';
import { buttonStyles, responsiveWidth } from '../constants';

const NotifyButton = styled.button`
  border-radius: 8px;
  box-shadow: 0px 1px 14px rgba(85, 85, 85, 0.2);
  background-color: #f39e2b;
  padding: 9px 12px;
  border: none;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  font-family: inherit;
  letter-spacing: -0.5px;
  transition: background-color 0.15s;
  @media ${responsiveWidth.mobile} {
    padding: 5px;
    font-size: 12px;
  }
`;
export default NotifyButton;
