import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const MasonryLikeGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  & > div {
    padding: 70px 100px;
  }
  & > div:nth-child(even) {
    background-color: #fbf8f5;
    & > *:first-child {
      order: 1;
    }
  }
  @media ${responsiveWidth.mobile} {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    & > div {
      padding: 20px 35px;
    }
    & > div:nth-child(even) {
      margin-top: 0;
    }
    @media ${responsiveWidth.mobile} {
      & > div:nth-child(even) {
        & > *:first-child {
          order: 0;
        }
      }
    }
  }
`;
export default MasonryLikeGrid;
