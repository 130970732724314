import React from 'react';
import VideoWrapper from 'src/styles/styledComponents/videoWrapper';
import Card from 'src/styles/styledComponents/card';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import Button from 'src/styles/styledComponents/button';
import { graphql, useStaticQuery } from 'gatsby';
import VideoApp from 'src/components/home/videoApp';

const Video = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "product_web_video" } } }
        ) {
          nodes {
            frontmatter {
              title
              description
              topic
              video_link
              action_button
            }
            id
          }
        }
      }
    `),
    { title, description, action_button, video_link, topic } =
      data.allMarkdownRemark.nodes[0].frontmatter;
  return (
    <Card align={'center'}>
      <div className={'container video-container'}>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Description
          className={'video'}
          marginBottom={'20px'}
          dangerouslySetInnerHTML={{ __html: topic }}
        />
        <VideoWrapper>
          <VideoApp src={video_link} />
        </VideoWrapper>
        <Description
          style={{ maxWidth: '730px' }}
          top={'30px'}
          size={'19px'}
          lineHeight={'28px'}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {action_button && (
          <div className={'action'}>
            <Button>{action_button}</Button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default Video;
