/* eslint-disable prettier/prettier */
import React from 'react';
import styled from 'styled-components';
import { buttonStyles, responsiveWidth } from 'src/styles/constants';

const HeaderButton = styled.button`
  border-radius: 10px;
  background-color: ${(props) =>
    props.scrolled
      ? buttonStyles['primary'].backgroundColor
      : buttonStyles['secondary'].backgroundColor};
  padding: 9px 22px;
  border: 2px solid #FB6357;
  font-size: 16px;
  color: ${(props) => props.scrolled
    ? buttonStyles['primary'].color
    : buttonStyles['secondary'].color};
  font-weight: 600;
  cursor: pointer;
  font-family: inherit;
  letter-spacing: 0;
  ${(props) =>
    props.isDisabled &&
    'pointer-events: none; opacity: 0.6; cursor: not-allowed;'}
  transition: background-color .15s;
  &:hover {
    background-color: ${(props) =>
    props.scrolled
      ? buttonStyles['primary'].hoverColor
      : buttonStyles['secondary'].hoverColor};
  }

  @media ${responsiveWidth.mobile} {
    font-size: 16px;
  }
`;
export default HeaderButton;
