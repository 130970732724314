import React from 'react';
import PreferencesSectionWrapper from 'src/styles/styledComponents/preferencesSectionWrapper';
import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';

const PreferencesSection = ({
  title,
  descriptions,
  sectionKey,
  setCookieSection,
}) => (
  <PreferencesSectionWrapper>
    <div className={'header'}>
      <div>{title}</div>
      <div className={'action'}>
        <Switch
          onChange={(e) => setCookieSection({ [sectionKey]: e })}
          disabled={sectionKey === 'strictly_necessary_cookies'}
          defaultChecked
        />
      </div>
    </div>
    <div className={'content'}>
      {descriptions?.map((description, index) => (
        <div key={index}>{description}</div>
      ))}
    </div>
  </PreferencesSectionWrapper>
);

export default PreferencesSection;
