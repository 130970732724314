import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';
import Card from './card';

const WhyContentListWrapper = styled(Card)`
  margin-bottom: ${(props) => (props.type === 'v2' ? 0 : '20px')};
  border-radius: ${(props) => (props.type === 'v2' ? 0 : '8px')};
  box-shadow: none;
  padding: 40px 70px;
  line-height: 28px;
  .content {
    display: grid;
    grid-gap: 10%;
    text-align: left;
    align-items: center;
    justify-items: center;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &:nth-child(even) {
    background-color: #fcf8f5;
    padding: 100px;
    .content {
      grid-template-columns: 1fr 1fr;
      & > div:nth-child(1) {
        order: 1;
      }
    }
  }
  &:nth-child(odd) {
    &:not(:first-child) {
      padding: 100px;
    }
    .content {
      grid-template-columns: 1fr 1fr;
    }
  }
  #features-0 {
    margin-top: 80px;
  }
  @media ${responsiveWidth.mobile} {
    padding: 30px 15px;
    line-height: 21px;
    &:nth-child(even) {
      padding: 30px 15px;
    }
    &:nth-child(odd) {
      &:not(:first-child) {
        padding: 30px 15px;
      }
    }
    div.container .content {
      grid-template-columns: 1fr;
      grid-gap: 50px;
      & > div:nth-child(1) {
        order: 2;
      }
      & > div:nth-child(2) {
        order: 1;
      }
    }
    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    #features-0 {
      margin-top: 10px;
    }
  }
`;
export default WhyContentListWrapper;
