import React from 'react';
import SingleCollapse from 'src/styles/styledComponents2/singleCollapse';
import Grid from 'src/styles/styledComponents2/grid';
import NavLink from 'src/styles/styledComponents2/navLink';
import PopupCard from 'src/styles/styledComponents2/popupCard';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import Button from 'src/styles/styledComponents/button';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { Panel } from 'rc-collapse';
import { featuresSection, productsPages } from 'src/utility/constants';

const MobileMenuPopup = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "header" } } }
        ) {
          nodes {
            frontmatter {
              action_buttons
              products_list {
                image {
                  childImageSharp {
                    gatsbyImageData(width: 60)
                  }
                }
                description
                title
              }
              features_list {
                image {
                  childImageSharp {
                    gatsbyImageData(width: 60)
                  }
                }
                description
                title
              }
            }
            id
          }
        }
      }
    `),
    { action_buttons, products_list, features_list } =
      data.allMarkdownRemark.nodes[0].frontmatter;
  return (
    <PopupCard isMenuPopup>
      <Grid column={'1fr'} gap={'10px'}>
        <div>
          <NavLink onClick={() => navigate('/why-sparkpico')}>
            {action_buttons[0]}
          </NavLink>
        </div>
        <SingleCollapse
          accordion={true}
          expandIcon={() => (
            <div className={'collapse-arrow'}>
              {/* <StaticImage
                src={'../../images/arrow.png'}
                alt={'arrow'}
                height={24}
              /> */}
            </div>
          )}
        >
          <Panel
            showArrow={true}
            header={<NavLink>{action_buttons[1]}</NavLink>}
          >
            <Grid column={'1fr 1fr 1fr'} gap={'20px'}>
              {features_list.map((feature, index) => (
                <div
                  className={'item'}
                  key={index}
                  onClick={() =>
                    navigate(`/features#${featuresSection[index]}`)
                  }
                >
                  <div className={'image'}>
                    <GatsbyImage
                      alt={'feature'}
                      image={getImage(feature.image)}
                    />
                  </div>
                  <div>
                    <div className={'title'}>{feature.title}</div>
                    <div className={'description'}>{feature.description}</div>
                  </div>
                </div>
              ))}
            </Grid>
          </Panel>
        </SingleCollapse>
        <div>
          <NavLink onClick={() => navigate('/plans')}>
            {action_buttons[2]}
          </NavLink>
        </div>
        <SingleCollapse
          accordion={true}
          expandIcon={() => (
            <div className={'collapse-arrow'}>
              {/* <StaticImage
                src={'../../images/arrow.png'}
                alt={'arrow'}
                height={24}
              /> */}
            </div>
          )}
        >
          <Panel
            showArrow={true}
            header={<NavLink>{action_buttons[3]}</NavLink>}
          >
            <Grid column={'1fr 1fr 1fr'} gap={'20px'}>
              {products_list.map((product, index) => (
                <div
                  className={'item'}
                  key={index}
                  onClick={() => navigate(`/${productsPages[index]}`)}
                >
                  <div className={'image'}>
                    <GatsbyImage
                      alt={'feature'}
                      image={getImage(product.image)}
                    />
                  </div>
                  <div>
                    <div className={'title'}>{product.title}</div>
                    <div className={'description'}>{product.description}</div>
                  </div>
                </div>
              ))}
            </Grid>
          </Panel>
        </SingleCollapse>
        <div>
          <NavLink
            onClick={() => navigate(`${process.env.GATSBY_APP_URL}/login`)}
          >
            {action_buttons[4]}
          </NavLink>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            onClick={() => navigate(`${process.env.GATSBY_APP_URL}/register`)}
            size={'sm'}
            type={'primary'}
          >
            {action_buttons[5]}
          </Button>
        </div>
      </Grid>
    </PopupCard>
  );
};

export default MobileMenuPopup;
