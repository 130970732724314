import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const PlansCardHolder = styled.div`
  display: grid;
  grid-template-columns: 255px 300px 255px;
  align-items: start;
  justify-content: center;
  grid-gap: 50px;
  & + * {
    margin-top: 50px;
  }
  @media ${responsiveWidth.mobile} {
    grid-template-columns: 1fr;
    grid-gap: 50px;
    & + * {
      margin-top: 30px;
    }
  }
`;
export default PlansCardHolder;
