import React from 'react';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';
import StyledCollapse from 'src/styles/styledComponents/styledCollapse';
import NavButton from 'src/styles/styledComponents/navButton';
import { StaticImage } from 'gatsby-plugin-image';
import { Panel } from 'rc-collapse/es';
import { getFrontmatter, kebabCase } from '../../utility/helpers';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import HorizontalLine from 'src/styles/styledComponents/horizontalLine';
import ScrollspyGridFaq from 'src/styles/styledComponents/scrollspyGridFaq';

const FaqContent = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "faq_page" } } }
        ) {
          nodes {
            frontmatter {
              description
              titles
              faq_groups {
                title
                faq_list {
                  question
                  answers
                }
              }
              navigation_buttons
              navigation_buttons_link
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `),
    { faq_groups, navigation_buttons, navigation_buttons_link } =
      getFrontmatter(data);
  return (
    <Card color={'#fbf8f5'} bottom={'0'}>
      <div className={'container faq-container'}>
        <ScrollspyGridFaq>
          <div className={'content'}>
            {faq_groups.map((group, index) => (
              <div key={index} id={kebabCase(group.title)}>
                <Title
                  marginBottom={'40px'}
                  dangerouslySetInnerHTML={{ __html: group.title }}
                />
                <StyledCollapse
                  shadow={'none'}
                  bottom={'30px'}
                  accordion={true}
                  expandIcon={() => (
                    <div className={'collapse-arrow'}>
                      {/* <StaticImage
                        src={'../../images/arrow.png'}
                        alt={'arrow'}
                        height={24}
                      /> */}
                    </div>
                  )}
                >
                  {group.faq_list.map((list, index) => (
                    <Panel header={list.question} key={index} showArrow={true}>
                      <div dangerouslySetInnerHTML={{ __html: list.answers }} />
                    </Panel>
                  ))}
                </StyledCollapse>
                {index === faq_groups.length - 1 ? (
                  ''
                ) : (
                  <HorizontalLine line={'30px'} width={'100%'} height={'3px'} />
                )}
              </div>
            ))}
            <div className={'actions'}>
              <NavButton onClick={() => navigate(navigation_buttons_link[0])}>
                {navigation_buttons[0]}
                <span />
              </NavButton>
              <NavButton onClick={() => navigate(navigation_buttons_link[1])}>
                {navigation_buttons[1]}
                <span />
              </NavButton>
            </div>
          </div>
        </ScrollspyGridFaq>
      </div>
    </Card>
  );
};
export default FaqContent;
