import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const CollectWrapper = styled.div`
  & > div:nth-child(even) {
    & > *:first-child {
      order: 1;
    }
  }
  .description-points {
    font-size: 20px;
    display: grid;
    margin-top: 10px;
    grid-gap: 10px;
    .description {
      margin-left: 7px;
    }
  }
  @media ${responsiveWidth.mobile} {
    .description-points {
      font-size: 14px;
    }
    & > div:nth-child(even) {
      & > *:first-child {
        order: 0;
      }
    }
  }
`;
export default CollectWrapper;
