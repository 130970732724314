import React, { Fragment } from 'react';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';
import Description from 'src/styles/styledComponents/description';
import { graphql, useStaticQuery } from 'gatsby';
import FlashCard from 'src/styles/styledComponents/flashCard';
import TriangleLeft from 'src/styles/styledComponents/triangleLeft';
import TriangleRight from 'src/styles/styledComponents/triangleRight';
import NavButton from 'src/styles/styledComponents/navButton';

const Features = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "features" } } }
        ) {
          nodes {
            frontmatter {
              features {
                feature_1 {
                  description
                  title
                  image {
                    base
                  }
                }
                feature_2 {
                  description
                  title
                  image {
                    base
                  }
                }
                feature_3 {
                  description
                  title
                  image {
                    base
                  }
                }
                feature_4 {
                  description
                  title
                  image {
                    base
                  }
                }
                feature_5 {
                  description
                  title
                  image {
                    base
                  }
                }
                feature_6 {
                  description
                  title
                  image {
                    base
                  }
                }
              }
              title
              topic
              description
              navigation_button
              link
            }
          }
        }
      }
    `),
    { title, description, features, navigation_button, link } =
      data.allMarkdownRemark.nodes[0].frontmatter,
    featuresList = Object.values(features);
  return (
    <Card>
      <div className={'container-features features'}>
        <Title width={'60%'} dangerouslySetInnerHTML={{ __html: title }} />
        <Description
          width={'61%'}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className={'features-list'}>
          {featuresList.map((item, index) => {
            const image = item?.image?.base;

            return (
              <Fragment key={index}>
                <div>
                  {!(index % 2) ? (
                    <>
                      <FlashCard
                        className={'image-wrapper'}
                        padding={'0px'}
                        shadow={'none'}
                        color={'transparent'}
                        radius={'15px'}
                      >
                        <img
                          alt={'discover'}
                          src={require(`../../../static/img/${image}`).default}
                          width={160}
                          height={158}
                        />
                      </FlashCard>
                      <FlashCard align={'flex-start'}>
                        <TriangleLeft size={20} />
                        <Title
                          size={'26px'}
                          lineHeight={'30px'}
                          marginBottom={'10px'}
                          letterSpace={'0'}
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                        <Description
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                        <a href={`/why-sparkpico/#features-${index}`}>
                          {navigation_button}
                        </a>
                      </FlashCard>
                    </>
                  ) : (
                    <>
                      {' '}
                      <FlashCard align={'flex-start'}>
                        <TriangleRight size={20} />
                        <Title
                          size={'26px'}
                          lineHeight={'30px'}
                          marginBottom={'10px'}
                          letterSpace={'0'}
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                        <Description
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                        <a href={`/why-sparkpico/#features-${index}`}>
                          {navigation_button}
                        </a>
                      </FlashCard>
                      <FlashCard
                        className={'image-wrapper'}
                        padding={'0px'}
                        shadow={'none'}
                        color={'transparent'}
                        radius={'15px'}
                      >
                        <img
                          alt={'discover'}
                          src={require(`../../../static/img/${image}`).default}
                          width={160}
                          height={158}
                        />
                      </FlashCard>
                    </>
                  )}
                </div>
                <div></div>
              </Fragment>
            );
          })}
        </div>
        <NavButton className="feature_btn">
          <a href={link} className="features_button_link">
            {navigation_button}
          </a>
        </NavButton>
      </div>
    </Card>
  );
};

export default Features;
