import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const ContentWrapper = styled.div`
  & > div:nth-child(even) {
    & > *:first-child {
      order: 1;
      transform: scaleX(-1);
    }
  }
  @media ${responsiveWidth.mobile} {
    & > div:nth-child(even) {
      & > *:first-child {
        order: 0;
      }
    }
  }
`;
export default ContentWrapper;
