import React from 'react';
import styled from 'styled-components';
import Tabs from 'rc-tabs';
import { responsiveWidth } from 'src/styles/constants';

const VerticalTabs = styled(Tabs)`
  display: grid;
  grid-template-columns: 45% 50%;
  grid-gap: 20px;
  margin-top: ${(props) => props.top || '30px'};
  text-align: left;
  padding: ${(props) => props.padding || '10px 10px 0'};
  justify-content: center;
  background-color: ${(props) => props.color};
  &.share-spark {
    grid-template-columns: 50% 45%;
    & > div:first-child {
      order: 1;
    }
  }
  .rc-tabs-nav {
    height: 100%;
    overflow: visible;
    padding: 0 50px;
  }
  .description-card {
    display: flex;
    align-items: flex-start;
  }
  .rc-tabs-tab {
    border-radius: 15px;
    background-color: #fff;
    padding: 20px;
    font-size: 24px;
    color: #9a9faa;
    font-weight: bold;
    transition: all 0.2s;
    cursor: pointer;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1),
      30px 42px 54px 0 rgba(0, 0, 0, 0.05),
      inset 9px 0 0 0 rgba(232, 235, 241, 1);
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .description {
      display: none;
      font-weight: normal;
    }
    .spark-icon {
      display: none;
    }
    &.rc-tabs-tab-active {
      border-radius: 15px;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1),
        30px 42px 54px 0 rgba(0, 0, 0, 0.05),
        inset 9px 0 0 0 ${(props) => props.active || '#fb6357'};
      background-color: #ffffff;
      color: #515459;
      margin-bottom: 20px !important;
      .title {
        font-size: 30px;
        margin-bottom: 5px;
        color: ${(props) => props.active || '#fb6357'};
      }
      .description-card {
        margin-top: 10px;
      }
      .description {
        font-size: 18px;
        line-height: 27px;
        display: block;
        margin-left: 5px;
      }
      .spark-icon {
        margin-top: 2px;
        height: 18px;
        width: 18px;
        display: block;
      }
    }
  }
  .tab-head-image {
    display: none;
  }

  @media ${responsiveWidth.mobile} {
    height: auto;
    margin-top: 0px;
    &.rc-tabs {
      padding: 0;
      grid-template-columns: 100%;
    }
    .rc-tabs-content-holder {
      display: none;
    }
    .rc-tabs-nav {
      padding: 30px 10px;
      height: auto;
      overflow: visible;
    }
    .rc-tabs-tab {
      font-size: 18px;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1),
        15px 21px 27px 0 rgba(0, 0, 0, 0.05),
        inset 0 -4px 0 0 rgba(232, 235, 241, 1);
    }
    .rc-tabs-tab.rc-tabs-tab-active {
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1),
        15px 21px 27px 0 rgba(0, 0, 0, 0.05),
        inset 0 -9px 0 0 ${(props) => props.active || '#fb6357'};
      padding: 9px 9px 30px;
      overflow: hidden;
      .title {
        padding: 15px 15px 0;
        font-size: 20px;
        color: ${(props) => props.active};
        margin-bottom: 0px;
      }
      .description {
        padding: 0 5px;
        font-size: 14px;
        margin-left: 0px;
        line-height: 21px;
      }
      .spark-icon {
        margin-top: 2px;
        padding: 0 0 0 15px;
        height: 12px;
        width: 12px;
      }
      .tab-head-image {
        display: block;
        img {
          border-radius: 15px;
        }
      }
    }
  }
`;
export default VerticalTabs;
