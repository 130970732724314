import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { GlobalStyle, GlobalStyleV2 } from 'src/styles/globalStyles';
import Header from 'src/components/header';
import Footer from 'src/components/footer';
import { GlobalStylePolicy } from 'src/styles/globalStyles';
import PageHelmet from 'src/components/pageHelmet';
import Content from '../components/pages/contributors/content';

import { InitialLoader } from '../components/preloader/initialLoader';

const Contributors = () => {
  const data = useStaticQuery(graphql`
    {
      mainPage: allMarkdownRemark(
        filter: {
          frontmatter: {
            id: { eq: "page-contributors" }
            status: { eq: "Published" }
          }
        }
      ) {
        nodes {
          frontmatter {
            title
            hero_title
            hero_subtitle
            id
            hero_image {
              id
              publicURL
              name
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.4578)
              }
            }
            status
            created_at
            updated_at
          }
          html
        }
      }
    }
  `);
  if (!data.mainPage.nodes || data.mainPage.nodes.length < 1) {
    navigate('/');
    return null;
  }
  const { title, hero_title, hero_subtitle, hero_image } =
      data.mainPage.nodes[0].frontmatter,
    [loader, setLoader] = useState(true),
    body = data.mainPage.nodes[0].html;

  let headless =
    typeof window !== 'undefined' &&
    window.location.search.includes('headless');

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, []);

  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} />
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStylePolicy />
      {!headless && <Header color={'#fbf8f5'} />}
      <Content
        title={hero_title}
        subtitle={hero_subtitle}
        image={hero_image}
        body={body}
      />
      {!headless && <Footer />}
    </>
  );
};

export default Contributors;
