import React from 'react';
import cx from 'classnames';
// import check from '../../images/arrow';

const Down = ({ isOpen }) => (
  // <img
  //   src={}
  //   alt={'arrow'}
  //   width="24"
  //   height="24"
  //   color="8b8b8b"
  //   viewBox="0 0 20 20"
  //   className={cx('collapse', { 'is-open': isOpen })}
  // />
  <div>
    
  </div>
);
Down.defaultProps = {};

export default Down;
