/* eslint-disable prettier/prettier */
import React from 'react';
import StyledComparison from 'src/styles/styledComponents/styledComparison';
import { StaticImage } from 'gatsby-plugin-image';
import { plansSectionColors, plansSectionShadow } from 'src/styles/constants';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const PlanComparison = ({
  list,
  basicPlan,
  premiumPlan,
}) => (
  <StyledComparison>

    {list.map((item, index) => (
      <div key={index} className={'comparison_component'}>

        <div className={'title-container'} >
          <div></div>
          <div className={'heading free-plan'}>{basicPlan[0]}</div>
          <div className={'heading'}>{premiumPlan[0]}</div>
        </div>


        <div className={'pricing-card'}
          style={{ backgroundColor: plansSectionShadow[index], boxShadow: `0 12px 24px 0 rgba(0, 0, 0, 0.1),
                 30px 42px 54px 0 rgba(0, 0, 0, 0.05),
                 10px 0 0 0 ${plansSectionColors[index]}, -10px 0 0 0 ${plansSectionColors[index]}`}}>

          <div
            className={index === 4 ? 'title plan' : 'title'}
            style={{ color: plansSectionColors[index], whiteSpace: 'pre-wrap', textAlign: 'center'}}
          >
            {item.name}
          </div>

          <div>
            {item.features_list.map((feature, index) => (
              <div key={index} className={'feature'}>


                <div>
                  <span
                    dangerouslySetInnerHTML={{ __html: feature.feature_detail }}
                  />
                  {feature.tooltip_text && (
                    <span className={'info'}>
                      <img
                        src={require('../../images/icons-info.png').default}
                        width={20}
                        alt={'icon'}
                      />
                      <img
                        data-tip={feature.tooltip_text}
                        data-for={'styled-comparison'}
                        className={'info-hover'}
                        src={require('../../images/icon-info-hover.png').default}
                        width={20}
                        alt={'icon'}
                      />
                    </span>
                  )}
                </div>

                <div>
                  {feature.basic === 'true' ? (
                    <StaticImage
                      src={'../../images/icon-spark-active.svg'}
                      alt={'active'}
                      width={30}
                    />
                  ) : feature.basic == 'false' ? (
                    <StaticImage
                      src={'../../images/icon-spark-inactive.svg'}
                      alt={'inactive'}
                      width={30}
                    />
                  ) : (
                    feature.basic !== 'true' &&
                  feature.basic !== '' && (
                      <span className={'basic'} dangerouslySetInnerHTML={{ __html: feature.basic }} />
                    )
                  )}
                </div>


                <div>
                  {feature.premium === 'true' ? (
                    <StaticImage
                      src={'../../images/icon-spark-active.svg'}
                      alt={'active'}
                      width={30}
                    />
                  ) : feature.premium === 'false' ? (
                    <StaticImage
                      src={'../../images/icon-spark-inactive.svg'}
                      alt={'inactive'}
                      width={30}
                    />
                  ) : (
                    feature.premium !== 'true' &&
                  feature.premium !== '' && (
                      <span
                        className={'basic'}
                        dangerouslySetInnerHTML={{ __html: feature.premium }}
                      />
                    )
                  )}
                </div>

              </div>
            ))}
          </div>
          <ReactTooltip
            place={'bottom'}
            id={'styled-comparison'}
            className={'__react_component_tooltip'}
            effect={'solid'}
          />
        </div>
      </div>
    ))}


  </StyledComparison>
);

export default PlanComparison;

PlanComparison.defaultProps = {
  buttons: [],
  list: [],
  basicPlan: [],
  premiumPlan: [],
};

PlanComparison.protoTypes = {
  buttons: PropTypes.array,
  list: PropTypes.array,
  basicPlan: PropTypes.array,
  premiumPlan: PropTypes.array,
};
