import React from 'react';
import styled from 'styled-components';
import {
  cardShadow,
  borderRadius,
  cardBackground,
  responsiveWidth,
} from 'src/styles/constants';

const Card = styled.div`
  border-radius: ${borderRadius};
  background-color: ${(props) => props.color || cardBackground};
  box-shadow: ${(props) => props.shadow || cardShadow};
  padding: ${(props) => props.padding || '100px 70px'};
  margin-bottom: ${(props) => props.bottom || '20px'};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-image: ${(props) =>
    props.background ? `url(${props.background})` : 'unset'};
  background-repeat: no-repeat;
  background-size: ${(props) => props.size || 'contain'};
  background-position: ${(props) => props.position || 'none'};
  background-attachment: ${(props) => props.attachment || 'none'};
  background-position-y: ${(props) => props.positionY || 'bottom'};
  text-align: ${(props) => props.align || 'left'};
  overflow: hidden;
  &.features {
    margin: 0;
    box-shadow: none;
    border-radius: 0;
  }
  &.footer {
    background-color: #3e434e;
    padding: 50px 70px;
    .copyright {
      color: #fff;
      span {
        padding: 2px;
      }
    }
  }
  &.page-title {
    text-align: center;
    padding: 70px;
    background-size: cover;
    background-position-y: center;
  }
  &.text-for-mobile {
    background-color: #3c404b;
  }
  .gatsby-focus-wrapper &:last-child {
    margin-bottom: 0;
  }
  @media ${responsiveWidth.heroLayout} {
    &.hero-clipper {
      padding: 0 70px !important;
    }
  }
  @media ${responsiveWidth.mobile} {
    padding: 30px 15px;
    margin-bottom: ${(props) => props.mobileBottom || '10px'};
    &.core-features {
      padding: 30px 15px 60px;
    }
    &.why-spark {
      padding: 60px 15px;
      .actions {
        display: flex;
        justify-content: center;
      }
    }
    &#create-sparks,
    &#share,
    &#organize,
    &#store {
      padding: 95px 15px;
    }
    &#content {
      padding: 40px 40px;
    }
    &.footer {
      padding: 15px;
    }

    &.page-title {
      h1 {
        margin: 0;
      }
      padding: 25px;
    }
    &.hero-clipper {
      padding: 30px 15px !important;
    }
  }
`;
export default Card;
