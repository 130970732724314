import styled from 'styled-components';
import Button from 'src/styles/styledComponents/button';

const ButtonOutlined = styled(Button)`
  background-color: #fff;
  color: #322e2e;
  border: 1px solid #fe6252;
  box-sizing: border-box;
  transition: all 0.15s;
  padding: 6px 22px;
  box-shadow: none;

  .plans_intro_button {
    font-size: 22px;
    padding: 0;
  }
  &:hover {
    color: #fff;
    background-color: #e44637;
    border: 1px solid #e44637;
  }
  .plans_intro_link {
    padding: 10px 22px;
    width: 100%;
    text-decoration: none;
    color: #000;
    &:hover {
      color: #fff;
    }
  }
  &.stores-button {
    padding: 28px 32px !important;
    a {
      color: #322e2e;
    }
    &:hover {
      a {
        color: #fff;
      }
    }
  }
`;
export default ButtonOutlined;
