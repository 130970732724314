import React, { useEffect, useState } from 'react';
import HeaderButton from 'src/styles/styledComponents/headerButton';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery, Link, navigate } from 'gatsby';
import Cookies from 'universal-cookie';
import HeaderWrapper from 'src/styles/styledComponents2/headerWrapper';
import NavLink from 'src/styles/styledComponents2/navLink';
import VerticalLine from 'src/styles/styledComponents2/verticalLine';
import Trigger from 'rc-trigger';
import 'rc-trigger/assets/index.css';
import FeaturesPopup from 'src/components/featuresPopup';
import ProductsPopup from 'src/components/productsPopup';
import MobileMenuPopup from 'src/components/mobileMenuPopup';
import WelcomWrapper from 'src/styles/styledComponents/welcomWrapper';
import { postRequest } from 'src/utility/axiosClient';

const Header = ({ color }) => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "header" } } }
        ) {
          nodes {
            frontmatter {
              action_buttons
              welcom_message
            }
            id
          }
        }
      }
    `),
    { action_buttons, welcom_message } =
      data.allMarkdownRemark.nodes[0].frontmatter,
    [isScrolled, setScrolled] = useState(false);

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', function () {
      let doc = document.documentElement;
      let top = (window?.pageYOffset || doc?.scrollTop) - (doc?.clientTop || 0);
      if (!top) {
        setScrolled(false);
      } else {
        setScrolled(true);
      }
    });
  }

  const [featureOpened, setFeatureOpen] = useState(false),
    [productsOpened, setProductsOpen] = useState(false),
    [mobileMenu, setMobileMenu] = useState(false),
    [user, setUser] = useState(false),
    [isDesktop, setIsDesktop] = useState(false);

  const redirectToApp = () => {
    !(user && isDesktop) &&
      window.location.replace(`${process.env.GATSBY_APP_URL}/login`);
  };

  const logOut = () => {
    // Delete user cookie
    const cookies = new Cookies();
    cookies.remove('user_name_sparkpico');
    try {
      const response = postRequest('logout');
    } catch (error) {
      console.error(error);
    }
    // Change logged status
    setUser(false);
  };

  useEffect(() => {
    const cookies = new Cookies();
    const userName = cookies.get('user_name_sparkpico');

    if (userName) {
      setUser(userName);
    }

    if (window.innerWidth > 768) {
      setIsDesktop(true);
    }
  }, []);

  return (
    <>
      <HeaderWrapper scrolled={isScrolled} color={color}>
        <div className={'container header-container'}>
          <Link to={'/'}>
            <img
              src={require('../images/spark-pico-logo.png').default}
              alt={'logo'}
              width={'auto'}
              height={30}
            />
          </Link>
          <div className={'actions'}>
            <NavLink onClick={() => navigate('/why-sparkpico')}>
              {action_buttons[0]}
            </NavLink>
            <NavLink>
              <Trigger
                action={['click']}
                popup={<FeaturesPopup />}
                popupAlign={{
                  points: ['tc', 'bc'],
                  offset: [0, 20],
                }}
                popupTransitionName={'rc-trigger-popup-zoom'}
                onPopupVisibleChange={(data) => setFeatureOpen(data)}
              >
                <div>
                  <span>{action_buttons[1]}</span>
                  {/* <StaticImage
                    className={featureOpened ? 'active' : ''}
                    src={'../../images/arrow.png'}
                    alt={'arrow'}
                    width={18}
                  /> */}
                </div>
              </Trigger>
            </NavLink>
            <NavLink onClick={() => navigate('/plans')}>
              {action_buttons[2]}
            </NavLink>
            <NavLink>
              <Trigger
                action={['click']}
                popup={<ProductsPopup />}
                popupAlign={{
                  points: ['tc', 'bc'],
                  offset: [0, 20],
                }}
                popupTransitionName={'rc-trigger-popup-zoom'}
                onPopupVisibleChange={(data) => setProductsOpen(data)}
              >
                <div>
                  <span>{action_buttons[3]}</span>
                  {/* <StaticImage
                    className={productsOpened ? 'active' : ''}
                    src={'../../images/arrow.png'}
                    alt={'arrow'}
                    width={18}
                  /> */}
                </div>
              </Trigger>
            </NavLink>
            <VerticalLine />
            <WelcomWrapper auth={user}>
              <NavLink
                overFlow={'ellipsis'}
                space={'nowrap'}
                width={'140px'}
                onClick={redirectToApp}
                disableHover={user}
              >
                {user && isDesktop ? welcom_message : action_buttons[4]}
              </NavLink>

              {user && isDesktop && (
                <p className={'logout-action'} onClick={logOut}>
                  Log Out
                </p>
              )}
            </WelcomWrapper>
            <a
              href={`${process.env.GATSBY_APP_URL}${!user ? '/register' : ''}`}
              target="child"
            >
              <HeaderButton scrolled={isScrolled}>
                {user && isDesktop ? action_buttons[7] : action_buttons[5]}
              </HeaderButton>
            </a>
          </div>
          <div className={'mobile-actions'}>
            {!mobileMenu && (
              <a
                href={`${process.env.GATSBY_APP_URL}/register`}
                target="_blank"
              >
                <HeaderButton scrolled={isScrolled}>
                  {action_buttons[5]}
                </HeaderButton>
              </a>
            )}
            <Trigger
              action={['click']}
              popup={<MobileMenuPopup />}
              popupAlign={{
                points: ['tr', 'br'],
                offset: [0, 20],
              }}
              popupTransitionName={'rc-trigger-popup-zoom'}
              onPopupVisibleChange={(data) => setMobileMenu(data)}
              popupStyle={{ width: 'calc(100% - 30px)' }}
            >
              <span>
                <StaticImage
                  src={'../images/icon-deck.png'}
                  alt={'deck'}
                  width={30}
                />
              </span>
            </Trigger>
          </div>
        </div>
      </HeaderWrapper>
    </>
  );
};

export default Header;
