import React, { useEffect, useState } from 'react';
import { getFrontmatter } from 'src/utility/helpers';
import PageHelmet from 'src/components/pageHelmet';
import {
  GlobalStyle,
  GlobalStyleHome,
  GlobalStyleProductWeb,
  GlobalStyleV2,
} from 'src/styles/globalStyles';
import { graphql, useStaticQuery } from 'gatsby';
import Footer from 'src/components/footer';
import Header from 'src/components/header';
import Video from 'src/components/products/video';
import InfoSection from 'src/components/products/infoSection';
import OtherProductSection from 'src/components/products/otherProductSection';
import ProductFaq from 'src/components/products/productFaq';
import ProductDetailSection from 'src/components/products/productDetailSection';
import CoreFeaturesMobile from 'src/components/products/coreFeaturesMobile';
import CombinedHeroMobile from 'src/components/products/combinedHeroMobile';
import { InitialLoader } from '../components/preloader/initialLoader';
import Stores from '../components/home/stores';
import CallToAction from '../components/home/callToAction';

const ProductsMobile = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "products_mobile_page" } }
          }
        ) {
          nodes {
            frontmatter {
              title
              description
            }
          }
        }
      }
    `),
    { title, description } = getFrontmatter(data),
    [loader, setLoader] = useState(true);

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 700);
  }, []);
  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description}>
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </PageHelmet>
      <GlobalStyle />
      <GlobalStyleHome />
      <GlobalStyleV2 />
      <GlobalStyleProductWeb />
      <Header />
      <CombinedHeroMobile />
      <ProductDetailSection of={'mobile'} />
      <CoreFeaturesMobile />
      <InfoSection of={'mobile'} />
      <CallToAction />
      <Video />
      <OtherProductSection of={'mobile'} />
      <ProductFaq of={'mobile'} />
      <Stores />
      <Footer />
    </>
  );
};

export default ProductsMobile;
