import styled from 'styled-components';
import { responsiveWidth } from '../constants';
import after from '../../images/path-from-back-Now.svg';
import before from '../../images/path-from-now-after.svg';

const ImageBox = styled.div`
  height: 250px;
  display: grid;
  grid-template-columns: 25% 10% 35%;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper-constrained {
    border-radius: 10px;
    border: 2px solid ${(props) => props.color};
    border-bottom-width: 20px;
  }

  @media ${responsiveWidth.mobile} {
    height: 400px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    .gatsby-image-wrapper-constrained {
      margin-bottom: 30px;
    }
    .path-before > div:nth-child(1) {
      &:after {
        display: none;
      }
    }
    .path-after > div:nth-child(1) {
      &:before {
        display: none;
      }
    }
  }
`;
export default ImageBox;
