import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from '../../utility/helpers';

import {
  Card as PostCard,
  CardDetails,
  CardCategory,
  Author,
  ContentWrapper,
} from '../../styles/components/releaseNotes/postCard';

const Card = ({ title, description, author, category }) => (
  <PostCard>
    <CardDetails>
      <CardCategory
        className={kebabCase(category.toLowerCase())}
        category={category.toLowerCase()}
      >
        {category}
      </CardCategory>
      {/* <Author>Written by {author}</Author> */}
    </CardDetails>

    <ContentWrapper>
      <h3>{title}</h3>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </ContentWrapper>
  </PostCard>
);

Card.PropTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default Card;
