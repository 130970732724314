import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const TriangleLeft = styled.div`
  width: 0;
  height: 0;
  border-top: ${(props) => props.size || '50'}px solid transparent;
  border-bottom: ${(props) => props.size || '50'}px solid transparent;
  border-right: ${(props) => props.size || '50'}px solid
    ${(props) => props.color || '#fff'};
  position: absolute;
  transform: translateX(-100%);
  left: 0;
  @media ${responsiveWidth.mobile} {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid ${(props) => props.color || '#fff'};
  }
`;
export default TriangleLeft;
