import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const ProductCard = styled.div`
  padding: 20px;
  background-color: #fbf8f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .content {
    padding: 25px 35px 20px;
  }
  .gatsby-image-wrapper {
    margin-bottom: 20px;
    img {
      max-height: 300px !important;
    }
  }
  button {
    margin-top: 20px;
  }
  @media ${responsiveWidth.mobile} {
    button {
      margin-top: 15px;
    }
    .content {
      padding: 0px;
    }
  }
`;
export default ProductCard;
