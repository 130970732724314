import styled from 'styled-components';
import { buttonStyles, responsiveWidth } from 'src/styles/constants';

const ButtonBlack = styled.button`
  border-radius: 10px;
  background-color: #fe6252;
  padding: 15px 35px;
  border: none;
  transition: all 0.3s;
  font-size: 22px;
  color: ${(props) => buttonStyles[props.type || 'primary'].color};
  font-weight: 600;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  letter-spacing: -1px;
  margin-top: ${(props) => props.top};
  &:hover {
    background-color: #e44637;
    color: #fff;
  }
  ${(props) =>
    props.isDisabled &&
    'pointer-events: none; opacity: 0.6; cursor: not-allowed;'}

  .hero_sign_up_link {
    text-decoration: none;
    color: #fff;
  }
  @media ${responsiveWidth.mobile} {
    font-size: 20px;
  }
`;
export default ButtonBlack;
