import styled from 'styled-components';
import { responsiveWidth } from '../constants';

const Arrow = styled.div`
  height: 0px;
  width: 0px;
  border-radius: 2px;
  border-bottom: 50px solid ${(props) => props.color};
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  transform: ${(props) => (props.rotate ? props.rotate : 'rotate(-90deg)')};

  @media ${responsiveWidth.mobile} {
    border-bottom: 25px solid ${(props) => props.color};
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    transform: rotate(-180deg);
  }
`;
export default Arrow;
