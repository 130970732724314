import styled from 'styled-components';
import { responsiveWidth, breakPoints } from 'src/styles/constants';

const PlansProductCard = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  text-align: center;

  .plan_product__btn {
    margin-top: 30px;
    flex: 1;
    display: flex;
    align-items: center;

    button {
      max-width: 139px;
      .plans_product_link {
        width: auto;
        height: auto;
        padding: 6px 22px;
        display: block;
        text-decoration: none;
        color: #000;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .plans_product_button {
    padding: 0;
    transition: all 0.15s ease 0s;
  }

  @media ${responsiveWidth.mobile} {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    h1 {
      font-size: 25px;
      margin-bottom: 35px;
    }

    .gatsby-image-wrapper {
      max-width: 215px;
      max-height: 130px;

      img {
        object-fit: contain !important  ;
      }
    }

    button {
      font-size: 21px;
    }
  }

  @media (min-width: ${breakPoints.pc}) {
    h1 {
      font-size: 30px;
      margin-bottom: 40px;
    }

    .gatsby-image-wrapper {
      max-width: 262px;
      max-height: 160px;
    }

    button {
      font-size: 26px;
      max-width: 195px !important;
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    max-width: 450px;
    width: 100%;

    h1 {
      margin-bottom: 49px;
    }

    .gatsby-image-wrapper {
      max-width: 310px;
    }

    .plan_product__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 65px;

      button {
        width: 233px;
        font-size: 31px;
      }
    }
  }
`;
export default PlansProductCard;
