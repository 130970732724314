import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { InitialLoaderStyle } from '../../styles/initialLoaderStyle';

export const InitialLoader = ({ size, className }) => (
  <>
    <InitialLoaderStyle />
    <Spin
      id={'preloader'}
      indicator={
        <LoadingOutlined style={{ fontSize: size ? size : 40 }} spin />
      }
      className={`initial-loading ${className ? className : ''}`}
    />
  </>
);
