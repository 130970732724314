import React from 'react';
import moment from 'moment';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';

import getRoadmapPosts from '../../../queries/graphql/roadmap/posts';
import { navigate } from 'gatsby';
import LearnButton from '../../../styles/components/roadmap/learnButton';

const RecentlyLaunched = ({ title }) => {
  const releases = getRoadmapPosts();

  return (
    <div className={'section-content'}>
      <div className="section_title launched">{title}</div>
      <div className="roadmap__postCard-container">
        {releases.map((release, index) => {
          release = release.frontmatter;

          return (
            <div className="postCard" key={index}>
              <div className="postCard-cardWrapper">
                <div className="postCard-image-container">
                  {release.featured_image != null ? (
                    <GatsbyImage
                      image={getImage(release.featured_image)}
                      alt={'image'}
                    />
                  ) : (
                    <StaticImage
                      src={'../../../images/roadmap-graphic.png'}
                      alt={'roadmap-graphic'}
                    />
                  )}
                </div>
                <div className={'content'}>
                  <p className="postCard-release_at">
                    {moment(release.released_at).format('MMMM YYYY')}
                  </p>
                  <h3 className="postCard-title">
                    {' '}
                    Announcing: {release.title}{' '}
                  </h3>
                  <LearnButton onClick={() => navigate('/release-notes')}>
                    Learn more
                  </LearnButton>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecentlyLaunched;
