import React from 'react';
import Card from 'src/styles/styledComponents/card';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import Button from 'src/styles/styledComponents/button';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BreadCrumb from '../breadCrumb';
import { getFrontmatterOf } from '../../utility/helpers';

const HeroWebClipper = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "webclipper_hero" } } }
        ) {
          nodes {
            id
            frontmatter {
              navigation_buttons
              titles
              description
              info
              link
              image {
                childImageSharp {
                  gatsbyImageData(width: 700)
                }
              }
            }
          }
        }
        header: allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "header" } } }
        ) {
          nodes {
            frontmatter {
              action_buttons
              products_list {
                title
              }
            }
            id
          }
        }
      }
    `),
    { titles, description, navigation_buttons, info, image, link } =
      data.allMarkdownRemark.nodes[0].frontmatter,
    { action_buttons, products_list } = getFrontmatterOf(data, 'header');

  const breadCrumb = [
    { label: 'Home page', path: '/' },
    { label: action_buttons[3], path: '/products-webclipper' },
    { label: products_list[2].title },
  ];
  return (
    <>
      <div className={'product-web'}>
        <BreadCrumb breadCrumb={breadCrumb} />
      </div>
      <Card
        color={'#fbf8f5'}
        padding={'0px 0px 70px'}
        className={'hero-clipper'}
      >
        <div className={'container hero-container-clipper'}>
          <div>
            <Title className={'underLine'} lineHeight={'52px'}>
              <span>{titles[0]} </span>
              {titles[1]} <span style={{ color: '#ff98a9' }}>{titles[2]} </span>{' '}
              {titles[3]}
            </Title>
            <Description
              size={'19px'}
              lineHeight={'27px'}
              top={'30px'}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className={'actions'}>
              <Button onClick={() => navigate(link)}>
                {navigation_buttons[0]}
              </Button>
            </div>
            <Description
              color={'rgba(0,0,0,.3)'}
              dangerouslySetInnerHTML={{ __html: info }}
            />
          </div>
          <div className={'hero-image-container'}>
            <GatsbyImage alt={'hero'} image={getImage(image)} />
          </div>
        </div>
      </Card>
    </>
  );
};

export default HeroWebClipper;
