import styled from 'styled-components';
import StyledCollapse from 'src/styles/styledComponents/styledCollapse';

const SingleCollapse = styled(StyledCollapse)`
  font-size: 16px;
  margin-bottom: 5px;
  .rc-collapse-item {
    padding: 0;
    box-shadow: none;
    margin-bottom: 0;
  }
  .rc-collapse-item .collapse-arrow {
    top: 0;
    right: -25px;
  }
`;
export default SingleCollapse;
