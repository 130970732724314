import React from 'react';
import styled from 'styled-components';
import Modal from 'react-overlays/Modal';
import { responsiveWidth } from 'src/styles/constants';

const StyledModal = styled(Modal)`
  position: fixed;
  width: ${(props) => props.width || '270px'};
  z-index: 1040;
  top: 50%;
  left: 50%;
  padding: 20px;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  .image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .water-mark {
      width: 150px;
    }
    .info-icon {
      position: absolute;
      height: 32px;
      width: 32px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .content {
    text-align: center;
    margin-top: 20px;
    .title {
      font-size: 16px;
      font-weight: 600;
    }
    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
    .popup-title {
      font-size: 16px;
      font-weight: 600;
    }
    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    margin-top: 30px;
  }
  .action_center {
    display: flex;
  }
  @media ${responsiveWidth.mobile} {
    max-width: 500px;
    width: 100%;
  }
  @media (max-width: 560px) {
    max-width: 300px;
  }
  @media (max-width: 330px) {
    max-width: 260px;
  }
`;
export default StyledModal;
