import styled from 'styled-components';

const FeatureCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10%;
  -webkit-box-align: center;
  align-items: center;

  .gatsby-image-wrapper {
    margin-bottom: 30px;
    & > div {
      max-width: 520px !important;
    }
    img {
      object-fit: contain !important;
    }
  }
  .actions {
    display: flex;
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 0px;
    .actions {
      justify-content: center;
      margin-top: 5px;
    }

    .product-webclipper-description {
      padding-bottom: 50px;
    }
  }
`;
export default FeatureCard;
