import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const Description = styled.div`
  font-size: ${(props) => props.size || '20px'};
  width: ${(props) => props.width || '100%'};
  color: ${(props) => props.color || '#2c3038'};
  margin: 0 0 ${(props) => props.marginBottom || '0'};
  margin-top: ${(props) => props.top || '0'};
  font-weight: ${(props) => props.weight || 'normal'};
  text-align: ${(props) => props.align || 'inherit'};
  padding: ${(props) => props.padding || '0'};
  box-sizing: border-box;
  letter-spacing: ${(props) => props.letterSpace || 'none'};
  line-height: ${(props) => props.lineHeight || 'inherit'};
  &.video{
    em {
      color: #FB6357;
    }
  }
  }
  &.features-description{
  max-width: 729px;
  }

  @media ${responsiveWidth.mobile} {
    width: 100%;
    font-size: 14px;
    line-height: ${(props) => props.mobileLine || 'inherit'};
    &.discover-description{
      font-size: 14px;
  }
    &.challenge-card-description{
      font-size: 20px;
      line-height: 23px;
    }
    &.whyspark-description{
      font-size: 14px;
    }
`;
export default Description;
