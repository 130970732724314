import { graphql, useStaticQuery } from 'gatsby';

export const getPlanCardsANAAlert = (device) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            section_name: { eq: "plan_card_alert" }
            plan_id: { regex: "g/ana_/" }
          }
        }
      ) {
        nodes {
          frontmatter {
            plan_id
            title
            description
            button
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.nodes
    .map((n) => n.frontmatter)
    .find((n) => n.plan_id == `ana_${device}`);
};
