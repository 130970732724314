import React from 'react';
import Button from 'src/styles/styledComponents/button';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';
import Description from 'src/styles/styledComponents/description';

const CallToAction = ({ page }) => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "home_call_to_action" } }
          }
        ) {
          nodes {
            frontmatter {
              description
              titles
              button_text
              link
            }
            id
          }
        }
      }
    `),
    { titles, description, button_text, link } = getFrontmatter(data);
  return (
    <Card align={'center'} color={page ? '#ffffff' : '#fbf8f5'}>
      <Title marginBottom={'20px'}>
        <span style={{ color: '#fe6252' }}>{titles[0]}</span>
        <span> {titles[1]}</span>
      </Title>
      <Description
        marginBottom={'20px'}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Button>
        <a href={link} className="home_call_to_action_link">
          {button_text}
        </a>{' '}
      </Button>
    </Card>
  );
};

export default CallToAction;
