import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter, getFrontmatterOf } from 'src/utility/helpers';
import PageHelmet from 'src/components/pageHelmet';
import {
  GlobalStyle,
  GlobalStyleHome,
  GlobalStyleV2,
  GlobalStyleWhySparkpico,
} from 'src/styles/globalStyles';
import Header from 'src/components/header';
import Footer from 'src/components/footer';
import Hero from 'src/components/whySparkpico/hero';
import WhySparkpicoContent from 'src/components/whySparkpico/whySparkpicoContent';
import WhySparkpicoInfo from 'src/components/whySparkpico/whySparkpicoInfo';
import UseCase from 'src/components/whySparkpico/useCases';
import WhySparkpicoForm from 'src/components/whySparkpico/form';

import { InitialLoader } from '../components/preloader/initialLoader';
import Stores from '../components/home/stores';
import BreadCrumb from '../components/breadCrumb';

const WhySparkpico = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "why_sparkpico_page" } }
          }
        ) {
          nodes {
            frontmatter {
              title
              description
              page_title
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
        header: allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "header" } } }
        ) {
          nodes {
            frontmatter {
              action_buttons
            }
            id
          }
        }
      }
    `),
    info = getFrontmatter(data),
    { action_buttons } = getFrontmatterOf(data, 'header'),
    [loader, setLoader] = useState(true);

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 900);
  }, []);
  const breadCrumb = [
    { label: 'Home page', path: '/' },
    { label: action_buttons[0] },
  ];
  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={info?.title} description={info?.description}>
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </PageHelmet>
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStyleHome />
      <GlobalStyleWhySparkpico />
      <Header />
      <div className={'why-spark'}>
        <BreadCrumb breadCrumb={breadCrumb} padding={'0px'} />
      </div>
      <Hero />
      <WhySparkpicoContent type={'v2'} />
      <WhySparkpicoInfo />
      <UseCase />
      <WhySparkpicoForm />
      <Stores page={'Why-spark'} />
      <Footer />
    </>
  );
};

export default WhySparkpico;
