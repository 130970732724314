import React from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import Card from 'src/styles/styledComponents/card';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import Button from 'src/styles/styledComponents/button';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ProductWebContact = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "product_web_contact" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              link
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              button_text
              description
            }
          }
        }
      }
    `),
    { title, image, button_text, description, link } = getFrontmatter(data);
  return (
    <Card align={'center'} color={'#fbf8f5'}>
      <div className={'container'}>
        <GatsbyImage alt={'features'} image={getImage(image)} />
        <Title
          className={'web-contact'}
          marginBottom={'10px'}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Description
          marginBottom={'30px'}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <Button onClick={() => navigate(link)}>{button_text}</Button>
      </div>
    </Card>
  );
};

export default ProductWebContact;
