import React, { useEffect, useState } from 'react';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';
import Description from 'src/styles/styledComponents/description';
import VerticalTabs from 'src/styles/styledComponents/verticalTabs';
import FlashCard from 'src/styles/styledComponents/flashCard';
import { TabPane } from 'rc-tabs';
import { graphql, useStaticQuery } from 'gatsby';
import MobileTabs from '../../styles/styledComponents2/mobileTabs';

const TabHead = ({ head, descriptions, image }) => (
  <div>
    <div className={'tab-head-image'}>
      <img
        alt={'use-case'}
        src={require(`../../../static/img/${image}`).default}
        width={'100%'}
        height={'auto'}
      />
    </div>
    <div className={'title'} dangerouslySetInnerHTML={{ __html: head }} />
    {descriptions.map((descriptions, index) => (
      <div className={'description-card'} key={index}>
        <img
          src={require('../../images/spark-fill.svg').default}
          className={'spark-icon'}
          alt={'spark-fill'}
        />
        <span
          className={'description'}
          dangerouslySetInnerHTML={{ __html: descriptions }}
        />
      </div>
    ))}
  </div>
);

const UseCase = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "use_cases" } } }
        ) {
          nodes {
            id
            frontmatter {
              navigation_buttons
              description
              title
              use_case_tabs {
                detailed_description
                description
                template
                title
                image {
                  base
                }
                detailed_description_in_points {
                  point_1
                  point_2
                  point_3
                  point_4
                  point_5
                }
              }
            }
          }
        }
      }
    `),
    { title, description, use_case_tabs } =
      data.allMarkdownRemark.nodes[0].frontmatter;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth),
    [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    function reportWindowSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, []);

  return (
    <Card className={'use-case-container'} align={'center'}>
      <div className={'container'}>
        <div className={'about'}>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        {windowWidth > 768 ? (
          <VerticalTabs defaultActiveKey={'0'} tabbarposition={'left'}>
            {use_case_tabs.map((tab, index) => (
              <TabPane
                tab={
                  <TabHead
                    head={tab.title}
                    descriptions={Object.values(
                      tab?.detailed_description_in_points
                    ).filter((value) => value !== '')}
                    image={tab.image?.base}
                  />
                }
                key={index}
              >
                <FlashCard
                  className={'tab'}
                  shadow={'none'}
                  color={'transparent'}
                >
                  <img
                    alt={'use-case'}
                    src={
                      require(`../../../static/img/${tab.image?.base}`).default
                    }
                    width={'100%'}
                    height={'auto'}
                  />
                  {tab.detailed_description === undefined ? (
                    <Description
                      size={'20px'}
                      className={'description'}
                      width={'auto'}
                      dangerouslySetInnerHTML={{
                        __html: tab.detailed_description,
                      }}
                    />
                  ) : (
                    ''
                  )}
                </FlashCard>
              </TabPane>
            ))}
          </VerticalTabs>
        ) : (
          <div className={'use-case-mobile'}>
            {use_case_tabs.map((tab, index) => (
              <MobileTabs
                key={index}
                className={tabIndex === index && 'active-tab'}
                onClick={() => setTabIndex(index)}
              >
                {tabIndex === index ? (
                  <>
                    <div className={'tab-head-image'}>
                      <img
                        alt={'use-case'}
                        src={
                          require(`../../../static/img/${tab.image?.base}`)
                            .default
                        }
                        width={'100%'}
                        height={'auto'}
                      />
                    </div>
                    <div
                      className={'title'}
                      dangerouslySetInnerHTML={{ __html: tab.title }}
                    />
                    {Object.values(tab?.detailed_description_in_points)
                      .filter((value) => value !== '')
                      .map((descriptions, index) => (
                        <div className={'description-card'} key={index}>
                          <img
                            src={require('../../images/spark-fill.svg').default}
                            className={'spark-icon'}
                            alt={'spark-fill'}
                          />
                          <span
                            className={'description'}
                            dangerouslySetInnerHTML={{ __html: descriptions }}
                          />
                        </div>
                      ))}
                  </>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tab.title,
                    }}
                  />
                )}
              </MobileTabs>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
};

export default UseCase;
