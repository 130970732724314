import { graphql, useStaticQuery } from 'gatsby';

const getRoadmapPosts = () => {
  const posts = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            id: { eq: "release-notes-item" }
            status: { eq: "Published" }
          }
        }
        sort: { fields: [frontmatter___released_at], order: DESC }
        limit: 3
      ) {
        nodes {
          frontmatter {
            title
            description
            type
            author
            released_at
            status
            featured_image {
              id
              publicURL
              name
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.792)
              }
            }
          }
          parent {
            ... on File {
              birthTime(formatString: "MM/DD/YYYY")
            }
          }
        }
      }
    }
  `);

  return posts.allMarkdownRemark.nodes;
};

export default getRoadmapPosts;
