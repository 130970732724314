import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const StyledComparisonTabs = styled.div`
  width: 100%;
  display: none;
  text-align: left;
  margin-top: 20px;

  .plan-info {
    margin-bottom: 20px;
    text-align: left;
    .price {
      font-size: 57px;
      margin-left: 20px;
    }
    .dollar {
      font-size: 19px;
      top: 18px;
      left: -12px;
    }
  }
  // @media ${responsiveWidth.mobile} {
  //   display: block;
  // }
`;
export default StyledComparisonTabs;
