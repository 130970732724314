import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const NavButton = styled.button`
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: none;
  background-color: #fff;
  padding: 10px 30px;
  border-radius: 8px;
  color: ${(props) => props.textColor || '#fe6253'};
  font-size: 22px;
  margin-top: ${(props) => props.marginTop || '70px'};
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  transition: all 0.3s;
  &:hover {
    background-color: #fe6253;
    color: #fff;
    span {
      border-left-color: #fff;
    }
  }
  .features_button_link {
    padding: 10px 30px;
    width: 100%;
    display: block;
    text-align: center;
    text-decoration: none;
    color: #fe6253;
    &:hover {
      color: #fff;
    }
  }
  span {
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 10px solid ${(props) => props.color || '#fe6253'};
    margin-left: 10px;
    ${(props) => props.hideArrow && 'display: none'};
  }

  @media ${responsiveWidth.mobile} {
    width: 100%;
    margin-top: 30px;
    font-size: 16px;
  }
`;
export default NavButton;
