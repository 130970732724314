import React from 'react';
import styled from 'styled-components';

const VoiceCommanders = styled.div`
  .voice_commands {
    background-color: #ffffff;
    height: 60%;
    overflow-x: auto;
    padding: 40px 50px;

    @media (max-width: 759px) {
      background-color: #f2f4f9;
      padding-top: 25px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .header-title {
        font-size: 18px;
      }

      @media (max-width: 759px) {
        .header-title {
          display: none;
        }
      }

      .format {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .view-images {
          display: flex;
          align-items: center;
          margin-right: 15px;
          background-color: #ffffff;
          
          .image {
            padding: 10px;
            cursor: pointer;
          }
          
          .active-command {
            border: 2px solid #FFA303;
            border-radius: 5px 0 0 5px;
          }
          .active-conversation {
            border: 2px solid #FFA303;
            border-radius: 0 5px 5px 0;
          }
          .passive_left {
            border: 2px solid #d9d9d9;
            border-radius: 5px 0 0 5px;
            border-right: none;
          }
          .passive_right {
            border: 2px solid #d9d9d9;
            border-radius: 0 5px 5px 0;
            border-left: none;
          }
        }
        
        .sort-images {
          display: flex;
          align-items: center;
          margin-right: 15px;
          border: 2px solid #d9d9d9;
          border-radius: 5px;
          background-color: #ffffff;

          .image {
            padding: 10px;
            cursor: pointer;

            &:first-child {
              border-right: 2px solid #d9d9d9;
            }
          }
        }
      }
    }

    .card_container {
      min-width: 80% !important;
      width: 100%;

      .card {
        margin-bottom: 15px;

        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          padding: 15px 20px;
          z-index: 1;

          .card-title {
            font-size: 18px;
          }

          @media (max-width: 759px) {
            padding: 10px 20px;v
          }
        }

        .collapse {
          position: relative;
          right: 20px;
          transition: transform 300ms;
        }

        .is-open {
          transform: rotate(0.5turn);
          transition: transform 300ms;
        }

        .block {
          .toggle {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: none;
            background: #ffffff;
            box-shadow: 1px 2px 9px rgba(209, 209, 209, 0.49);
            border-radius: 10px 10px 5px 5px;
            width: 100%;
          }
        }

        .collapse-css-transition {
          position: relative;
          bottom: 3px;

          @media (max-width: 759px) {
            box-shadow: 0px -2px 0px 0px rgb(209 209 209 / 49%);
          }

          .card-data {
            background-color: rgb(240 240 240 / 74%);
            padding: 20px 24px;
            z-index: 0;
            position: relative;
            bottom: 10px;
            border-radius: 5px;
            line-height: 35px;

            @media (max-width: 759px) {
              background-color: #ffffff;
              padding-bottom: 30px;
            }
            .card_title {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
            }
            .card_description {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: #6A6969;
              padding-bottom: 20px;
              padding-top: 5px;
            }
          }
        }
      }
    }
  }
`;
export default VoiceCommanders;
