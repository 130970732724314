import React from 'react';
import PropTypes from 'prop-types';

import Table from './table';

import {
  Container,
  ContentWrapper,
  ContainerIntro,
} from '../../../styles/components/subprocessors/content';

const Content = ({ title, updateDate, html, subprocesses }) => {
  const replacePatternToComponent = (text, pattern) => {
    const splitText = text.split(pattern);
    const matches = text.match(pattern);

    if (splitText.length <= 1) {
      return text;
    }

    const finalArr = splitText.reduce((arr, element, index) => {
      if (!element) return arr;

      if (matches.includes(element)) {
        const deviceType = element.split('type="')[1].split('"}}')[0];

        return [
          ...arr,
          <Table
            processes={subprocesses}
            deviceType={deviceType}
            key={index}
          />,
        ];
      }

      return [...arr, element];
    }, []);

    return finalArr.map((e, index) => {
      if (typeof e === 'string') {
        return <div dangerouslySetInnerHTML={{ __html: e }} key={index} />;
      }

      return e;
    });
  };

  const pattern =
    /(\{\{subprocessors type="iOS"\}\})|(\{\{subprocessors type="Android"\}\})|(\{\{subprocessors type="Laravel Package"\}\})|(\{\{subprocessors type="Backend"\}\})/g;

  const date = new Date(updateDate);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = monthNames[date.getMonth()];
  const day = date.getDay();
  const year = date.getFullYear();

  return (
    <Container className="subprocessors--container">
      <ContainerIntro className="subprocessors--container_intro">
        <h1>{title}</h1>
        <p>Last updated: {`${month} ${day}, ${year}`}</p>
      </ContainerIntro>

      <ContentWrapper className="subprocessors--cotent_wrapper">
        {replacePatternToComponent(html, pattern)}
      </ContentWrapper>
    </Container>
  );
};

Content.protoTypes = {
  title: PropTypes.string.isRequired,
  updateDate: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  subprocesses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      activities: PropTypes.string.isRequired,
      license_link: PropTypes.string.isRequired,
      license_name: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      device: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Content;
