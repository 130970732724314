import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const InputWrapperSpark = styled.div`
  position: relative;

  label {
    position: absolute;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
    font-size: ${(props) => (props.isFocus ? '12px' : '16px')};
    opacity: ${(props) => props.isFocus && '0'};
    top: ${(props) => (props.isFocus ? '15px' : '50%')};
    transform: translateY(-50%);
    left: 20px;
    transition: all 0.3s;
  }
  @media ${responsiveWidth.mobile} {
    label {
      right: 55px;
      left: 10px;
    }
  }
`;
export default InputWrapperSpark;
