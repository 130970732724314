import React from 'react';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';
import { getFrontmatters } from 'src/utility/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

const WhySparkpicoInfo = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "why_sparkpico_info" } }
          }
        ) {
          nodes {
            frontmatter {
              title
              image {
                childImageSharp {
                  gatsbyImageData(width: 300)
                }
              }
            }
          }
        }
      }
    `),
    info = getFrontmatters(data);
  return (
    info != false && (
      <Card align={'center'}>
        <div className={'container'}>
          <GatsbyImage alt={'info'} image={getImage(info.image)} />
          <Title
            style={{ marginTop: '30px' }}
            marginBottom={'0'}
            size={'32px'}
            letterSpace={'-1px'}
            dangerouslySetInnerHTML={{ __html: info.title }}
          />
        </div>
      </Card>
    )
  );
};

export default WhySparkpicoInfo;
