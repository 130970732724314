import React from 'react';
import styled from 'styled-components';
import { breakPoints } from '../../constants';

export const Container = styled.div`
  background-color: white;
`;

export const Wrapper = styled.div`
  margin: auto;

  @media (max-width: ${breakPoints.pcSmall}) {
    max-width: 90vw;
    padding-bottom: 1px;
  }

  .title-container {
    .title {
      justify-content: flex-start;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    max-width: 900px;

    .title-container {
      .title {
        font-size: 40px;
      }

      div {
        font-size: 27px;
      }
    }

    .feature {
      div {
        &:first-child {
          span {
            &:first-child {
              font-weight: 600;
            }
          }
        }

        span {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }

  @media (min-width: ${breakPoints.pc}) {
    max-width: 1100px;

    .title-container {
      .title {
        font-size: 49px;
      }

      div {
        font-size: 33px;
      }
    }

    .feature {
      div {
        span {
          font-size: 22px;
          &.basic {
            font-size: 20px;
          }
        }
      }
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    max-width: 1200px;

    .title-container {
      .title {
        font-size: 54px;
      }

      div {
        font-size: 36px;
      }
    }

    .feature {
      div {
        span {
          font-size: 25px;
        }
      }
      .info {
        padding: 0;
      }
    }
  }
`;

export const PlanTable = styled.div`
  /* @media (max-width: ${breakPoints.pcSmall}) {
    & > div {
      display: none !important;
    }
  } */

  & > div {
    display: none;
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    & > div {
      display: block;
    }
  }
`;

export const PlanCollapse = styled.div`
  @media (max-width: ${breakPoints.pcSmall}) {
    & > div {
      display: block;
    }
  }

  @media (min-width: ${breakPoints.tablet}) {
    & > div {
      display: block;

      & > div {
        &:first-child {
          padding: 29px 36px 56px 36px;
          margin-bottom: 90px;
        }
      }

      .plan-info {
        .name {
          font-size: 31px;
        }

        .discount {
          text-align: right;
          position: relative;
          bottom: 100px;
        }

        .price {
          font-size: 72px;

          .dollar {
            font-size: 22px;
          }

          .duration {
            position: relative;
            top: 35px;
            right: 73px;
          }
        }

        .description {
          font-size: 17px;
          font-weight: 400;
        }
      }

      .rc-collapse {
        .rc-collapse-item {
          padding-left: 0;

          .rc-collapse-header {
            padding: 10px 0;
            padding-left: 0;

            .collapse-arrow {
              top: 25px;
            }

            h1 {
              font-size: 27px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    display: none;
  }
`;

export const PlanTitle = styled.div`
  text-align: center;
  font-size: 45px;

  h2 {
    margin: 0;
  }

  @media (max-width: ${breakPoints.tablet}) {
    h2 {
      font-size: 30px;
      padding-top: 45px;
      max-width: 580px;
      margin: auto;
    }
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    h2 {
      font-size: 46px;
      padding-top: 45px;
      max-width: unset;
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    h2 {
      font-size: 62px;
    }
  }
`;
