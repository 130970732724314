import React from 'react';
import styled from 'styled-components';
import { breakPoints } from '../../constants';

export const Container = styled.div`
  background-color: #fbf8f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.hero {
    background-image: linear-gradient(#fbf8f5 57%, white 35%);
  }
  @media (min-width: ${breakPoints.pcSmall}) {
    padding: 0px 0 30px 0 !important;
  }

  @media (min-width: ${breakPoints.pc}) {
    padding: 0px 0 64px 0 !important;
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    padding: 0px 0 85px 0 !important;
  }
`;

export const MainContent = styled.div`
  max-width: 90vw;
  margin-bottom: 16px;
  h1 {
    color: #322e2e;
    margin: 0 auto;
    text-align: center;
    font-size: 45px;
    margin-bottom: 18px;
  }

  p {
    color: #322e2e;
    margin: 0 auto;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
    margin-bottom: 21px;
  }

  @media (max-width: ${breakPoints.tablet}) {
    h1 {
      font-size: 30px;
      margin-top: 50px;
      text-align: center;
    }

    p {
      font-size: 20px;
    }
  }

  @media (min-width: ${breakPoints.mobileSmall}) {
    h1 {
      margin-top: 30px;
      text-align: center;
    }
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    max-width: 788px;
    padding-top: 25px;

    h1 {
      margin-bottom: 35px;
    }
  }

  @media (min-width: ${breakPoints.pc}) {
    max-width: 1100px;

    h1 {
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    max-width: 1078px;
    padding-top: 30px;
    margin-bottom: 16px;
  }
`;

export const CardContainer = styled.div`
  @media (max-width: ${breakPoints.tablet}) {
    max-width: 80vw;
    width: 100%;

    & > div {
      width: 100%;
      row-gap: 69px;

      & > div {
        padding: 34px 20px;
        margin: 5px;

        .name {
          font-size: 28px;
          margin-bottom: 16px !important;
          padding-top: 10px !important;
        }

        .features {
          div {
            span {
              font-size: 20px;
            }
          }
        }

        button {
          width: 100%;
        }
      }
    }
  }

  @media (min-width: ${breakPoints.tablet}) {
    & > div {
      & > div {
        .features {
          margin-top: 50px;

          div {
            span {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  @media (max-width: ${breakPoints.pcSmall}) {
    width: 80vw;

    .price-container {
      .name {
        font-size: 28px;
        margin-bottom: 16px;
        padding-top: 15px;
      }

      .price {
        line-height: 55px;
      }

      .description {
        font-size: 28px;
      }
    }

    & > div {
      grid-template-columns: 1fr;
      grid-gap: 70px;
      width: 100%;

      .label {
        transform: rotate(0) translate(-50%, -100%);
        top: 0;
        left: 50%;
        border-radius: 8px 8px 0 0;
        position: absolute;
        padding: 5px 0px;
      }

      & > div {
        &[type='zoom'] {
          transform: none;

          .price-container {
            & > div {
              &:first-child {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .label {
              max-width: 184px;
              font-size: 20px;
            }
          }
        }
        .pricing-detail {
          display: none;
        }
        &:first-child {
          padding: 34px;
        }

        button {
          max-width: 184px;
          width: 100%;
        }
      }
    }
  }
`;

export const SloganContent = styled.div`
  max-width: 90vw;
  margin: auto;
  margin-top: 59px;
  text-align: center;
  margin-bottom: 59px;

  a {
    color: #fe6152;
    font-weight: bold;
    font-size: 19px;
  }
  /* 
  h3,
  p {
    margin: 0;
  } */

  h3 {
    font-size: 24px;
  }

  p {
    font-size: 21px;
  }

  @media (min-width: ${breakPoints.tablet}) {
    max-width: 645px;

    h3 {
      margin: 44px 0 30px 0;
    }
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    max-width: 658px;
    margin-bottom: 0;

    a {
      font-size: 24px;
    }

    h3 {
      margin: 42px 0 24px 0;
      font-size: 27px;
    }

    p {
      font-size: 21px;
    }
  }

  @media (min-width: ${breakPoints.pc}) {
    max-width: 900px;

    a {
      font-size: 29px;
    }

    h3 {
      font-size: 33px;
      margin: 51px 0 30px 0;
    }

    p {
      font-size: 25px;
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    a {
      font-size: 33px;
    }

    h3 {
      margin: 57px 0 33px 0;
      font-size: 37px;
    }

    p {
      font-size: 29px;
    }
  }
`;
