import React from 'react';
import Card from 'src/styles/styledComponents/card';
import { graphql, useStaticQuery } from 'gatsby';
import Description from '../../styles/styledComponents/description';
import Title from '../../styles/styledComponents/title';
import Topic from '../../styles/styledComponents/topic';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { transformationColors } from '../../styles/constants';
import Arrow from '../../styles/styledComponents2/arrow';
import ImageBox from '../../styles/styledComponents2/imageBox';

const Transformation = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "transformation" } } }
        ) {
          nodes {
            frontmatter {
              title
              topic
              step_1 {
                description
                image {
                  childImageSharp {
                    gatsbyImageData(width: 300)
                  }
                }
                title
              }
              step_2 {
                title
                description
                image {
                  childImageSharp {
                    gatsbyImageData(width: 300)
                  }
                }
              }
              step_3 {
                description
                title
                image {
                  childImageSharp {
                    gatsbyImageData(width: 300)
                  }
                }
              }
            }
          }
        }
      }
    `),
    { topic, title, step_1, step_2, step_3 } =
      data.allMarkdownRemark.nodes[0].frontmatter,
    steps = [step_1, step_2, step_3];
  return (
    <Card className={'transformation'}>
      <div className={'container'}>
        <Topic dangerouslySetInnerHTML={{ __html: topic }} />
        <Title dangerouslySetInnerHTML={{ __html: title }} align={'center'} />
        <div className={'steps-container'}>
          {steps.map((step, index) => (
            <div key={index}>
              <ImageBox
                color={transformationColors[index]}
                className={'image-box'}
              >
                <GatsbyImage alt={step.title} image={getImage(step.image)} />
                <Arrow
                  color={transformationColors[index]}
                  rotate={index === 1 ? 'rotate(90deg)' : null}
                />
                <div>
                  <Topic
                    size={'35px'}
                    color={transformationColors[index]}
                    bottom={'20px'}
                    dangerouslySetInnerHTML={{ __html: step.title }}
                  />
                  <Description
                    size={'24px'}
                    dangerouslySetInnerHTML={{ __html: step.description }}
                  />
                </div>
              </ImageBox>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Transformation;
