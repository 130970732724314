import styled from 'styled-components';
import React from 'react';

const LearnButton = styled.button`
  font-family: 'AvertaStd-Bold';
  margin: auto;
  background: transparent;
  border: none;
  color: #ffa303;
  font-size: 20px;
  line-height: 23px;
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default LearnButton;
