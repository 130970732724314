import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const FormWrapper = styled.form`
  margin-top: 40px;

  .names,
  .contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
  }
  .why-sparkpico {
    .signup-button {
      margin-left: 10px;
    }
    .info {
      margin: 24px 187px 24px 0px;
    }

    & > *:not(:last-child) {
      display: inline-block;
    }
  }
  & > *:not(:last-child) {
    margin-bottom: 40px;
  }
  input,
  textarea {
    border-radius: 8px;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background-color: #fff;
    padding: 20px;
    border: none;
    outline: none;
    font-size: 22px;
    width: calc(100% - 40px);
    &.spark-input {
      width: 280px;
      padding: 7px;
      border-left: 9px solid #fe6152;
    }
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  @media ${responsiveWidth.mobile} {
    .names,
    .contact {
      grid-template-columns: 1fr;
    }

    .why-sparkpico {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .mobile-actions {
        display: inline-flex;
        justify-content: center;
        margin-top: 10px;
        .signup-button {
          margin-top: 10px;
        }

        .info {
          margin: 10px 0 0 8px;
          width: 180px;
          text-align: left;
        }
      }
      .spark-input {
        width: 300px;
      }
    }
  }
`;
export default FormWrapper;
