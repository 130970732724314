import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const MasonaryList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
  &:not(:first-child) {
    margin-top: 100px;
  }
  & > * {
    text-align: left;
    h1 {
      margin-top: 50px;
    }
    &:nth-child(2) {
      margin-top: 100px;
    }
    &:nth-child(5) {
      margin-top: 100px;
    }
  }
  @media ${responsiveWidth.mobile} {
    grid-template-columns: 1fr;
    &:not(:first-child) {
      margin-top: 30px;
    }
    & > * {
      margin-top: 0;
      h1 {
        margin-top: 20px;
      }
      &:nth-child(even),
      &:nth-child(5) {
        margin-top: 0;
      }
    }
  }
`;
export default MasonaryList;
