/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';
import Description from 'src/styles/styledComponents/description';
import NavButton from 'src/styles/styledComponents/navButton';
import Topic from 'src/styles/styledComponents/topic';
import { TabPane } from 'rc-tabs';
import HorizontalTabs from 'src/styles/styledComponents/horizontalTabs';
import { graphql, useStaticQuery } from 'gatsby';
import { discoverBackground, discoverColors } from 'src/styles/constants';

const Discover = () => {
  const data = useStaticQuery(graphql`
      {
  allMarkdownRemark(filter: {frontmatter: {section_name: {eq: "discover"}}}) {
    nodes {
      id
      frontmatter {
        title
        topic
        background {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        discover_tabs {
          banner_media {
            base
            extension
          }
          head
          head_image {
            base
          }
          navigation_button
          show_navigation_button
          navigation_button_link
          title
          replaces {
            title
            icons {
              childImageSharp {
                gatsbyImageData(height: 36)
              }
            }
          }
          description
          head_image_active {
            base
          }
          highlighted_word_in_title
          link
        }
      }
    }
  }
}
    `),
    { title, topic, discover_tabs, background } =
      data.allMarkdownRemark.nodes[0].frontmatter;
  const [backGround, setBackGround] = useState(0);
  const [selectedTab, setSelectedTab] = useState('0');

  const Highlighted = ({ text, highlight}) => {
    if (!highlight.trim()) {
      return <span className={'title'}>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const title = text.split(regex);
    return (
      <span className={'title'}>
      {title.filter(String).map((text, index) => regex.test(text) ? (
          <span key={index} style={{ color: discoverColors[backGround]}}>{text}</span>
        ) : (
          <span key={index}>{text}</span>
        ))}
    </span>
    );
  };

  return (
    <Card className={'discover'} background={background?.childImageSharp.fluid.src}
          attachment={'fixed'} size={'cover'}>
      <div className={'container discover-container'}>
        <Topic
          className={'topic-discover'}
          dangerouslySetInnerHTML={{ __html: topic }}
        />
        <Title
          className={'title-discover'}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <HorizontalTabs
          defaultActiveKey={'0'}
          onChange={(index) => {
            setSelectedTab(index);
            setBackGround(index);
          }}
          color={discoverColors[backGround]}
          active={discoverBackground[selectedTab]}
          activeKey={`${selectedTab}`}
        >
          {discover_tabs.map((tab, index) => (
            <TabPane
              tab={
                <div className={'tab-head'}>
                  <span>{tab.head}</span>{' '}
                </div>
              }
              key={index}
            >
              <div className={'tab-content'}>
                <div className={'media-container'}>
                  {tab.banner_media
                    ?.extension === 'webm' ||
                  tab.banner_media?.extension === 'mp4'
                    ? <video
                      controls={false}
                      autoPlay
                      loop
                      muted
                      src={
                        require(`../../../static/img/${tab.banner_media?.base}`)
                          .default} />
                    : <img
                      src={
                        require(`../../../static/img/${tab.banner_media?.base}`)
                          .default
                      }
                      alt={'discover'}
                    />
                  }
                </div>
                <div>
                  <Highlighted
                    text={tab?.title}
                    highlight={tab?.highlighted_word_in_title}
                  />
                  {/*<div className={'typing-text'}>{!!tab.changable_text.length && <Typed strings={tab.changable_text} typeSpeed={200} backSpeed={70} loop backDelay={2}/>}</div>*/}
                  <Description size={'19px'} className={'discover-description'}>
                    <div
                      dangerouslySetInnerHTML={{ __html: tab.description }}
                    />
                  </Description>
                  {tab.show_navigation_button === 'true' ? (
                    <a href={tab.navigation_button_link}
                      className='nav_button_link'>
                      <NavButton
                        textColor={'#2c3038'}
                        color={discoverColors[backGround]}
                      >{tab.navigation_button}
                        <span />
                      </NavButton>
                    </a>
                  ) : (
                    <div> </div>
                  )}
                  <div className={'navigation-link'}>
                  <a href={tab.navigation_button_link}>
                    {tab.link}
                  </a>
                  </div>
                </div>
              </div>
            </TabPane>
          ))}
        </HorizontalTabs>
      </div>
    </Card>
  );
};

export default Discover;
