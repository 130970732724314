import {
  basicFont,
  breakPoints,
  containerWidth,
  responsiveWidth,
} from './constants';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
body {
  background-color: #f2f4f9;
  font-family: ${basicFont};
  margin: 0;
  font-size: 16px;
  line-height: 1.2;
  color: #2c3038;
}
.breadcrumb{
  display: flex;
  align-items: center;
  font-family: ${basicFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin-right: 5px;
  padding-bottom: 40px;
  .navigation{
  display: flex;
  align-items: center;
  }
  .breadCrumb-text{
    text-decoration: none;
    color: #C4C4C4;
  }
}
.features-hero{
  padding: 70px 140px !important;
  background-color: #ffffff;
  text-align: center;
}
 .video-js .vjs-big-play-button {
height: 100px !important;
width: 100px !important;
border-radius: 50px !important;
background-color:#FB6357;
.vjs-icon-placeholder:before {
  font-size: 75px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
}
button {
 font-family: ${basicFont};
}
button.rc-tabs-nav-more {
display: none;
}
.rc-collapse {
  background-color: transparent;
}
.slider-container {
  text-align: left;
  .slick-track{
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .slick-slide{
   width: 370px !important;
  }
  .slick-slide > div {
    margin: 0 10px 0 10px;
  }
  .slick-list {
    padding: 50px 0;
  }
}
.slick-arrows {
  display: flex;
  justify-content: flex-end;
  & > div:not(:last-child) {
    margin-right: 10px;
  }
}
*:focus-visible, *:focus {
  outline: none !important;
}
div:focus:not(.focus-visible) {
  outline: none;
}
.container {
  max-width: ${containerWidth};
  width: 100%;
}
.container-info{
  max-width: 920px;
  width: 100%;
}
.container-product{
  max-width: 1250px;
  width: 100%;
}
.container-features{
  max-width: 1110px;
  width: 100%;
}
.tablet-image{
 img{
  object-fit: contain !important;
  }
}
.arrow-button {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.arrow{
  margin: 0;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  position: absolute;
  border-radius: 2px;
  border-bottom: 50px solid #FFA303;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  z-index: 1;
}

.typed-cursor {
  color: rgba(0, 0, 0, 0.3);
  font-weight: 100;
}
.info-quotes{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1),
  30px 42px 54px 0 rgba(0, 0, 0, 0.05),
    inset -10px 0 0 0 #FFA303;
  background-color: #fff;
  padding: 20px;
  font-size: 18px;
  color: #515459;;
  font-weight: 400;
  text-align: left;
}
.footer-container {
  color: #fff;
  display: grid;
  grid-template-columns: repeat(6, auto);

  a {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-weight: normal;
  }
  .head {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 15px;
  }
  .visit-list{
    display: flex;
    flex-direction: column;
    gap:20px;
    .visit-icons{
      display: flex;
      gap: 10px;
      .qr-code {
        position: relative;
        display: inline-block;
        cursor: pointer;
        .qr-hover {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 99;
          @media (min-width: ${breakPoints.pcLarge}) {
            top: 3px;
          }
        }
        &.qr-code:hover .qr-hover {
          display: inline;
          padding: 0px !important;
        }
      }
    }
  }
  & > div {
    padding: 30px 0;
  }
  .list {
    & > div:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .actions > *:not(:last-child) {
    margin-right: 20px;
  }
}
.__react_component_tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}
@media ${responsiveWidth.mobile} {
  body {
    padding: 10px;
  }
  .plans-page-stores{
    display: none!important;
  }
  .use-case-mobile{
    margin-top:30px;
  }
  
  .mobile-tabs{
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 30px 10px;
  }
  .arrow-button {
    width: 40px;
    height: 40px;
    img {
      width: 32px;
      height: 32px;
    }
  }
  .about {
    h1{
      padding-bottom: 0px !important;
    }
  }
  .info-quotes{
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1),
    30px 42px 54px 0 rgba(0, 0, 0, 0.05),
    inset 0 -10px 0 0 #FFA303;
  }
  .video-js .vjs-big-play-button {
    height: 50px !important;
    width: 50px !important;
    border-radius: 50px !important;
    .vjs-icon-placeholder:before {
      font-size: 42px !important;
      top: 0px !important;
}
  }
  .slick-list {
    min-height: 400px;
  }
  .slider-container {
    margin-top: 30px;
    .slick-slide > div {
      margin-right: 10px;
    }
  }
  .breadcrumb{
    display: none;
  }
  .features-hero{
    padding:80px 10px 10px !important;
  }
  .footer-container {
    grid-template-columns: 1fr;
    & > div {
      padding: 15px;
    }
    .head {
      margin-bottom: 10px;
    }
  }
  .visit-list{
    display: block !important;
  }
  .arrow{
    border-bottom: 25px solid #FFA303;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
  }
}
`;

export const GlobalStyleHome = createGlobalStyle`
.steps-container {
  display: grid;
  grid-gap: 20px;
  & > div:nth-child(2){
    .image-box{
      display: grid;
      grid-template-columns: 35% 10% 25%;
      grid-gap: 20px;
    }
    div:nth-of-type(1) {
    order:3;
    }
    div:nth-of-type(2) {
     order:2;
    }
    div:nth-of-type(3) {
    order:1;
    }
  }
}
.stores-container {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-column-gap: 20px;
  .store-grid {
    padding:100px 50px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 4px;
   &.mobile{
     margin-bottom: 40px;
   }
    span:nth-child(1) {
      color: #fe6252;
    }
    span:nth-child(2){
      display: none;
    }
  }
  .action {
    margin: 20px auto auto;
    &.web{
      margin-top: 40px;
    }
    & > *:not(:last-child) {
      margin-right: 20px;
    }
    & > div {
    cursor: pointer;
    }
  }
  @media (max-width: 1405px) {
    .action-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & > *:not(:last-child) {
        width: 100%;
      }
      & > div {
        border-radius: 10px;
      }
    }
  }
}
.text-for-mobile {
  display: none !important;
}
.faq-container {
  text-align: center;
  margin: 0 auto;
  h1 {
    margin-bottom: 0;
  }
  .rc-collapse {
    margin-top: 40px;
  }
  .actions {
    margin-top: 70px;
    text-align: left;
    display: flex;
    justify-content: center;
    button:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.video-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.discover-container {
  text-align: center;
  .rc-tabs {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
    video {
      max-width: 575px;
      max-height: 436px;
      width: 100%;

      @media (max-width: 1060px) {
        max-width: 430px;
      }
      @media (max-width: 500px) {
        max-width: 300px;
      }
      @media (max-width: 350px) {
        max-width: 270px;
      }
    }
  }
  .gray-title {
    color: rgba(0, 0, 0, 0.3);
    font-size: 22px;
    font-weight: 500;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .icons > *:not(:last-child) {
    margin-right: 10px;
  }
  button {
    margin-top: 30px;
    letter-spacing: -0.5px;
  }
  .typing-text {
    color: #fe6253;
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 30px;
  }
}

.hero-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1.1fr;
  align-items: end;

  .actions {
    margin-top: 40px;
    display: inline-flex;
    button:not(:last-child) {
      margin-right: 20px;
    }
  }
  .hero-image-container {
    position: absolute;
    right: -160px;
    bottom: -95px;
    width: 860px;
  }
}
.use-case-container {
  .actions {
    display: flex;
    justify-content: flex-start;
    button:not(:last-child) {
      margin-right: 30px;
    }
  }
}
.meet-sparkpico {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.features {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .features-list {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    text-align: left;
    margin-top: 40px;
    div:nth-of-type(1) {
      order: 1;
    }

    div:nth-of-type(2) {
      order: 2;
    }

    div:nth-of-type(3) {
      order: 4;
    }

    div:nth-of-type(4) {
      order: 3;
    }
    div:nth-of-type(5) {
      order: 5;
    }

    div:nth-of-type(6) {
      order: 6;
    }

    div:nth-of-type(7) {
      order: 8;
    }

    div:nth-of-type(8) {
      order: 7;
    }
    div:nth-of-type(9) {
      order: 9;
    }

    div:nth-of-type(10) {
      order: 10;
    }

    div:nth-of-type(11) {
      order: 12;
    }

    div:nth-of-type(12) {
      order: 11;
    }
    
    & > div {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 30px;
      justify-items: center;
      align-items: center;
      & > * {
        margin: 0;
      }
      a{
        color: #FB6357;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .feature_btn {
    padding: 0;
  }
}

.use-case-container {
  padding: 100px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .actions {
    text-align: left;
    padding: 0 70px;
    align-self: flex-start;
  }
}

.challenges-container {
  text-align: center;
   max-width: 1150px;
  .action {
    display: flex;
    justify-content: center;
  }
  .challenges-card{
    display: grid;
    grid-template-rows: auto auto;
    margin-bottom: 20px;
    justify-items: center;
    }
}

.animation-container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.discover-container {
  .dot{
    margin-top: 20px;
  }
  .arrow-button {
    position: absolute;
    top: 400px;
    margin: 15px;
    @media (max-width: 500px) {
      top: 430px;
    }
    &.left {
      left: 0;
      transform: translateX(-20%);
    }
    &.right {
      right: 0;
      transform: translateX(20%);
    }
  }
}
@media (max-width: 1250px) {
 .hero-container .hero-image-container {
  width: 800px;
 }
}
@media ${responsiveWidth.heroLayout} {
  .hero-container {
    grid-template-columns: 1fr;
    text-align: center;
    grid-gap: 20px;
    text-align: left;
    & > div:nth-child(1) {
      order: 1;
    }
    & > div:nth-child(2) {
      order: 0;
    }
    .hero-image-container {
    position: static;
    width: 100%;
    }
  }
}

//For mobile
@media ${responsiveWidth.mobile} {
  .hero-container {
  text-align: left;
    .title{
      text-align: left !important;
    }
    .actions {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      button:first-child {
      order:2;
      padding: 10px 32px;
      margin-top: 20px;
      }
    }
    .actions button:not(:last-child) {
      margin-right: 15px;
    }
  }
  .info-graphic-container {
      margin-right:55px;
      transform: scale(0.3);
      transform-origin: top;
      margin-bottom: calc((0.3 - 1) * 600px);
  }

  .challenges-container {
    overflow: hidden;
    .slick-arrows {
      justify-content: center;
    }
  }

  .features {
    .features-list {
      grid-template-columns: 1fr;
      grid-gap: 10px;
      & > div {
        grid-gap: 20px;
        a{
          font-size: 14px;
          font-weight: 400;
        }
      }
      & > div > *:not(.image-wrapper) {
        padding: 15px;
        border-radius: 8px;
        h1 {
          font-size: 14px;
          line-height: 21px;
          text-align: left !important;
          padding-bottom: 5px !important;
        }
        div {
          font-size: 11px;
        }
      }
    }
    .image-wrapper {
      border-radius: 8px;
      img {
        width: 87px;
        height: 87px;
      }
    }
    .feature_btn {
      display: none;
    }
  }

  .steps-container {
    grid-template-columns: 1fr;
    text-align: center;
    & > div:nth-child(2){
      .image-box{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .stores-container .container img {
    height: 67px;
    width: 67px;
    margin-bottom: 30px;
  }
  .stores-container > div:not(.store-grid) {
  padding: 30px 15px 0 15px;
  }
.stores-container{
  .title{
    span:nth-child(1) {
      font-size: 24px;
      color: #fe6252;
      display: block;
    }
    span:nth-child(2) {
      display: none;
    }
  }
}
  .stores-container {
    grid-template-columns: 1fr;
    & > div:nth-child(2) {
      padding:0px;
      margin-bottom: 0;
      align-items: center;
      text-align: center;
      .title{
        margin-bottom:25px;
      }
      .action {
        display: flex;
        flex-direction: column;
      }
    }
    & > div:nth-child(1) {
      display: none;
    }
    .action > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  .text-for-mobile {
    display: block;
    font-size: 16px;
    color: #fff;
    text-align: center;
    border-radius:0px;
  }
  .video-container {
    .action {
      width: 100%;
    }
  }

  .use-case-container {
    padding: 30px 10px;
    .actions,
    .about {
      padding: 0 10px;
      h1{
      padding-bottom: 0px !important;
      }
    }
  }

  .discover-container {
    .arrow-button {
      height: 40px;
      width: 40px;
    }
  }
  .faq-container,
  .use-case-container {
    .actions {
      flex-direction: column;
      margin-top: 30px;
      button {
        margin: 0;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
`;

export const GlobalStylePlans = createGlobalStyle`
   .faq-container {
  text-align: center;
}
.plans-intro-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .plans-card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    max-width: 1000px;
    margin-top: 40px;
  }
}
@media (max-width: ${breakPoints.mobileLarge}) {
  .pricing-image{
    width: 300px;
  }
}
@media ${responsiveWidth.mobile} {
  .plans-intro-container {
    text-align: center;
    .plans-card-grid {
      text-align: center;
      grid-template-columns: 1fr;
    }
  }
  .faq-container {
    text-align: left;
  }
  
}
`;

export const GlobalStyleFaq = createGlobalStyle`
  .title {
  margin-bottom: 30px;
  width: 100%;
}
.actions {
  display: flex;
  margin-top: 0;
  button {
    margin-top: 0;
  }
  button:not(:last-child) {
    margin-right: 30px;
  }
}
@media ${responsiveWidth.mobile} {
  .title {
    margin-bottom: 20px;
  }
  .actions {
    flex-direction: column;
    button:not(:last-child) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}
`;

export const GlobalStylePolicy = createGlobalStyle`
  .policy-section:not(:last-child) {
   margin-bottom: 100px;
  }
  h4 {
  margin-bottom: 10px;
  margin-top: 0;
  }
  p {
  margin-top: 0;
  }
  a {
  color: #0bae89;
  }
`;

export const GlobalStyleContact = createGlobalStyle`
  .container.contact-us {
  text-align: center;
  }
  @media ${responsiveWidth.mobile} {
  .container.contact-us  {
    .title  {
      text-align: left;
    }
    .description{
      text-align: left;
    }
  }
  button {
  display: inherit;
  margin: 0 auto;
  }
  }
`;

export const GlobalStylePreferences = createGlobalStyle`
  
`;

export const GlobalStyleWhySparkpico = createGlobalStyle`
  .hero-container-spark {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
   grid-gap: 10px;
    align-items: end;

  .actions {
    margin-top: 40px;
    button:not(:last-child) {
      margin-right: 20px;
    }
    button {
      margin-bottom: 15px;
    }
  }
}
  .text-for-mobile {
    display: none!important;
  }
 .info {
 width: auto;
 position: static;
 }
 .container.challenges-container-spark {
   max-width: 1200px;
 }
  .slider-container {
    max-width: 800px;
    margin: 0 auto;
    .slick-slide {
      & > div {
        margin: 0 20px;
      }
    }
  }
 ul.slick-dots {
 bottom: -45px;
 }

 .arrow-button {
    position: absolute;
    top: 450px;
    margin: 15px;
    &.left {
      left: 120px;
      transform: translateX(-20%);
    }
    &.right {
      right: 120px;
      transform: translateX(20%);
    }
  }
 
  @media ${responsiveWidth.heroLayout} {
    .hero-container-spark {
      grid-template-columns: 1fr;
      grid-gap: 20px;
      text-align: left;
      & > div:nth-child(1) {
        order: 0;
      }
      & > div:nth-child(2) {
        order: 1;
      }
      .hero-image-container {
        position: static;
        width: 100%;
      }
    }
  }

  @media ${responsiveWidth.mobile} {
  .hero-container-spark {
    grid-template-columns: 1fr;
    text-align: left;
    grid-gap: 20px;

    & > div:nth-child(1) {
      order: 0;
    }

    & > div:nth-child(2) {
      order: 1;
    }
    .actions button:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
    .text-for-mobile {
      display: block!important;
      font-size: 16px;
      color: #fff;
      text-align: center;
      border-radius:0px;
    }
  ul.slick-dots {
    bottom: -20px;
  }

  .slick-list {
    min-height: 400px;
  }
  .arrow-button {
    top:300px;
    &.left {
      left:0;
    }
    &.right {
      right:0;
    }
  }
  .slider-container {
    max-width: 800px;
    margin: 0 20px;
  }
}
`;

export const GloablStyleFeatures = createGlobalStyle`
  .info-section {
    .gatsby-image-wrapper {
      margin-bottom: 20px;
    }
  button {
    margin-top: 40px;
  }
 }
  .text-for-mobile {
    display: none!important;
  }
 @media ${responsiveWidth.mobile} {
  .info-section {
    button {
      margin-top: 20px;
    }
  }
   .text-for-mobile {
     display: block!important;
     font-size: 16px;
     color: #fff;
     text-align: center;
     border-radius:0px;
   }
 }
`;

export const GlobalStyleV2 = createGlobalStyle`
  body {
  padding: 0;
  }
  #gatsby-focus-wrapper {
  & > div {
  margin: 0;
  box-shadow: none;
  border-radius: 0;
    &.page-title{
      margin: 0;
    }
    h1 {
      padding-bottom: 30px;
      margin: 0;
      &.underLine{
        padding-bottom: 0px !important;
      }
      @media (max-width: 920px) {
        text-align: center;
      }
    }  
    & > h1 {
      margin-bottom: 0;
      margin-top: 36px;
    }
  }
  }
  .features-actions{
    padding-bottom: 90px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    background-color: #ffffff;
  }
  @media ${responsiveWidth.heroLayout} {
    #gatsby-focus-wrapper {
      & > div {
        &.page-title {
          margin:  0;
        }
      }
    }
  }
`;

export const GlobalStyleProductWeb = createGlobalStyle`
  .container.challenges-container-products {
    max-width: 1200px;
  }
 .video-container{
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
 }
  .slider-container {
    max-width: 800px;
    margin: 0 auto;
  }
  .text-for-mobile {
    display: none!important;
  }
  .hero-web-container {
    position: relative;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1.5fr;
    align-items: end;

    .actions {
      margin-top: 40px;
      display: inline-flex;

      button:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

    .arrow-button {
      position: absolute;
      top: 400px;
      margin: 15px;

      &.left {
        left: 120px;
        transform: translateX(-20%);
      }

      &.right {
        right: 120px;
        transform: translateX(20%);
      }
    }

    @media ${responsiveWidth.mobile} {
      .arrow-button {
        top: 250px;

        &.left {
          left: 0;
        }

        &.right {
          right: 0;
        }
      }
      .hero-web-container{
          text-align: left;
          grid-template-columns: 1fr;
        &>div:first-child{
          order:2;
        }
          .actions {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            button:first-child {
              order:2;
              padding: 10px 32px;
              margin-top: 20px;
            }
          }
          .actions button:not(:last-child) {
            margin-right: 15px;
          }
      }
      .text-for-mobile {
        display: block !important;
        font-size: 16px;
        color: #fff;
        text-align: center;
        border-radius: 0px;

      }
    }
`;

export const GlobalStyleProductWebClipper = createGlobalStyle`
  .hero-container-clipper{
   position: relative;
   display: grid;
   grid-template-columns: 1fr 1.25fr;
   align-items: start;
   grid-gap: 100px;
  .actions {
    margin-top: 40px;
    button:not(:last-child) {
      margin-right: 20px;
    }
    button {
      margin-bottom: 15px;
    }
  }
}
  .text-for-mobile {
    display: none!important;
  }
  @media ${responsiveWidth.heroLayout} {
    .text-for-mobile {
      display: block !important;
      font-size: 16px;
      color: #fff;
      text-align: center;
      border-radius: 0px;
    }
  }
  @media ${responsiveWidth.mobile}{
    .hero-container-clipper {
      grid-template-columns: 1fr;
      grid-gap: 20px;
      text-align: center;
      & > div:nth-child(1) {
        order: 1;
      }
      & > div:nth-child(2) {
        order: 0;
      }
      .hero-image-container {
        position: static;
        width: 100%;
      }
    }
  }
`;
export const GlobalStyleAboutUs = createGlobalStyle`
  .hero-container-about {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    align-items: end;

    .hero-image-container {
      position: absolute;
      right: 0;
      bottom: -100px;
      width: 600px;
    }
  }
    @media ${responsiveWidth.mobile} {
      .hero-container-about {
        grid-template-columns: 1fr;
        text-align: center;
        grid-gap: 20px;

        & > div:nth-child(1) {
          order: 1;
        }

        & > div:nth-child(2) {
          order: 0;
        }
        .hero-image-container {
          position: static;
          width: 100%;
        }
      }
    }
  }`;
