import React from 'react';
import styled from 'styled-components';

const ContentCard = styled.div`
  .box {
    position: relative;
  }
  .imageBox {
    width: 80%;
    height: 75%;
    background-color: ${(props) => props.color};
    position: absolute;
  }
  .gatsby-image-wrapper {
    z-index: 9;
    margin: 20px;
    width: 100%;
  }
`;
export default ContentCard;
