import React, { useEffect, useState } from 'react';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';
import { graphql, useStaticQuery } from 'gatsby';
import { TabPane } from 'rc-tabs';
import FlashCard from '../../styles/styledComponents/flashCard';
import VerticalTabs from '../../styles/styledComponents/verticalTabs';
import MobileTabs from '../../styles/styledComponents2/mobileTabs';

const TabHead = ({ head, image, description }) => (
  <div>
    <div className={'tab-head-image'}>
      <img
        alt={'use-case'}
        src={require(`../../../static/img/${image}`).default}
        width={'100%'}
      />
    </div>
    <div className={'title'} dangerouslySetInnerHTML={{ __html: head }} />
    {description.map((description, index) => (
      <div className={'description-card'} key={index}>
        <img
          src={require('../../images/spark-fill.svg').default}
          className={'spark-icon'}
          alt={'spark-fill'}
        />
        <span
          className={'description'}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    ))}
  </div>
);
const UseCases = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "use_cases" } } }
        ) {
          nodes {
            id
            frontmatter {
              navigation_buttons
              description
              title
              use_case_tabs {
                detailed_description
                description
                template
                title
                image {
                  base
                }
                detailed_description_in_points {
                  point_1
                  point_2
                  point_3
                  point_4
                  point_5
                }
              }
            }
          }
        }
      }
    `),
    { title, use_case_tabs } = data.allMarkdownRemark.nodes[0].frontmatter;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth),
    [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    function reportWindowSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, []);

  return (
    <>
      <Card>
        <div className={'about'}>
          <Title
            padding={'0 25px'}
            align={'center'}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
        {windowWidth > 768 ? (
          <VerticalTabs
            defaultActiveKey="0"
            tabbarposition={'left'}
            top={'0px'}
            color={'#ffff'}
            className={'container'}
          >
            {use_case_tabs.map((content, index) => (
              <TabPane
                tab={
                  <TabHead
                    head={content.title}
                    description={Object.values(
                      content?.detailed_description_in_points
                    ).filter((value) => value !== '')}
                    image={content.image?.base}
                  />
                }
                key={index}
              >
                <FlashCard
                  className={'tab'}
                  shadow={'none'}
                  color={'transparent'}
                >
                  <img
                    alt={'use-case'}
                    src={
                      require(`../../../static/img/${content.image?.base}`)
                        .default
                    }
                    width={'100%'}
                  />
                </FlashCard>
              </TabPane>
            ))}
          </VerticalTabs>
        ) : (
          <div className={'use-case-mobile'}>
            {use_case_tabs.map((tab, index) => (
              <MobileTabs
                key={index}
                className={tabIndex === index && 'active-tab'}
                onClick={() => setTabIndex(index)}
              >
                {tabIndex === index ? (
                  <>
                    <div className={'tab-head-image'}>
                      <img
                        alt={'use-case'}
                        src={
                          require(`../../../static/img/${tab.image?.base}`)
                            .default
                        }
                        width={'100%'}
                        height={'auto'}
                      />
                    </div>
                    <div
                      className={'title'}
                      dangerouslySetInnerHTML={{ __html: tab.title }}
                    />
                    {Object.values(tab?.detailed_description_in_points)
                      .filter((value) => value !== '')
                      .map((descriptions, index) => (
                        <div className={'description-card'} key={index}>
                          <img
                            src={require('../../images/spark-fill.svg').default}
                            className={'spark-icon'}
                            alt={'spark-fill'}
                          />
                          <span
                            className={'description'}
                            dangerouslySetInnerHTML={{ __html: descriptions }}
                          />
                        </div>
                      ))}
                  </>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tab.title,
                    }}
                  />
                )}
              </MobileTabs>
            ))}
          </div>
        )}
      </Card>
    </>
  );
};

export default UseCases;
