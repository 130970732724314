import styled from 'styled-components';
import img from '../../images/video-background.png';

const ButtonWithIcon = styled.div`
  height: 60px;
  width: 120px;
  cursor: pointer;
  border: 2px solid #fe6252;
  border-radius: 10px;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;

  .round-button {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: relative;
    transition: all 0.4s ease 0s;
    border-radius: 50%;
    border: 1px solid #fe6152;
  }

  .round-button:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    display: block;
    width: 0px;
    height: 0px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 8px solid #fe6152;
  }
  &:hover {
    .round-button {
      background-color: #fe6152;
    }
    .round-button:before {
      border-left: 8px solid white;
    }
  }
`;
export default ButtonWithIcon;
