import React from 'react';
import styled from 'styled-components';
import { breakPoints } from '../../constants';

export const Container = styled.div`
  font-family: 'AvertaStd';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  .container__roadmap--wrapper {
    background: #fbf8f5;
  }
  .container__roadmap {
    margin-top: -95px;
    background: #fbf8f5;
    display: flex;
    flex-direction: inherit;
    width: 100%;
    @media (max-width: ${breakPoints.tablet}) {
      padding-left: 0;
      flex-direction: column-reverse;
      padding-top: 310px;
      display: flex;
    }
    @media (max-width: ${breakPoints.mobileLarge}) {
      padding-top: 210px;
      max-height: 470px;
    }
    @media (max-width: ${breakPoints.mobileSmall}) {
      padding-top: 28px;
      max-height: 520px;
    }

    .heading {
      margin: 50px 0 76px 290px;
      max-width: 685px;

      @media (min-width: ${breakPoints.pcLarge}) {
        margin: 50px 0 76px 150px;
        margin-top: 0;
      }
      @media (max-width: ${breakPoints.pcLarge}) {
        max-width: 554px;
        margin: 53px 10px 0 100px;
      }
      @media (max-width: ${breakPoints.pc}) {
        margin: 45px 0 0 70px;
        max-width: 422px;
        line-height: 40px;
      }
      @media (max-width: ${breakPoints.pcSmall}) {
        margin: 35px 0 0 15px;
        max-width: 370px;
      }
      @media (max-width: ${breakPoints.tablet}) {
        margin: auto;
        max-width: 660px;
      }
      @media (max-width: ${breakPoints.mobileLarge}) {
        padding-top: 0;
      }

      .heading_title {
        color: black;
        font-size: 48px;
        font-weight: bold;
        line-height: 56px;
        outline: unset !important;

        @media (max-width: ${breakPoints.pcLarge}) {
          font-size: 42px;
          line-height: 49px;
          margin-bottom: 25px;
        }
        @media (max-width: ${breakPoints.pc}) {
          font-size: 32px;
          max-width: 500px;
          line-height: 38px;
        }
        @media (max-width: ${breakPoints.pcSmall}) {
          font-size: 28px;
          line-height: 32px;
        }
        @media (max-width: ${breakPoints.tablet}) {
          padding: 20px 0;
          max-width: 536px;
          margin: auto;
          text-align: center;
        }
        @media (max-width: ${breakPoints.mobileLarge}) {
          padding-top: 0px;
          max-width: 310px;
          font-size: 28px;
          line-height: 33px;
          text-align: center;
        }
        @media (max-width: ${breakPoints.mobileSmall}) {
          font-size: 20px;
          line-height: 23px;
          text-align: center;
          padding-bottom: 20px;
          max-width: 253px;
        }

        > span {
          color: rgb(0, 173, 136);
          font-family: 'AvertaStd-BoldItalic';
        }
        span:nth-child(2) {
          color: rgb(249, 64, 56);
        }
      }

      .heading_subtitle {
        font-size: 20px;
        line-height: 23px;
        margin-top: 25px;
        padding-bottom: 48px;
        color: #70757f;
        font-family: 'AvertaStd-Semibold';

        @media (max-width: ${breakPoints.pcLarge}) {
          font-size: 18px;
          line-height: 21px;
          padding-bottom: 0;
          margin-bottom: 33px;
          margin-top: 0;
        }
        @media (max-width: ${breakPoints.pc}) {
          padding-bottom: 15px;
          font-size: 16px;
          line-height: 19px;
          margin-top: 0;
        }
        @media (max-width: ${breakPoints.pcSmall}) {
          padding-bottom: 5px;
          font-size: 16px;
          line-height: 18px;
          margin-top: 0px;
        }
        @media (max-width: ${breakPoints.tablet}) {
          padding-bottom: 77px;
          margin: auto;
          text-align: center;
          max-width: 527px;
        }
        @media (max-width: ${breakPoints.mobileLarge}) {
          text-align: center;
          font-size: 15px;
          line-height: 17px;
          max-width: 330px;
        }
        @media (max-width: ${breakPoints.mobileSmall}) {
          text-align: center;
          font-size: 10px;
          line-height: 12px;
          max-width: 248px;
          padding-bottom: 50px;
        }
      }

      .heading_button {
        font-family: 'AvertaStd-Semibold';
        list-style: none;
        background: #fe6152;
        box-shadow: 1px 1px 10px rgba(107, 107, 107, 0.41);
        border-radius: 10px;
        color: #ffffff;
        text-decoration: none;
        font-size: 24px;
        line-height: 28px;
        padding: 8px 20px;
        margin: auto;

        @media (max-width: ${breakPoints.pcSmall}) {
          font-size: 18px;
          line-height: 21px;
        }
        @media (max-width: ${breakPoints.pc}) {
          font-size: 22px;
          position: relative;
          bottom: 21px;
        }
        @media (max-width: ${breakPoints.pcSmall}) {
          font-size: 18px;
          line-height: 21px;
        }
        @media (max-width: ${breakPoints.tablet}) {
          left: 34%;
          bottom: 35px;
        }
        @media (max-width: ${breakPoints.mobileLarge}) {
          max-width: 100px;
          font-size: 14px;
          left: 28%;
          bottom: 50px;
        }
        @media (max-width: ${breakPoints.mobileSmall}) {
          max-width: 160px;
          font-size: 10px;
          line-height: 12px;
          padding: 6px 15px;
          bottom: 30px;
          margin-left: -4%;
        }

        &:hover {
          background: #f73d2c;
          transition: all 0.3s ease 0s;
        }
      }
    }

    .heading_image {
      max-width: 1140px;
      max-height: 782px;
      width: 100%;
      height: auto;

      @media (max-width: ${breakPoints.pcLarge}) {
        max-width: 764px;
        max-height: 553px;
        padding-top: 52px;
      }
      @media (max-width: ${breakPoints.pc}) {
        margin-right: 0;
        padding-top: 86px;
        display: block;
      }
      @media (max-width: ${breakPoints.pcSmall}) {
        padding-top: 48px;
        margin: auto;
        max-height: 500px;
        max-width: 560px;
        margin-bottom: 0;
      }
      @media (max-width: ${breakPoints.tablet}) {
        margin: auto;
      }
      @media (max-width: ${breakPoints.mobileLarge}) {
        max-width: 380px;
        margin: auto;
      }
      @media (max-width: ${breakPoints.mobileSmall}) {
        max-width: 273px;
        position: relative;
        top: 90px;
        padding-top: 0;
        margin: auto;
      }

      img {
        @media (min-width: ${breakPoints.pcLarge}) {
          max-width: 1140px;
          max-height: 782px;
          width: 100%;
          height: auto;
        }
        @media (max-width: ${breakPoints.pcLarge}) {
          width: 100%;
          height: auto;
        }
        @media (max-width: ${breakPoints.pc}) {
          max-width: 560px;
          max-height: 430px;
          position: relative;
          bottom: 45px;
          width: auto;
        }
        @media (max-width: ${breakPoints.pcSmall}) {
          width: 100%;
          bottom: 0;
        }
        @media (max-width: ${breakPoints.tablet}) {
          bottom: 45px;
          position: relative;
          padding-top: 125px;
        }
        @media (max-width: ${breakPoints.mobileLarge}) {
          bottom: 10px;
        }
        @media (max-width: ${breakPoints.mobileSmall}) {
          bottom: 100px;
          padding-top: 20px;
        }
      }
    }
  }

  .roadmap__section {
    padding: 25px 0px 50px;
    max-width: 1035px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 1024px) {
      .section-content {
        padding: 0 40px;
      }
    }
    @media (max-width: ${breakPoints.tablet}) {
      .section-content {
        padding: 0 20px;
      }
    }
    .features-image {
      position: relative;
      z-index: 0;
      .road {
        position: absolute;
        margin-top: 232px;
        margin-left: 88px;
        z-index: -2;
        @media (max-width: ${breakPoints.tablet}) {
          display: none;
        }
      }
      .road-mobile {
        display: none;
        @media (max-width: ${breakPoints.tablet}) {
          position: absolute;
          display: block;
          margin-top: 221px;
          margin-left: 181px;
          z-index: -1;
        }
        @media (max-width: ${breakPoints.mobileLarge}) {
          margin-left: 0px;
        }
        @media (max-width: ${breakPoints.mobileSmall}) {
          display: none;
        }
      }
      .previous-quarter {
        position: absolute;
        margin-left: 133px;
        margin-top: 142px;
        @media (max-width: ${breakPoints.tablet}) {
          display: none;
        }
      }
      .previous-quarter-mobile {
        display: none;
        @media (max-width: ${breakPoints.tablet}) {
          position: absolute;
          display: block;
          margin-top: 177px;
          margin-left: 244px;
        }
        @media (max-width: ${breakPoints.mobileLarge}) {
          margin-left: 16px;
        }
        @media (max-width: ${breakPoints.mobileSmall}) {
          display: none;
        }
      }
      .icon-released {
        margin-top: 205px;
        position: absolute;
        margin-left: 470px;
        @media (max-width: ${breakPoints.tablet}) {
          margin-top: 207px;
          position: absolute;
          margin-left: 378px;
        }
        @media (max-width: ${breakPoints.mobileLarge}) {
          margin-left: 183px;
        }
        @media (max-width: ${breakPoints.mobileSmall}) {
          display: none;
        }
      }
      .current-quarter {
        position: absolute;
        margin-top: 330px;
        margin-left: 510px;
        @media (max-width: ${breakPoints.tablet}) {
          display: none;
        }
      }
      .current-quarter-mobile {
        display: none;
        @media (max-width: ${breakPoints.tablet}) {
          position: absolute;
          display: block;
          margin-top: 352px;
          margin-left: 469px;
        }
        @media (max-width: ${breakPoints.mobileLarge}) {
          margin-left: 283px;
        }
        @media (max-width: ${breakPoints.mobileSmall}) {
          display: none;
        }
      }
      .in-progress {
        position: absolute;
        margin-top: 500px;
        margin-left: 230px;
        @media (max-width: ${breakPoints.mobileLarge}) {
          margin-left: 35px;
        }
        @media (max-width: ${breakPoints.mobileSmall}) {
          display: none;
        }
      }
      .next-quarter {
        position: absolute;
        margin-top: 540px;
        margin-left: 451px;
        @media (max-width: ${breakPoints.tablet}) {
          display: none;
        }
      }
      .next-quarter-mobile {
        display: none;
        @media (max-width: ${breakPoints.tablet}) {
          position: absolute;
          display: block;
          margin-top: 684px;
          margin-left: 216px;
        }
        @media (max-width: ${breakPoints.mobileLarge}) {
          margin-left: 6px;
          margin-top: 633px;
        }
        @media (max-width: ${breakPoints.mobileSmall}) {
          display: none;
        }
      }
      .planned {
        position: absolute;
        margin-top: 590px;
        margin-left: 790px;
        @media (max-width: ${breakPoints.tablet}) {
          margin-top: 766px;
          margin-left: 375px;
        }
        @media (max-width: ${breakPoints.mobileLarge}) {
          margin-left: 207px;
          margin-top: 779px;
        }
        @media (max-width: ${breakPoints.mobileSmall}) {
          display: none;
        }
      }
    }
    .features-list-1 {
      display: grid;
      justify-content: center;
      align-items: center;
      margin-bottom: 75px;
      @media (max-width: ${breakPoints.tablet}) {
        justify-content: end;
        margin-bottom: 68px;
      }
    }

    .features-list-2 {
      display: grid;
      justify-content: start;
      align-items: center;
      margin-left: 150px;
      @media (max-width: ${breakPoints.tablet}) {
        justify-content: start;
        margin-left: 0px;
        margin-bottom: 90px;
      }
    }

    .features-list-3 {
      display: grid;
      justify-content: end;
      align-items: center;
      margin-top: -115px;
      margin-right: 25px;
      @media (max-width: ${breakPoints.tablet}) {
        justify-content: end;
        margin-top: 0px;
        margin-right: 0px;
      }
    }

    .section_title {
      font-family: 'Roboto';
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
      color: #000000;
      display: flex;
      justify-content: space-between;
      &.launched {
        margin-top: 53px;
      }
      .quarter-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        padding: 14px 42px;
        display: flex;
        background: #ffd584;
        align-items: center;
        justify-content: center;
      }

      @media (max-width: ${breakPoints.tablet}) {
        font-size: 18px;
        line-height: 21px;
        &.features {
          display: none;
        }
      }
    }

    .section_subtitle {
      font-family: 'AvertaStd-Regular';
      text-align: center;
      line-height: 26px;
      font-size: 20px;

      @media (min-width: ${breakPoints.pcLarge}) {
        max-width: 750px;
        margin: auto;
      }
      @media (max-width: ${breakPoints.pcLarge}) {
        max-width: 750px;
        margin: auto;
        padding-bottom: 35px;
      }
      @media (max-width: ${breakPoints.mobileLarge}) {
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        max-width: 250px;
        margin: auto;
        padding-bottom: 20px;
      }
      @media (max-width: ${breakPoints.mobileSmall}) {
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        padding-bottom: 30px;
      }
    }
  }

  .roadmap__postCard-container {
    padding-top: 25px;
    padding-bottom: 68px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin: auto;

    @media (max-width: ${breakPoints.tablet}) {
      grid-template-columns: 1fr;
      justify-items: center;
      grid-gap: 16px;
    }

    .postCard {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 0px;
      max-height: 320px;
      max-width: 305px;

      .postCard-cardImage {
        position: relative;
        top: 10px;

        .postCard-image-container {
          border-radius: 10px;
          .postCard-image {
            max-width: 305px;
            max-height: 195px;
          }
        }
      }

      .postCard-cardWrapper {
        background: #fbfbfb;
        box-shadow: 1px 1px 30px rgba(192, 192, 192, 0.25);
        border-radius: 10px;

        .content {
          padding: 16px 24px 24px;
          color: #000000;
          .postCard-release_at {
            font-family: 'AvertaStd-Regular';
            font-size: 23px;
            line-height: 19px;
            text-align: left;

            @media (max-width: ${breakPoints.tablet}) {
              font-size: 16px;
            }
          }

          .postCard-title {
            font-family: 'Roboto';
            font-style: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: left;
            font-weight: 400;
          }

          .postCard-link {
            font-family: 'AvertaStd-Bold';
            color: #ffa303;
            font-size: 20px;
            line-height: 23px;
            cursor: pointer;
            text-decoration: underline;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  .roadmap-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
`;
