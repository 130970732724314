import React, { useEffect, useState } from 'react';
import {
  GlobalStyle,
  GlobalStyleFaq,
  GlobalStyleV2,
} from 'src/styles/globalStyles';
import PageTitle from 'src/components/pageTitle';
import Header from 'src/components/header';
import Footer from 'src/components/footer';
import FaqContent from 'src/components/faq/faqContent';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import PageHelmet from 'src/components/pageHelmet';

import { InitialLoader } from '../components/preloader/initialLoader';

const FaqPage = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "faq_page_data" } } }
        ) {
          nodes {
            frontmatter {
              title
              description
              page_title
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `),
    { title, page_title, background, description } = getFrontmatter(data),
    [loader, setLoader] = useState(true);

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);
  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description} />
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStyleFaq />
      <Header color={'#fbf8f5'} />
      <PageTitle title={page_title} titles />
      <FaqContent />
      <Footer />
    </>
  );
};

export default FaqPage;
