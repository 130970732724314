import styled from 'styled-components';
import Card from 'src/styles/styledComponents/card';
import { responsiveWidth } from 'src/styles/constants';

const CombinedHeroWebWrapper = styled(Card)`
  padding: 0 70px 100px;
  background-color: #fbf8f5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  .description-points {
    font-size: 19px;
    line-height: 28px;
    display: grid;
    margin-top: 10px;
    grid-gap: 10px;
    .description {
      margin-left: 10px;
    }
  }

  @media ${responsiveWidth.mobile} {
    padding: 30px 15px;
    .description-points {
      font-size: 14px;
    }
    & > div {
      &:first-child {
        padding-bottom: 50px;
        button {
          margin-top: 30px;
        }
      }
    }
  }
`;
export default CombinedHeroWebWrapper;
