import React from 'react';
import FeaturesTab from '../../../styles/components/roadmap/featuresTab';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from '../../../utility/helpers';
import { roadmapColors } from '../../../styles/constants';

const Features = ({ title }) => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "Roadmap features list" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              roadmap_features_list {
                feature_title
                feature_1
                feature_2
                feature_3
                feature_4
                template
              }
            }
          }
        }
      }
    `),
    { roadmap_features_list } = getFrontmatter(data);
  return (
    <>
      <div className={'section-content'}>
        <div className="section_title features">
          <div>{title}</div>
          <div className={'quarter-title'}>III quarter 2022</div>
        </div>
        <div className={'features-image'}>
          <img
            className={'road'}
            src={require('../../../images/graphics_road.png').default}
            alt={'road'}
          />
          <img
            className={'road-mobile'}
            src={require('../../../images/graphics-road-mobile.png').default}
            alt={'road'}
          />
          <img
            className={'previous-quarter'}
            src={require('../../../images/previous-quarter.png').default}
            alt={'previous-quarter'}
          />
          <img
            className={'previous-quarter-mobile'}
            src={require('../../../images/previous-quarter-mobile.png').default}
            alt={'previous-quarter'}
          />

          <img
            className={'icon-released'}
            src={require('../../../images/icon-released.png').default}
            alt={'icon-released'}
          />
          <img
            className={'current-quarter'}
            src={require('../../../images/current-quarter.png').default}
            alt={'current-quarter'}
          />
          <img
            className={'current-quarter-mobile'}
            src={
              require('../../../images/Icon-current-quarter-mobile.png').default
            }
            alt={'current-quarter'}
          />
          <img
            className={'in-progress'}
            src={require('../../../images/in-progress.png').default}
            alt={'in-progress'}
          />
          <img
            className={'next-quarter'}
            src={require('../../../images/next-quarter.png').default}
            alt={'next-quarter'}
          />
          <img
            className={'next-quarter-mobile'}
            src={require('../../../images/next-quarter-mobile.png').default}
            alt={'next-quarter'}
          />
          <img
            className={'planned'}
            src={require('../../../images/planned.png').default}
            alt={'planned'}
          />
          {roadmap_features_list.map((features, index) => (
            <div className={`features-list-${index + 1}`} key={index}>
              <FeaturesTab className={'active'} color={roadmapColors[index]}>
                {features.feature_title}
              </FeaturesTab>
              <FeaturesTab color={roadmapColors[index]}>
                {features.feature_1}
              </FeaturesTab>
              <FeaturesTab color={roadmapColors[index]}>
                {features.feature_2}
              </FeaturesTab>
              <FeaturesTab color={roadmapColors[index]}>
                {features.feature_3}
              </FeaturesTab>
              <FeaturesTab color={roadmapColors[index]}>
                {features.feature_4}
              </FeaturesTab>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Features;
