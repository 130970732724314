import React, { useEffect, useState } from 'react';
import Card from 'src/styles/styledComponents/card';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import CollectWrapper from 'src/styles/styledComponents2/collectWrapper';
import ImageWithTitleDescription from 'src/styles/styledComponents2/imageWithTitleDescription';
import ButtonBlack from '../../styles/styledComponents2/buttonBlack';

const Collect = ({ data, color, id, titles, bottom }) => {
  const { title, description, content_list, button, link } = data;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function reportWindowSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, []);
  return (
    <div id={id}>
      <Card
        align={'center'}
        color={color}
        padding={id === 'organize' ? '45px' : '35px 30px'}
        className={'features'}
      >
        <Title
          size={'50px'}
          className={id}
          marginBottom={'5px'}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Description
          size={'19px'}
          lineHeight={'28px'}
          className={'features-description'}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Card>
      <div className={'arrow'} />
      <Card padding={'40px 70px'} className={'features'}>
        <div className={'container'}>
          <CollectWrapper>
            {content_list.map((content, index) => (
              <ImageWithTitleDescription
                bottom={bottom}
                column={'1fr 1fr'}
                gap={'10%'}
                key={index}
                verticalAlign={'center'}
              >
                {windowWidth > 768 ? (
                  <>
                    <div>
                      {content.image?.base && (
                        <img
                          alt={'collect'}
                          src={
                            require(`../../../static/img/${content.image?.base}`)
                              .default
                          }
                          width={'100%'}
                        />
                      )}
                    </div>
                    <div className={'content'}>
                      <div className={'content-title'}>{titles}</div>
                      <Title
                        className={'collect-title'}
                        size={'34px'}
                        marginBottom={'20px'}
                        letterSpace={'-1px'}
                        dangerouslySetInnerHTML={{ __html: content.title }}
                      />
                      <div className={'description-points'}>
                        {Object.values(content.detailed_description_in_points)
                          .filter((value) => value !== '')
                          .map((description, index) => (
                            <div className={'description-card'} key={index}>
                              <img
                                src={
                                  require('../../images/spark-fill-yellow.svg')
                                    .default
                                }
                                className={'spark-icon'}
                                alt={'spark-fill'}
                              />
                              <Description
                                className={'description'}
                                size={'19px'}
                                lineHeight={'27px'}
                                dangerouslySetInnerHTML={{
                                  __html: description,
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={'content'}>
                      <div className={'content-title'}>{titles}</div>
                      <Title
                        className={'collect-title'}
                        size={'34px'}
                        marginBottom={'20px'}
                        letterSpace={'-1px'}
                        dangerouslySetInnerHTML={{ __html: content.title }}
                      />
                      <div>
                        {content.image?.base && (
                          <img
                            alt={'collect'}
                            src={
                              require(`../../../static/img/${content.image?.base}`)
                                .default
                            }
                            width={'100%'}
                          />
                        )}
                      </div>
                      {Object.values(content.detailed_description_in_points)
                        .filter((value) => value !== '')
                        .map((description, index) => (
                          <div key={index} className={'description-points'}>
                            <div className={'description-card'}>
                              <img
                                src={
                                  require('../../images/spark-fill-yellow.svg')
                                    .default
                                }
                                className={'spark-icon'}
                                alt={'spark-fill'}
                              />
                              <Description
                                className={'description'}
                                mobileLine={'21px'}
                                dangerouslySetInnerHTML={{
                                  __html: description,
                                }}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </ImageWithTitleDescription>
            ))}
          </CollectWrapper>
        </div>
      </Card>
      <div className={'features-actions'}>
        <ButtonBlack>
          <a href={link} className="hero_sign_up_link">
            {button}
          </a>
        </ButtonBlack>
      </div>
    </div>
  );
};

export default Collect;
