/* eslint-disable prettier/prettier */
import styled from 'styled-components';

const NavLink = styled.div`
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  padding-bottom: 5px;
  text-overflow: ${(props) => props.overFlow || 'none'};
  max-width: ${(props) => props.width || 'none'};
  white-space: ${(props) => props.space || 'none'};
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fe6252;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  &::after {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }

  &:hover::after,
  &:focus::after {
    transform: ${(props) =>
    `translate3d(${!props.disableHover ? '0' : '-100%'}, 0, 0)`};
  }
  .gatsby-image-wrapper {
    transition: all 0.5s;
    &.active {
      transform: rotate(180deg);
    }
  }
`;
export default NavLink;
