import styled from 'styled-components';
import { breakPoints, responsiveWidth } from '../constants';

const NotificationCard = styled.div`
  background-color: ${(props) =>
    props.type === 'Read' ? '#fffff' : 'rgba(250, 247, 240, 0.61)'};
  border-radius: 16px;
  padding: 18px 38px;
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 10% 90%;
  &.results{
    grid-template-columns: 100%;
  }
  &.hover{
    background-color: rgba(250, 247, 240, 0.61);
  }
}
  .time {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${(props) => (props.type === 'read' ? '#6F7376' : '#F39E2B')};
    font-size: 16px;
    border-right: 1px solid #DADADA;
    font-weight: 500;
  }
  .content {
    color: ${(props) => (props.type === 'Read' ? '#6F7376' : '#2c3038')};
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
      .title-announcement {
      color: ${(props) => (props.type === 'Read' ? '#353535' : '#F39E2B')} ;
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 8px;
    }
  }
   @media ${responsiveWidth.mobile}{
   padding: 5px;
   margin-bottom: 10px;
     &.results{
       font-size: 14px;
       padding: 10px;
     }
   .time{
     font-size: 12px;
     border-right: 1px solid #c4c4c4;
    } 
    .content {
     font-size: 10px;
      .title-announcement {
      font-size: 12px;
      font-weight: 600;
    }
  }
  @media (max-width:${breakPoints.mobileLarge} ){
    grid-template-columns: 20% 80%;
  }
`;
export default NotificationCard;
