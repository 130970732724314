import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const Dot = styled.div`
  height: 8px;
  width: 8px;
  margin: 0 15px;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid black;

  &.active {
    background-color: ${(props) => props.activeColor || '#000'};
    border-color: ${(props) => props.activeColor || '#000'};
  }
  @media ${responsiveWidth.mobile} {
    margin: 0 10px;
  }
`;
export default Dot;
