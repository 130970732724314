import axios from 'axios';

export const axiosClient = axios.create({
  validateStatus: (status) => status >= 200 && status < 300 && status !== 205,
});

axiosClient.defaults.baseURL = process.env.GATSBY_API_URL;

axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'content-language': 'en',
};

export function getRequest(URL, params, cancelToken) {
  return axiosClient
    .get(`/${URL}`, {
      withCredentials: true,
      params,
      cancelToken: cancelToken ? cancelToken : '',
    })
    .then((response) => response);
}

export function postRequest(URL, payload) {
  return axiosClient
    .post(`/${URL}`, payload, { withCredentials: true })
    .then((response) => response);
}
