import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const FeatureScrollspy = styled.div`
  position: sticky;
  top: 60px;
  z-index: 99;
  border-radius: 15px;
  overflow: hidden;
  display: grid;
  font-weight: bold;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  font-size: 28px;
  background-color: #ffffffb3;
  white-space: nowrap;
  a {
    color: #c4c4c4;
    text-decoration: none;
    div {
      padding: 15px;
      text-align: center;
      height: 100%;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.active {
      color: #ffa303;
      font-weight: 600;
      div {
        background-color: #f9f1e6;
      }
    }
  }
  & + div {
    border-radius: 0 0 8px 8px;
  }
  @media ${responsiveWidth.mobile} {
    overflow-x: auto;
    padding: 0 0px 0px;
    font-size: 20px;
    a {
      div {
        border-radius: 8px;
      }
    }
  }
`;
export default FeatureScrollspy;
