/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import React, { useState } from 'react';
import PlanCard from 'src/styles/styledComponents/planCard';
import Button from 'src/styles/styledComponents/button';
import { graphql, useStaticQuery, Link, navigate } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import CustomModal from './customModal';
import ReactTooltip from 'react-tooltip';
import ButtonOutlined from 'src/styles/styledComponents2/buttonOutlined';
import PlansCardHolder from 'src/styles/styledComponents2/plansCardHolder';

import {
  SloganContent,
  Container,
  MainContent,
  CardContainer,
} from '../../styles/components/plan/plansIntro';

const PlansIntro = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "plans_intro" } } }
        ) {
          nodes {
            frontmatter {
              title
              topic
              description
              navigation_button
              additional_description
              plans {
                actual_price
                button
                link
                description
                discount_duration
                discount_text
                label
                name
                features_list {
                  tooltip_text
                  feature_texts
                }
                navigation_button
                modal_data {
                  cancel_button
                  content
                  ok_button
                  title
                }
              }
            }
          }
        }
      }
    `),
    {
      title,
      description,
      plans,
      navigation_button,
      additional_description,
      topic,
      link,
    } = getFrontmatter(data),
    basicPlan = plans[0],
    premiumPlan = plans[1],
    [showBasic, setShowBasic] = useState(false),
    [showPremium, setShowPremium] = useState(false);

  let iOS =
    typeof window !== 'undefined' &&
    window.location.search.includes('?platform=ios'),
    Android =
      typeof window !== 'undefined' &&
      window.location.search.includes('?platform=android');

  return (
    <Container>
      <div className={'container plans-intro-container'}>
        <MainContent>
          <h1>{title}</h1>
          <p>{description}</p>
        </MainContent>

        <CardContainer>
          <PlansCardHolder>
            <PlanCard>
              <div className={'price-container'}>
                <div>
                  <div
                    className={'name'}
                    dangerouslySetInnerHTML={{ __html: basicPlan.name }}
                  />
                  <div className={'price-web'}>
                    <div>
                      <span className={'price'}>
                        <span className={'dollar'}>
                          {basicPlan.actual_price[0]}
                        </span>
                        {basicPlan.actual_price[1]}
                      </span>
                    </div>
                    <div
                      className={'description'}
                      dangerouslySetInnerHTML={{
                        __html: basicPlan.description,
                      }}
                    />
                  </div>
                  <div className={'features'}>
                    {basicPlan.features_list.map((feature, index) => (
                      <div key={index}>
                        <span
                          data-tip={feature.tooltip_text}
                          data-for="plans-free"
                        >
                          <b>{feature.feature_texts[0]}</b>{' '}
                          {feature.feature_texts[1]}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={'price-mobile'}>
                  <div>
                    <span className={'price'}>
                      <span className={'dollar'}>
                        {basicPlan.actual_price[0]}
                      </span>
                      {basicPlan.actual_price[1]}
                    </span>
                  </div>
                  <div
                    className={'description'}
                    dangerouslySetInnerHTML={{ __html: basicPlan.description }}
                  />
                </div>
              </div>
              <ButtonOutlined
                className={'plans_intro_button'}
                onClick={() => navigate(link)}
              >
                Sign Up
              </ButtonOutlined>
            </PlanCard>
            <PlanCard type={'zoom'}>
              <div className={'price-container'}>
                <div>
                  <div
                    className={'label'}
                    dangerouslySetInnerHTML={{ __html: premiumPlan.label }}
                  />
                  <div
                    className={'name'}
                    dangerouslySetInnerHTML={{ __html: premiumPlan.name }}
                  />
                  <div className={'price-web'}>
                    <div>
                      <span className={'price'}>
                        <span className={'dollar'}>
                          {premiumPlan.actual_price[0]}
                        </span>
                        {premiumPlan.actual_price[1]}
                        <span className={'duration'}>
                          {premiumPlan.actual_price[2]}
                        </span>
                      </span>
                    </div>
                    <div
                      className={'discount'}
                      dangerouslySetInnerHTML={{
                        __html: premiumPlan.discount_text,
                      }}
                    />
                    <div
                      className={'discount'}
                      dangerouslySetInnerHTML={{
                        __html: premiumPlan.discount_duration,
                      }}
                    />
                  </div>
                </div>
                <div className={'features'}>
                  {premiumPlan.features_list.map((feature, index) => (
                    <div key={index}>
                      <span data-tip={feature.tooltip_text} data-for="plans">
                        <b>{feature.feature_texts[0]}</b>{' '}
                        {feature.feature_texts[1]}
                      </span>
                    </div>
                  ))}
                </div>
                <div className={'price-mobile'}>
                  <div>
                    <span className={'price'}>
                      <span className={'dollar'}>
                        {premiumPlan.actual_price[0]}
                      </span>
                      {premiumPlan.actual_price[1]}
                    </span>
                    <span className={'duration'}>
                      {premiumPlan.actual_price[2]}
                    </span>
                  </div>
                  <div
                    className={'discount'}
                    dangerouslySetInnerHTML={{
                      __html: premiumPlan.discount_text,
                    }}
                  />
                  <div
                    className={'discount'}
                    dangerouslySetInnerHTML={{
                      __html: premiumPlan.discount_duration,
                    }}
                  />
                </div>
              </div>
              {iOS && (
                <Button
                  onClick={() =>
                    navigate(
                      'https://buy.sparkpicoproduct.com/planid=sp_premium'
                    )
                  }
                >
                  {premiumPlan.button}
                </Button>
              )}
              {Android &&
                window.in_app_purchase_subscription !== undefined &&
                window.in_app_purchase_subscription.purchasePlan(
                  'sp_premium'
                ) && (
                  <Button onClick={() => setShowPremium(true)}>
                    {premiumPlan.button}
                  </Button>
                )}
            </PlanCard>
          </PlansCardHolder>
        </CardContainer>
        <SloganContent>
          <Link to={'#plans-detailed'}>{navigation_button}</Link>
          <h3>{topic}</h3>
          <p>{additional_description}</p>
        </SloganContent>
      </div>
      <CustomModal
        visible={showBasic}
        setVisible={setShowBasic}
        data={basicPlan.modal_data[0]}
      />
      <CustomModal
        visible={showPremium}
        setVisible={setShowPremium}
        data={premiumPlan.modal_data[0]}
        url={`${process.env.GATSBY_APP_URL}/app?plan_id=sp_premium`}
      />
      <ReactTooltip place={'bottom'} id={'plans'} effect={'solid'} />
      <ReactTooltip place={'bottom'} id={'plans-free'} effect={'solid'} />
    </Container>
  );
};

export default PlansIntro;
