import styled from 'styled-components';

export const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 0px 5px;
  width: 24px;
  height: 15px;
  border-radius: 100px;
  border: 2px solid #ffa303;
  position: relative;
  background-color: #ffffff;
  transition: background-color 0.2s;
`;

export const SwitchButton = styled.span`
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 1px;
  border-radius: 45px;
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1) 0.15s;
  background: #ffa303;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  ${SwitchInput}:checked + ${SwitchLabel} & {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  ${SwitchLabel}:active & {
    width: 45px;
  }
`;
