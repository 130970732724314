/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { Statuses } from '../../utility/constants';
import PostCard from './postCard';
import { kebabCase } from '../../utility/helpers';
import moment from 'moment';

import {
  Container,
  SearchContainer,
  SearchInput,
  SearchWrapper,
  InputWrapper,
  Content,
  Posts,
  FilterSideBar,
  FilterItems,
} from '../../styles/components/releaseNotes/content';

const Types = Object.freeze({
  ALL: null,
  BUG_FIX: 'BUG FIX',
  IMPROVEMENTS: 'IMPROVEMENTS',
  FEATURES: 'FEATURE',
});

const ReleaseNotes = ({ searchPlaceholder }) => {
  const [filterQuery, setFilterQuery] = useState('');
  const [posts, setPosts, publishedDate] = useState(null);

  const [categoryFilters, setCategoryFilters] = useState([
    {
      text: <p className="all">All</p>,
      icon: (
        <StaticImage src="../../images/checkmarks.svg" alt="checkmarks.svg" />
      ),
      blackIcon: (
        <StaticImage
          src="../../images/black-checkmarks.svg"
          alt="checkmarks.svg"
        />
      ),
      selected: true,
      category: Types.ALL,
    },
    {
      text: <p className="select_all_category_features">Features</p>,
      icon: (
        <StaticImage src="../../images/rocket-ship.svg" alt="rocket-ship.svg" />
      ),
      blackIcon: (
        <StaticImage
          src="../../images/black-rocket-ship.svg"
          alt="rocket-ship.svg"
        />
      ),
      selected: false,
      category: Types.FEATURES,
    },
    {
      text: <p className="select_all_category_improvements">Improvements</p>,
      icon: <StaticImage src="../../images/gears.svg" alt="gears.svg" />,
      blackIcon: (
        <StaticImage src="../../images/black-gears.svg" alt="gears.svg" />
      ),
      selected: false,
      category: Types.IMPROVEMENTS,
    },
    {
      text: <p className="select_all_category_bug-fix">Bug Fix</p>,
      icon: <StaticImage src="../../images/bug.svg" alt="bug.svg" />,
      blackIcon: <StaticImage src="../../images/black-bug.svg" alt="bug.svg" />,
      selected: false,
      category: Types.BUG_FIX,
    },
  ]);

  const currentDate = new Date();

  const getLastDay = function (y, m) {
    return new Date(y, m + 1, 0).getDate();
  };

  const quarter = Math.floor(currentDate.getMonth() / 3);
  const firstDate = new Date(currentDate.getFullYear(), quarter * 3, 1);
  const endDate = new Date(
    firstDate.getFullYear(),
    firstDate.getMonth() + 3,
    0
  );

  const [dateFilters, setDateFilters] = useState([
    {
      text: 'Current month',
      selected: false,
      startTime: moment().startOf('month'),
      endTime: moment().endOf('month'),
    },
    {
      text: 'Previous month',
      selected: false,
      startTime: moment().startOf('month').subtract(1, 'months'),
      endTime: moment().endOf('month').subtract(1, 'months'),
    },
    {
      text: 'Current quarter',
      selected: false,
      startTime: moment().startOf('quarter'),
      endTime: moment().endOf('quarter'),
    },
    {
      text: 'Current Year',
      selected: false,
      startTime: moment().startOf('year'),
      endTime: moment().endOf('year'),
    },
  ]);

  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            id: { eq: "release-notes-item" }
            status: { eq: "Published" }
          }
        }
        sort: { fields: [frontmatter___released_at], order: DESC }
      ) {
        nodes {
          frontmatter {
            title
            description
            type
            author
            released_at
            status
          }
          parent {
            ... on File {
              birthTime(formatString: "MM/DD/YYYY")
            }
          }
        }
      }
    }
  `);

  const onFilter = () => {
    const postsData = data.allMarkdownRemark.nodes.map((post) => ({
      ...post.frontmatter,
      created: post.parent.birthTime,
    }));

    const selectedCategory = categoryFilters.find((e) => e.selected).category;

    const setDateFilters = dateFilters.find((e) => e.selected);

    setPosts(
      postsData.filter(
        (post) =>
          (filterQuery.toLowerCase() == '' ||
            post.title.toLowerCase().includes(filterQuery.toLowerCase()) ||
            post.description
              .toLowerCase()
              .includes(filterQuery.toLowerCase()) ||
            post.author.toLowerCase().includes(filterQuery.toLowerCase())) &&
          (setDateFilters == undefined ||
            (setDateFilters != undefined &&
              new Date(post.released_at) >=
              new Date(setDateFilters.startTime) &&
              new Date(post.released_at) <=
              new Date(setDateFilters.endTime))) &&
          (post.type == selectedCategory || selectedCategory === null)
      )
    );
  };

  useEffect(() => {
    onFilter();
  }, [filterQuery, dateFilters, categoryFilters]);

  useEffect(() => {
    setPosts(
      data.allMarkdownRemark.nodes.map((post) => ({
        ...post.frontmatter,
        created: post.parent.birthTime,
      }))
    );
  }, []);

  return (
    <Container>
      <SearchContainer>
        <SearchWrapper>
          <InputWrapper>
            <StaticImage
              src="../../images/search-icon.png"
              alt="search-icon.png"
            />
            <SearchInput
              placeholder={searchPlaceholder}
              onChange={(e) => setFilterQuery(e.target.value)}
            />
          </InputWrapper>
        </SearchWrapper>
      </SearchContainer>

      <Content>
        <FilterSideBar>
          <h4>Category</h4>

          <FilterItems>
            {categoryFilters.map((item, index) => (
              <li
                key={index}
                className={`${item.category != null
                  ? kebabCase(item.category.toLowerCase())
                  : 'all'
                  } ${item.selected ? 'active' : ''}`}
                onClick={() => {
                  setCategoryFilters((prevState) =>
                    prevState.map((category, idx) => ({
                      ...category,
                      selected: idx === index,
                    }))
                  );
                }}
              >
                {item.selected ? item.icon : item.blackIcon} {item.text}
              </li>
            ))}
          </FilterItems>

          <hr />
          <h4>Date</h4>
          <FilterItems>
            {dateFilters.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  setDateFilters((prevState) =>
                    prevState.map((date, idx) => ({
                      ...date,
                      selected: idx === index ? !date.selected : false,
                    }))
                  );
                }}
              >
                <div className="release_notes__checkmark">
                  {item.selected && (
                    <StaticImage src="../../images/tick.png" alt="tick.png" />
                  )}
                </div>
                {item.text}
              </li>
            ))}
          </FilterItems>
        </FilterSideBar>

        <Posts>
          <h1 className="search_posts_date">
            {dateFilters.find((e) => e.selected) != undefined
              ? dateFilters.find((e) => e.selected).text
              : 'All'}
          </h1>
          {posts &&
            posts.map((post, index) => {
              const { title, description, author, type } = post;
              return (
                <PostCard
                  title={title}
                  description={description}
                  author={author}
                  category={type}
                  key={index}
                />
              );
            })}
        </Posts>
      </Content>
    </Container>
  );
};

ReleaseNotes.PropTypes = {
  searchPlaceholder: PropTypes.string.isRequired,
};

export default ReleaseNotes;
