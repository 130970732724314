import React, { useEffect } from 'react';
import Title from 'src/styles/styledComponents/title';
import { kebabCase } from 'src/utility/helpers';

const PolicyContent = ({ content }) => {
  const { title, section_name } = content.node.frontmatter;
  useEffect(() => {
    document.addEventListener('click', function (e) {
      if (e.target.tagName === 'A' && !e.target.hasAttribute('target')) {
        e.target.setAttribute('target', '_blank');
      }
    });
  }, []);
  return (
    <div id={kebabCase(section_name)} className={'policy-section'}>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <div dangerouslySetInnerHTML={{ __html: content.node.html }} />
    </div>
  );
};
export default PolicyContent;
