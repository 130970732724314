import React, { useState } from 'react';
import Title from 'src/styles/styledComponents/title';
import Description from 'src/styles/styledComponents/description';
import Card from 'src/styles/styledComponents/card';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ButtonBlack from 'src/styles/styledComponents2/buttonBlack';
import ButtonWithIcon from 'src/styles/styledComponents2/buttonWithIcon';
import VideoModal from 'src/components/home/videoModal';
import { getFrontmatter } from 'src/utility/helpers';

const Hero = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "hero" } } }
        ) {
          nodes {
            id
            frontmatter {
              navigation_buttons
              titles
              link
              description
              info
              image {
                childImageSharp {
                  gatsbyImageData(width: 868)
                }
              }
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `),
    { titles, description, navigation_buttons, info, image, background, link } =
      getFrontmatter(data),
    [visible, setVisible] = useState(false);
  return (
    <Card
      padding={'120px 70px'}
      background={background?.childImageSharp.fluid.src}
      size={'cover'}
      positionY={'top'}
      hideBgInMobile
      color={'#fbf8f5'}
    >
      <div className={'container hero-container'}>
        <div>
          <Title size={'55px'} className={'title'}>
            <span style={{ color: '#00ad87' }}>{titles[0]} </span>
            {titles[1]} <span style={{ color: '#ff98a9' }}>{titles[2]} </span>{' '}
            {titles[3]}
          </Title>
          <Description
            size={'24px'}
            lineHeight={'33px'}
            mobileLine={'20px'}
            width={'75%'}
            color={'#70757f'}
          >
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Description>
          <div className={'actions'}>
            <ButtonBlack>
              <a href={link} className="hero_sign_up_link">
                {navigation_buttons[0]}
              </a>
            </ButtonBlack>
            <ButtonWithIcon onClick={() => setVisible(true)}>
              <span className={'round-button'} />
            </ButtonWithIcon>
          </div>
          <Description
            color={'rgba(0,0,0,.3)'}
            dangerouslySetInnerHTML={{ __html: info }}
          />
        </div>
        <div className={'hero-image-container'}>
          <GatsbyImage alt={'hero'} image={getImage(image)} />
        </div>
      </div>
      <VideoModal visible={visible} setVisible={setVisible} />
    </Card>
  );
};

export default Hero;
