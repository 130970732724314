/* eslint-disable prettier/prettier */
import React from 'react';
import styled from 'styled-components';
import { buttonStyles, responsiveWidth } from 'src/styles/constants';

const Button = styled.button`
  border-radius: 10px;
  box-shadow: ${(props) => buttonStyles[props.type || 'primary'].shadow};
  background-color: ${(props) =>
    buttonStyles[props.type || 'primary'].backgroundColor};
  padding: ${(props) => (props.size === 'sm' ? '9px 22px' : '15px 35px')};
  border: none;
  font-size: ${(props) => (props.size === 'sm' ? '16px' : '22px')};
  color: ${(props) => buttonStyles[props.type || 'primary'].color};
  font-weight: 600;
  cursor: pointer;
  margin-top: ${(props) => props.top || '0'};
  font-family: inherit;
  letter-spacing: ${(props) => (props.size === 'sm' ? '0' : '-0.5px')};
  ${(props) =>
    props.isDisabled &&
    'pointer-events: none; opacity: 0.6; cursor: not-allowed;'}
  transition: background-color .15s;
  &:hover {
    background-color: ${(props) =>
    buttonStyles[props.type || 'primary'].hoverColor};
  }
  .change_begins_today_sign_up {
    color: #fff;
    text-decoration: none;
  }
  &.stores-button{
    padding: 28px 32px !important;
    a{
      color: #ffffff;
      text-decoration: none;
    }
  }

  .home_call_to_action_link {
    text-decoration: none;
    color: #fff;
  }
  @media ${responsiveWidth.mobile} {
    font-size: ${(props) => (props.size === 'sm' ? '16px' : '18px')};
  }
`;
export default Button;
