import React from 'react';
import Card from 'src/styles/styledComponents/card';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Hero = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "about_us_hero" } } }
        ) {
          nodes {
            frontmatter {
              titles
              description
              image {
                childImageSharp {
                  gatsbyImageData(width: 700)
                }
              }
            }
          }
        }
      }
    `),
    { titles, description, image } =
      data.allMarkdownRemark.nodes[0].frontmatter;
  return (
    <Card color={'#fbf8f5'}>
      <div className={'container hero-container-about'}>
        <div>
          <Title
            size={'50px'}
            marginBottom={'20px'}
            weight={'600'}
            top={'10px'}
          >
            {titles[0]}
          </Title>
          <Description
            size={'23px'}
            marginBottom={'20px'}
            color={'#201f18'}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <div className={'hero-image-container'}>
          <GatsbyImage alt={'hero'} image={getImage(image)} />
        </div>
      </div>
    </Card>
  );
};

export default Hero;
