import React from 'react';
import 'rc-collapse/assets/index.css';
import Title from 'src/styles/styledComponents/title';
import Description from 'src/styles/styledComponents/description';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import {
  Container,
  ContentWrapper,
  Wrapper,
} from '../../styles/components/plan/pricingFaq';
import StyledCollapse from '../../styles/styledComponents/styledCollapse';
import { StaticImage } from 'gatsby-plugin-image';
import HorizontalLine from '../../styles/styledComponents/horizontalLine';
import { Panel } from 'rc-collapse';
import NavButton from '../../styles/styledComponents/navButton';

const PricingFaq = ({ headless }) => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "pricing_faq" } } }
        ) {
          nodes {
            frontmatter {
              title
              description
              navigation_button
              link
              faq_list {
                answers
                question
                template
              }
            }
            id
          }
        }
      }
    `),
    { title, description, faq_list, link, navigation_button } =
      data.allMarkdownRemark.nodes[0].frontmatter;
  return (
    <Container>
      <Wrapper>
        <div className={'container faq-container'}>
          <ContentWrapper>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </ContentWrapper>
          <StyledCollapse
            accordion={true}
            defaultActiveKey={'0'}
            expandIcon={() => (
              <div className={'collapse-arrow'}>
                {/* <StaticImage
                  src={'../../images/arrow.png'}
                  alt={'arrow'}
                  height={24}
                /> */}
              </div>
            )}
          >
            {faq_list.map((content, index) => (
              <Panel header={content.question} key={index} showArrow={true}>
                <HorizontalLine
                  line={'-10px'}
                  width={'100%'}
                  height={'2px'}
                  color={'#EBEEF3'}
                />
                <div dangerouslySetInnerHTML={{ __html: content.answers }} />
              </Panel>
            ))}
          </StyledCollapse>
          {headless && (
            <div className={'actions'}>
              <NavButton marginTop={'0'} onClick={() => navigate(link)}>
                {navigation_button}
              </NavButton>
            </div>
          )}
        </div>
      </Wrapper>
    </Container>
  );
};

export default PricingFaq;
