import React from 'react';
import CombinedHeroWebWrapper from 'src/styles/styledComponents2/combinedHeroWebWrapper';
import Button from 'src/styles/styledComponents/button';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { getFrontmatter, getFrontmatterOf } from 'src/utility/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BreadCrumb from '../breadCrumb';

const CombinedHeroWeb = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "hero_product_web" } } }
        ) {
          nodes {
            id
            frontmatter {
              title
              description
              note
              bottom_text
              button_text
              link
              image {
                childImageSharp {
                  gatsbyImageData(width: 960)
                }
              }
              detailed_description_with_points {
                points_1
                points_2
                points_3
              }
            }
          }
        }
        header: allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "header" } } }
        ) {
          nodes {
            frontmatter {
              action_buttons
              products_list {
                title
              }
            }
            id
          }
        }
      }
    `),
    {
      title,
      description,
      image,
      button_text,
      link,
      detailed_description_with_points,
    } = getFrontmatter(data),
    { action_buttons, products_list } = getFrontmatterOf(data, 'header');

  const breadCrumb = [
    { label: 'Home page', path: '/' },
    { label: action_buttons[3], path: '/products-webapp' },
    { label: products_list[0].title },
  ];
  return (
    <>
      <div className={'product-web'}>
        <BreadCrumb breadCrumb={breadCrumb} padding={'0px'} />
      </div>
      <CombinedHeroWebWrapper color={'#fbf8f5'}>
        <div className={'container hero-web-container'}>
          <div>
            <Title
              size={'40px'}
              marginBottom={'20px'}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <Description
              size={'19px'}
              lineHeight={'28px'}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className={'description-points'}>
              {Object.values(detailed_description_with_points)
                .filter((value) => value !== '')
                .map((description, index) => (
                  <div className={'description-card'} key={index}>
                    <img
                      src={
                        require('../../images/spark-fill-yellow.svg').default
                      }
                      className={'spark-icon'}
                      alt={'spark-fill'}
                    />
                    <span
                      className={'description'}
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  </div>
                ))}
            </div>
            <div className={'actions'}>
              <Button onClick={() => navigate(link)}>{button_text}</Button>
            </div>
          </div>
          <div className={'hero-image-container'}>
            <GatsbyImage alt={'hero'} image={getImage(image)} />
          </div>
        </div>
      </CombinedHeroWebWrapper>
    </>
  );
};

export default CombinedHeroWeb;
