import React from 'react';
import styled from 'styled-components';
import { breakPoints } from '../../constants';
import fontFace from '../../../css/typography.css';

export const Container = styled.div` {
  background: #fbf8f5;
  padding-top: 100px !important;
    .hero-container {
      max-width: 1150px !important;
      width: 100%;
      @media(max-width: ${breakPoints.pcLarge}) {
        max-width: 1300px;
      }
    }
    .container__contributors {
      margin: auto;
     
      .container__contributors--wrapper {
        display: grid;
        grid-template-columns: 1fr 1.1fr;

       @media(max-width: ${breakPoints.pc}){
        padding-top: 30px;
       }
       @media(max-width: ${breakPoints.pcSmall}){
        padding-top: 0;
       }
        @media (max-width: ${breakPoints.tablet}){
          display: flex;
          flex-direction: column-reverse;
        }
        .header {
          width: 180%;
          max-width: 860px;
          z-index: 2;
          @media (min-width: ${breakPoints.pcLarge}) {
            padding-top: 85px;
          }
          @media (max-width: ${breakPoints.pcLarge}) {
            max-width: 685px;
            width: 151%;
            padding-top: 60px;
          }
          @media (max-width: ${breakPoints.pc}) {
            padding-left: 68px;
            padding-top: 10px;
            width: 111%;
          }
          @media (max-width: ${breakPoints.pcSmall}) {
            padding-left: 15px;
            max-width: 368px;
            width: 125%;
            padding-top: 30px;
            margin-left: 21%;
          }
          @media (max-width: ${breakPoints.tablet}) {
            margin: auto;
            padding-top: 0;
            padding-bottom: 10px;
          }
          @media (max-width: ${breakPoints.mobileLarge}) {
            max-width: 290px;
            padding-left: 0;
          }
          @media (max-width: ${breakPoints.mobileSmall}) {
            max-width: 250px;
          }
          .header_title {
            font-family: 'AvertaStd-Bold';
            font-size: 48px;
            line-height: 56px;
            color: #2C3038;
          
            @media (max-width: ${breakPoints.pc}) {
              max-width: 610px;
              position: relative;
              bottom: 15px;
            }
            @media (max-width: ${breakPoints.pcSmall}) {
              position: static;
              font-size: 28px;
              line-height: 33px;
              padding-bottom: 15px !important;
            }
            @media (max-width: ${breakPoints.tablet}) {
              padding-bottom: 15px;
              text-align: center;
              max-width: 348px;
              margin: auto !important;
              width: 100%;
            }
            @media (max-width: ${breakPoints.mobileLarge}) {
              font-size: 26px;
              line-height: 30px;
            }
            @media (max-width: ${breakPoints.mobileSmall}) {
              font-size: 22px;
              line-height: 26px;
              padding-bottom: 15px;
            }
          }
          .header_subtitle {
            font-family: 'AvertaStd-Semibold';
            font-size: 28px;
            line-height: 33px;
            color: #70757F;
            @media (max-width: ${breakPoints.tablet}) {
              padding-bottom: 25px;
              text-align: center;
            }
            @media (max-width: ${breakPoints.mobileLarge}) {
              font-size: 18px;
              line-height: 21px;
            }
          }
        }
      .hero-image-container { 
        @media (max-width: ${breakPoints.tablet}) {
          margin: auto;
          padding-bottom: 30px;
        }
        > img {
          @media (min-width: ${breakPoints.pcLarge}) {
            max-width: 1221px;
            position: relative;
            left: 88px;
          }
          @media (max-width: ${breakPoints.pcLarge}) {
            max-width: 721px;
            position: relative;
            left: 18px;
          }
          @media (max-width: ${breakPoints.pc}) {
            position: static;
            max-width: 540px;
          }
          @media (max-width: ${breakPoints.pcSmall}) {
            max-width: 660px;
            position: relative;
            left: -14px;
          }
          @media (max-width: 840px) {
            max-width: 593px;
          }
          @media (max-width: ${breakPoints.tablet}) {
            max-width: 555px;
            left: 0;
          }
          @media (max-width: ${breakPoints.mobileLarge}) {
            max-width: 285px;
          }
          @media (max-width: ${breakPoints.mobileSmall}) {
            max-width: 218px;
          }
        }
      }
    }
  }
  .content--about__wrapper {
    .content--about {
      background: #fff;
      padding: 90px 60px;

      @media (max-width: ${breakPoints.pc}) {
      padding: 90px 65px;
      }
      @media (max-width: ${breakPoints.pcSmall}) {
        padding: 90px 20px;
      }
      @media (max-width: ${breakPoints.tablet}) {
        padding: 60px 10px;
      }
      @media (max-width: ${breakPoints.mobileLarge}) {
        padding: 30px 10px;
      }
      @media (max-width: ${breakPoints.mobileSmall}) {
        padding: 34px 10px;
      }

      & > :nth-child(1) {       
          font-family: 'AvertaStd-Semibold';
          font-size: 42px;
          line-height: 49px;
          text-align: center;
          letter-spacing: -0.025em;
          max-width: 1000px;
          margin: auto !important;

          .content--about__italic {
            font-family: 'AvertaStd-BoldItalic';
          }
          @media (max-width: ${breakPoints.pcSmall}) {
            font-size: 32px;
            line-height: 37px;
            max-width: 760px;
          }
          @media (max-width: ${breakPoints.tablet}) {
            max-width: 465px;
            font-size: 28px;
          }
          @media (max-width: ${breakPoints.mobileLarge}) {
            font-size: 18px;
            line-height: 21px;
            width: 80%;
          }
          @media (max-width: ${breakPoints.mobileSmall}) {
            font-size: 16px;
            line-height: 19px;
          }
      }

      & > :nth-child(2) {
        font-family: 'AvertaStd-Regular';
        font-size: 32px;
        line-height: 37px;
        text-align: center;
        letter-spacing: -0.025em;
        padding: 50px 0;
        max-width: 1160px;
        margin: auto;

        @media (max-width: ${breakPoints.pcLarge}) {
        font-size: 24px;
        line-height: 28px;
        max-width: 1210px;
        }
        @media (max-width: ${breakPoints.pcSmall}) {
          font-size: 20px;
          line-height: 23px;
          padding: 40px 20px 70px 20px;
          max-width: 753px;
        }
        @media(max-width: ${breakPoints.mobileLarge}) {
          font-size: 14px;
          line-height: 16px;
          padding: 0 20px;
          padding-top: 25px;
          padding-bottom: 45px;
        }
      }
      & > :nth-child(3) {
        font-family: 'AvertaStd-Semibold';
        font-size: 48px;
        line-height: 56px;
        text-align: center;
        letter-spacing: -0.025em;
        margin: auto;

        @media (max-width: ${breakPoints.pcSmall}) {
          font-size: 32px;
          line-height: 37px;
        }
        @media (max-width: ${breakPoints.mobileLarge}) {
          font-size: 28px;
          line-height: 33px;
        }
      }
    }
  }
`;
