import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import FeatureCard from 'src/styles/styledComponents2/featureCard';
import MasonryLikeGrid from 'src/styles/styledComponents2/masonryLikeGrid';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import ButtonBlack from '../../styles/styledComponents2/buttonBlack';

const WebClipperFeatures = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              section_name: { eq: "products_webclipper_features" }
            }
          }
        ) {
          nodes {
            id
            frontmatter {
              content_list {
                image {
                  childImageSharp {
                    gatsbyImageData(width: 369)
                  }
                }
                description
                title
                button_text
                link
              }
            }
          }
        }
      }
    `),
    { content_list } = getFrontmatter(data);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function reportWindowSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, []);
  return (
    <MasonryLikeGrid>
      {content_list.map((content, index) => (
        <FeatureCard key={index}>
          {windowWidth > 768 ? (
            <>
              <GatsbyImage alt={'feature'} image={getImage(content.image)} />
              <div>
                <Title
                  marginBottom={'20px'}
                  lineHeight={'52px'}
                  dangerouslySetInnerHTML={{ __html: content.title }}
                />
                <Description
                  color={'#4D4F52'}
                  size={'19px'}
                  lineHeight={'27px'}
                  className={'product-webclipper-description'}
                  dangerouslySetInnerHTML={{ __html: content.description }}
                />
                <div className={'actions'}>
                  <ButtonBlack>
                    <a href={content.link} className="hero_sign_up_link">
                      {content.button_text}
                    </a>
                  </ButtonBlack>
                </div>
              </div>
            </>
          ) : (
            <>
              <Title
                size={'26px'}
                lineHeight={'30px'}
                marginBottom={'20px'}
                dangerouslySetInnerHTML={{ __html: content.title }}
              />
              <GatsbyImage alt={'feature'} image={getImage(content.image)} />
              <div>
                <Description
                  lineHeight={'21px'}
                  marginBottom={'0 0 30px'}
                  align={'center'}
                  size={'14px'}
                  color={'#4D4F52'}
                  className={'product-webclipper-description'}
                  dangerouslySetInnerHTML={{ __html: content.description }}
                />
                <div className={'actions'}>
                  <ButtonBlack>
                    <a href={content.link} className="hero_sign_up_link">
                      {content.button_text}
                    </a>
                  </ButtonBlack>
                </div>
              </div>
            </>
          )}
        </FeatureCard>
      ))}
    </MasonryLikeGrid>
  );
};

export default WebClipperFeatures;
