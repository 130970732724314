import React from 'react';
import { Panel } from 'rc-collapse';
import Title from 'src/styles/styledComponents/title';
import Description from 'src/styles/styledComponents/description';
import StyledCollapse from 'src/styles/styledComponents/styledCollapse';
import NavButton from 'src/styles/styledComponents/navButton';
import Card from 'src/styles/styledComponents/card';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import HorizontalLine from '../../styles/styledComponents/horizontalLine';

const Faq = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "faq" } } }
        ) {
          nodes {
            frontmatter {
              title
              description
              link
              faq_list {
                answers
                question
                template
              }
              download
              navigation_button
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            id
          }
        }
      }
    `),
    { title, description, faq_list, navigation_button, link } =
      data.allMarkdownRemark.nodes[0].frontmatter;
  return (
    <Card>
      <div className={'container faq-container'}>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <StyledCollapse
          accordion={true}
          defaultActiveKey={'0'}
          expandIcon={() => (
            <div className={'collapse-arrow'}>
              {/* <StaticImage
                src={'../../images/arrow.png'}
                alt={'arrow'}
                height={24}
              /> */}
            </div>
          )}
        >
          {faq_list.map((item, index) => (
            <Panel header={item.question} key={index} showArrow={true}>
              <HorizontalLine
                line={'-10px'}
                width={'100%'}
                height={'2px'}
                color={'#EBEEF3'}
              />
              <div dangerouslySetInnerHTML={{ __html: item.answers }} />
            </Panel>
          ))}
        </StyledCollapse>
        <div className={'actions'}>
          <NavButton marginTop={'0'} onClick={() => navigate(link)}>
            {navigation_button}
          </NavButton>
        </div>
      </div>
    </Card>
  );
};

export default Faq;
