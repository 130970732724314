import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';
import Grid from 'src/styles/styledComponents2/grid';

const ImageWithTitleDescription = styled(Grid)`
  margin-top: 0;
  position: relative;
  &:not(:last-child) {
    margin-bottom: ${(props) => props.bottom};
  }
  .content {
    text-align: left;
    padding: 10% 0;
    .content-title {
      font-weight: 400;
      font-size: 22px;
      color: rgba(184, 184, 184, 0.5);
      padding-bottom: 5px;
    }
    .description-card {
      display: flex;
      align-items: flex-start;
    }
    .spark-icon {
      margin-top: 3px;
      padding-right: 5px;
      height: 18px;
      width: 18px;
      display: block;
    }
  }
  @media ${responsiveWidth.mobile} {
    .content {
      padding: 0;
      .content-title {
        margin-top: 30px;
        font-size: 16px;
      }
      .spark-icon {
        margin-top: 3px;
        height: 16px;
        width: 16px;
      }
    }
  }
`;
export default ImageWithTitleDescription;
