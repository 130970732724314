import React from 'react';
import styled from 'styled-components';

const TextAreaWrapper = styled.div`
  position: relative;
  label {
    position: absolute;
    color: rgba(0, 0, 0, 0.3);
    font-size: ${(props) => (props.isFocus ? '16px' : '22px')};
    top: ${(props) => (props.isFocus ? '5px' : '20px')};
    left: 40px;
    transition: all 0.3s;
  }
  textarea {
    padding: 25px 20px;
  }
`;
export default TextAreaWrapper;
