import React, { useEffect, useState } from 'react';
import Header from 'src/components/header';
import Footer from 'src/components/footer';
import {
  GlobalStyle,
  GlobalStylePlans,
  GlobalStyleV2,
} from 'src/styles/globalStyles';
import PricingFaq from 'src/components/plans/pricingFaq';
import PlansIntro from 'src/components/plans/plansIntro-buy';
import PlansDetailed from 'src/components/plans/plansDetailed';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import PageHelmet from 'src/components/pageHelmet';
import ContactUsSection from 'src/components/plans/contactUsSection';
import PlansProducts from 'src/components/plans/plansProducts';

import { InitialLoader } from '../components/preloader/initialLoader';
import CurrentPlan from 'src/components/plans/currentPlan';

const Plans = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "plans_page_data" } } }
        ) {
          nodes {
            frontmatter {
              title
              description
              page_title
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `),
    { title, description } = getFrontmatter(data),
    [loader, setLoader] = useState(true);

  let headless =
    typeof window !== 'undefined' &&
    window.location.search.includes('?headless');

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);
  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description} />
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStylePlans />
      {!headless && <Header />}
      <CurrentPlan />
      <PlansIntro />
      <PricingFaq />
      <PlansDetailed />
      <PlansProducts />
      <ContactUsSection />
      {!headless && <Footer />}
    </>
  );
};

export default Plans;
