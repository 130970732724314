import React from 'react';
import styled from 'styled-components';
import { breakPoints } from '../../constants';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fbf8f5;
  padding: 96px 0;
  padding-top: 0;
  min-width: 100%;

  h3 {
    font-size: 41px;
    max-width: 65vw;
    margin: 49px auto 41px auto;
    text-align: center;
    font-weight: bold;
    color: #322e2e;
    line-height: 38px;
    letter-spacing: -1.5px;
  }

  @media (min-width: ${breakPoints.tablet}) {
    padding-top: 0;
    max-width: 600px;
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    padding: 96px 0;
    padding-top: 96px;
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    h3 {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  h1 {
    font-size: 21px;
  }

  .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
    }
  }

  .gatsby-image-wrapper {
    max-width: 200px;
  }

  @media (min-width: ${breakPoints.tablet}) {
    grid-template-columns: repeat(3, minmax(250px, 1fr));

    .gatsby-image-wrapper {
      max-width: 187px;
      max-height: 115px;
    }
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    grid-template-columns: repeat(3, minmax(310px, 1fr));

    .gatsby-image-wrapper {
      max-width: 215px;
      max-height: 133px;
    }

    h1 {
      font-size: 25px;
    }
  }

  @media (min-width: ${breakPoints.pc}) {
    h1 {
      font-size: 30px;
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    grid-template-columns: repeat(3, minmax(450px, 1fr));

    h1 {
      font-size: 36px;
    }
  }
`;

export default Container;
