import React, { useEffect, useState } from 'react';
import CookieBannerWrapper from 'src/styles/styledComponents/cookieBannerWrapper';
import CookieOverlay from 'src/styles/styledComponents/cookieOverlay';
import Title from 'src/styles/styledComponents/title';
import Button from 'src/styles/styledComponents/button';
import Cookies from 'universal-cookie';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import LinkText from 'src/styles/styledComponents2/linkText';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const CookieBanner = ({ hideBanner }) => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "cookie_banner" } } }
        ) {
          nodes {
            frontmatter {
              title
              descriptions
              button_text
              image {
                childImageSharp {
                  gatsbyImageData(width: 150)
                }
              }
            }
            id
          }
        }
      }
    `),
    { title, descriptions, button_text, image } =
      data.allMarkdownRemark.nodes[0].frontmatter;
  const [visible, setVisible] = useState(false),
    handleBanner = (value) => {
      setVisible(value);
      if (typeof window !== 'undefined') {
        const cookies = new Cookies();
        value === false && cookies.set('acceptedCookie', 'true');
      }
    };
  useEffect(() => {
    setTimeout(() => {
      let hasCookie = 'false';
      if (typeof window !== 'undefined') {
        const cookies = new Cookies();
        hasCookie = cookies.get('acceptedCookie');
        hasCookie === 'true' ? setVisible(false) : setVisible(true);
      }
    }, 3000);
  }, []);
  return (
    <>
      <CookieBannerWrapper visible={visible && !hideBanner}>
        <GatsbyImage alt={'cookie'} image={getImage(image)} />
        <Title
          marginBottom={'20px'}
          size={'26px'}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className={'description'}>
          <span>{descriptions[0]}</span>{' '}
          <LinkText to={'/cookie-policy'}>{descriptions[1]}</LinkText>{' '}
          <span>{descriptions[2]}</span>{' '}
          <LinkText to={'/cookie-preferences'}>{descriptions[3]}</LinkText>
        </div>
        <Button size={'sm'} onClick={() => handleBanner(false)}>
          {button_text}
        </Button>
      </CookieBannerWrapper>
      {visible && !hideBanner && <CookieOverlay />}
    </>
  );
};

export default CookieBanner;
