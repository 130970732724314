import React from 'react';
import VideoJS from './VideoJS'; // point to where the functional component is stored

const VideoApp = ({ src, playsinline = false, autoplay = false }) => {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: autoplay,
    playsinline: playsinline,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src,
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  // const changePlayerOptions = () => {
  //   // you can update the player through the Video.js player instance
  //   if (!playerRef.current) {
  //     return;
  //   }
  //   // [update player through instance's api]
  //   playerRef.current.src([{src: 'http://ex.com/video.mp4', type: 'video/mp4'}]);
  //   playerRef.current.autoplay(false);
  // };

  return <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />;
};

export default VideoApp;
