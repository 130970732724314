import React from 'react';
import styled from 'styled-components';

const WelcomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  div {
    padding-bottom: ${(props) => (props.auth ? '0' : '5')};
  }

  .logout-action {
    margin: 0;
    font-size: small;
    color: #2c3038;
    text-decoration: underline;
    cursor: pointer;
  }
`;
export default WelcomWrapper;
