import React, { useEffect, useState } from 'react';
import { getFrontmatter, getFrontmatterOf } from 'src/utility/helpers';
import CombinedHeroMobileWrapper from 'src/styles/styledComponents2/combinedHeroMobileWrapper';
import NoteWithQuote from 'src/styles/styledComponents2/noteWithQuote';
import { graphql, useStaticQuery } from 'gatsby';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import Topic from 'src/styles/styledComponents/topic';
import BreadCrumb from '../breadCrumb';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const CombinedHeroMobile = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "products_mobile_hero" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              description
              note
              bottom_text
              image {
                childImageSharp {
                  gatsbyImageData(width: 700)
                }
              }
            }
          }
        }
        header: allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "header" } } }
        ) {
          nodes {
            frontmatter {
              action_buttons
              products_list {
                title
              }
            }
            id
          }
        }
        link: allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "stores" } } }
        ) {
          nodes {
            id
            frontmatter {
              mobile {
                android_link
                ios_link
              }
            }
          }
        }
      }
    `),
    { title, description, image, note, bottom_text } = getFrontmatter(data),
    { action_buttons, products_list } = getFrontmatterOf(data, 'header'),
    { mobile } = getFrontmatterOf(data, 'link');

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function reportWindowSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, []);

  const breadCrumb = [
    { label: 'Home page', path: '/' },
    { label: action_buttons[3], path: '/products-mobile' },
    { label: products_list[1].title },
  ];
  return windowWidth > 768 ? (
    <>
      <div className={'product-mobile'}>
        <BreadCrumb breadCrumb={breadCrumb} />
      </div>
      <CombinedHeroMobileWrapper>
        <div className={'container'}>
          <div>
            <Title
              marginBottom={'10px'}
              className={'underLine'}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <Description
              size={'19px'}
              lineHeight={'28px'}
              dangerouslySetInnerHTML={{ __html: description }}
              top={'20px'}
            />
            <div className={'image'}>
              <a href={mobile.android_link}>
                <img
                  src={'../../../img/android-app.png'}
                  width={100}
                  alt={'appstore'}
                />
              </a>
              <a href={mobile.ios_link}>
                <img
                  src={'../../../img/ios-app.png'}
                  width={100}
                  alt={'playstore'}
                />
              </a>
            </div>
          </div>
        </div>
        <div className={'container'}>
          <div>
            <Topic
              size={'36px'}
              padding={'0 50px'}
              color={'#000'}
              bottom={'0'}
              dangerouslySetInnerHTML={{ __html: bottom_text }}
            />
            <NoteWithQuote
              marginTop={'15px'}
              size={'14px'}
              padding={' 0 50px 50px'}
              lineHeight={'21px'}
              bottom={'15px'}
              dangerouslySetInnerHTML={{ __html: note }}
            />
          </div>
          <div className={'image-mobile'}>
            <GatsbyImage alt={'hero'} image={getImage(image)} />
          </div>
        </div>
      </CombinedHeroMobileWrapper>
      <div className={'arrow'} />
    </>
  ) : (
    <>
      <CombinedHeroMobileWrapper>
        <div className={'container-image'}>
          <div>
            <Title
              className={'underLine'}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
          <div>
            <img
              src={require('../../images/mobile-product.svg').default}
              alt={'hero'}
            />
          </div>
        </div>
        <div className={'container-description'}>
          <Description
            size={'14px'}
            align={'center'}
            dangerouslySetInnerHTML={{ __html: description }}
            top={'25px'}
          />
          <div className={'images'}>
            <a href={mobile.ios_link}>
              <img
                src={'../../../img/badge-appstore-copy-3x.png'}
                width={100}
                alt={'appstore'}
              />
            </a>
            <a href={mobile.android_link}>
              <img
                src={'../../../img/badge-playstore-copy-3x.png'}
                width={100}
                alt={'playstore'}
              />
            </a>
          </div>
        </div>
        <div className={'quotes'}>
          <Topic
            padding={'0 50px'}
            color={'#000'}
            bottom={'0'}
            dangerouslySetInnerHTML={{ __html: bottom_text }}
          />
          <NoteWithQuote
            size={'18px'}
            lineHeight={'27px'}
            bottom={'10px'}
            marginTop={'15px'}
            padding={' 0 50px 50px'}
            dangerouslySetInnerHTML={{ __html: note }}
          />
        </div>
      </CombinedHeroMobileWrapper>
      <div className={'arrow'} />
    </>
  );
};

export default CombinedHeroMobile;
