import React from 'react';
import styled from 'styled-components';
import { breakPoints } from '../../constants';

export const Container = styled.div`
  background-color: white;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto !important;
  padding: 83px 0;
  max-width: 90vw;

  @media (min-width: ${breakPoints.tablet}) {
    max-width: 710px;
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    padding-top: 51px;
    max-width: 877px;
  }

  @media (min-width: ${breakPoints.pc}) {
    max-width: 1066px;
    padding-top: 57px;
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    padding-top: 83px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 21px;

  @media (min-width: ${breakPoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 43px;
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    column-gap: 65px;
    row-gap: 47px;

    h1 {
      font-size: 25px;
      margin-bottom: 19px;
    }

    div {
      font-size: 16px;
    }
  }

  @media (min-width: ${breakPoints.pc}) {
    column-gap: 79px;
    row-gap: 57px;

    h1 {
      font-size: 30px;
      margin-bottom: 24px;
    }

    div {
      font-size: 20px;
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    column-gap: 93px;
    row-gap: 68px;

    h1 {
      font-size: 36px;
      margin-bottom: 23px;
    }

    div {
      font-size: 23px;
    }
  }
`;

export const ContentWrapper = styled.div`
  h1 {
    font-size: 45px;
    text-align: center;
    margin-bottom: 50px;
  }

  div {
    font-size: 18px;
  }

  @media (max-width: ${breakPoints.tablet}) {
    h1 {
      font-size: 26px;
    }
    div {
      font-size: 14px;
      text-align: center;
    }
  }
  @media (min-width: ${breakPoints.pcSmall}) {
    margin-bottom: 71px;

    h1 {
      font-size: 45px;
      margin-bottom: 27px;
    }

    div {
      font-size: 18px;
      max-width: 756px;
      text-align: center;
      margin: auto;
    }
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    margin-bottom: 71px;

    h1 {
      font-size: 45px;
      margin-bottom: 27px;
    }

    div {
      font-size: 18px;
      max-width: 756px;
      text-align: center;
      margin: auto;
    }
  }

  @media (min-width: ${breakPoints.pc}) {
    margin-bottom: 64px;

    h1 {
      font-size: 45px;
      margin-bottom: 27px;
    }

    div {
      font-size: 18px;
      max-width: 919px;
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    margin-bottom: 51px;
  }
`;
