/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '../../../styles/components/roadmap/content';
import Features from './features';
import RecentlyLaunched from './launched';
import ReleaseNoteButton from '../../../styles/components/roadmap/releaseNoteButton';
import { navigate } from 'gatsby';

const Content = ({ sections }) => (
  <>
    <Container>
      {sections.map((section, index) => (
        <section className="roadmap__section" key={index}>
          {(() => {
            switch (section.shortcode) {
              case 'features':
                return <Features title={section.title} />;
              case 'launched-releases':
                return <RecentlyLaunched title={section.title} />;
              default:
                return null;
            }
          })()}
          {section.label && section.url && (
            <div className={'roadmap-button'}>
              <ReleaseNoteButton onClick={() => navigate(section.url)}>
                {section.label}
              </ReleaseNoteButton>
            </div>
          )}
        </section>
      ))}
    </Container>
  </>
);

Content.protoTypes = {
  title: PropTypes.string.isRequired,
  releases: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      released_at: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Content;
