import styled from 'styled-components';

const PopupCard = styled.div`
  padding: ${(props) => (props.padding ? props.padding : '20px')};
  background-color: #fff;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: ${(props) => (props.border ? props.border : '5px')};
  max-width: ${(props) => (props.isMenuPopup ? 'unset' : '500px')};
  .item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
    cursor: pointer;
    align-items: center;
    width: 100%;

    .title {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .description {
      font-size: 12px;
    }
    .image {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
export default PopupCard;
