import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const HorizontalLine = styled.div`
  background-color: ${(props) => props.color || 'grey'};
  height: ${(props) => props.height || '1px'};
  border: 0;
  width: ${(props) => props.width || '90%'};
  transform: translateY(${(props) => props.line || '-100px'});

  @media ${responsiveWidth.mobile} {
    transform: translateY(${(props) => props.line || '-30px'});
  }
`;
export default HorizontalLine;
