import styled from 'styled-components';
import { breakPoints } from '../../constants';
import 'src/css/typography.css';

export const PlanCardContainer = styled.div`
  .plan-card {
    height: 10vh;
    background: #fbf8f5;

    @media (max-width: 992px) {
      height: 0;
    }
  }
`;
export const BuyPlan = styled.div`
  background-color: #fbf8f5;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  @media (max-width: ${breakPoints.pcSmall}) {
    padding-top: 70px !important;
  }
`;

export const Container = styled.div`
  max-width: 1150px;
  background: #fff;
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin: auto;
  position: relative;
  bottom: 35px;

  @media (min-width: ${breakPoints.pcLarge}) {
    max-width: 1150px;
  }
  @media (max-width: ${breakPoints.pc}) {
    max-width: 85vw;
  }
  @media (max-width: ${breakPoints.pcSmall}) {
    max-width: 87vw;
  }
`;

export const Wrapper = styled.div`
  .container {
    padding: 30px;
    @media (min-width: ${breakPoints.pc}) {
      max-width: 1100px;
    }
    @media (max-width: ${breakPoints.pc}) {
      max-width: 80vw;
    }
    @media (max-width: ${breakPoints.tablet}) {
      padding: 20px;
      max-width: 75vw;
    }
    .header_subtitle {
      font-family: 'AvertaStd-Bold';
      font-size: 25px;
      line-height: 29px;
      color: #1aac88;
      margin: 0;

      @media (max-width: ${breakPoints.mobileLarge}) {
        font-size: 14px;
        line-height: 1.2;
      }
    }
    .header_title {
      font-family: 'AvertaStd-Bold';
      font-size: 46px;
      line-height: 54px;
      letter-spacing: 1px;
      color: #000000;
      padding-bottom: 10px !important;
      text-align: left !important;

      @media (max-width: ${breakPoints.mobileLarge}) {
        font-size: 22px;
        line-height: 1;
      }
    }
    .active_status {
      background: #1fad8a;
    }
    .cancel_status {
      background: #ec6155;
    }
    ._status {
      border-radius: 4px;
      max-width: 100px;
      margin-top: 6px !important;
      margin-bottom: 15px !important;
      padding: 5px;
      margin: 0;
      font-family: 'AvertaStd-Bold';
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      text-align: center;

      @media (max-width: ${breakPoints.mobileLarge}) {
        font-size: 10px;
        line-height: 12px;
        padding: 5px 0;
      }
    }
    .description {
      font-family: 'AvertaStd-Regular';
      font-size: 25px;
      line-height: 29px;
      color: #000000;
      margin: 0;
      padding-top: 20px;
      padding-bottom: 50px;

      @media (max-width: ${breakPoints.mobileLarge}) {
        font-size: 14px;
        line-height: 17px;
        padding-top: 0px;
        padding-bottom: 10px;
      }
    }
    .button {
      font-family: 'Poppins', sans-serif;
      border: none;
      box-shadow: -6px 6px 7px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      background: #ec6155;
      padding: 8px 30px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      cursor: pointer;

      @media (max-width: ${breakPoints.mobileLarge}) {
        padding: 7px 20px;
        font-size: 12px;
        line-height: 18px;
      }
      &:hover {
        background-color: #e44637;
      }
    }
  }
`;
