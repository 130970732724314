import { graphql, useStaticQuery } from 'gatsby';

export const getWarningPopUp = (action) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            section_name: { eq: "plan_card_alert" }
            plan_id: { regex: "g/alert_/" }
          }
        }
      ) {
        nodes {
          frontmatter {
            plan_id
            title
            description
            ok_button
            cancel_button
            url
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.nodes
    .map((n) => n.frontmatter)
    .find((n) => n.plan_id.split(',').includes(`alert_${action}`));
};
