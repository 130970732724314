import React, { useEffect, useState } from 'react';
import Header from 'src/components/header';
import Footer from 'src/components/footer';
import {
  GlobalStyle,
  GlobalStylePlans,
  GlobalStyleV2,
  GlobalStyleAboutUs,
} from 'src/styles/globalStyles';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import PageHelmet from 'src/components/pageHelmet';
import Hero from 'src/components/aboutUs/hero';
import AboutUsInfo from 'src/components/aboutUs/aboutUsInfo';
import CallToAction from 'src/components/aboutUs/callToAction';
import AboutUsContent from 'src/components/aboutUs/aboutUsContent';

import { InitialLoader } from '../components/preloader/initialLoader';

const AboutUs = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "about_us_page_data" } }
          }
        ) {
          nodes {
            frontmatter {
              description
              title
              page_title
              info
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `),
    { title, description } = getFrontmatter(data),
    [loader, setLoader] = useState(true);

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);

  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description} />
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStylePlans />
      <GlobalStyleAboutUs />
      <Header />
      <Hero />
      <AboutUsInfo />
      <AboutUsContent />
      <CallToAction />
      <Footer />
    </>
  );
};

export default AboutUs;
