import React from 'react';
import styled from 'styled-components';

const ModalButton1 = styled.button`
  border-radius: 4px;
  padding: 11px 28px;
  border: none;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  left: 32%;
  background-color: #25b8f4;
  margin-top: 15px;
`;
export default ModalButton1;
