import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import WhyContentListWrapper from 'src/styles/styledComponents/whyContentListWrapper';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ButtonBlack from '../../styles/styledComponents2/buttonBlack';

const WhySparkpicoContent = ({ type }) => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "why_sparkpico_content" } }
          }
        ) {
          nodes {
            frontmatter {
              title
              description
              why_sparkpico_contents {
                title
                description
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                button
                link
              }
            }
          }
        }
      }
    `),
    info = getFrontmatter(data);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    info != false && (
      <div style={{ paddingTop: '0px' }}>
        {info.why_sparkpico_contents.map((content, index) => (
          <WhyContentListWrapper align={'center'} key={index} type={type}>
            <div className={'container'}>
              {!index && (
                <>
                  <Title
                    className={'underLine'}
                    letterSpace={'0px'}
                    dangerouslySetInnerHTML={{ __html: info.title }}
                  />
                  <Description
                    dangerouslySetInnerHTML={{ __html: info.description }}
                  />
                </>
              )}
              <div className={'content'} id={`features-${index}`}>
                <div>
                  <Title
                    className={'whyspark-title'}
                    size={'37px'}
                    letterSpace={'0px'}
                    dangerouslySetInnerHTML={{ __html: content.title }}
                  />
                  <Description
                    size={'19px'}
                    className={'whyspark-description'}
                    dangerouslySetInnerHTML={{ __html: content.description }}
                  />
                  <div className={'actions'}>
                    <ButtonBlack top={'22px'}>
                      <a className="hero_sign_up_link" href={content.link}>
                        {content.button}
                      </a>
                    </ButtonBlack>
                  </div>
                </div>
                <div>
                  <GatsbyImage
                    alt={'why-sparkpico'}
                    image={getImage(content.image)}
                  />
                </div>
              </div>
            </div>
          </WhyContentListWrapper>
        ))}
      </div>
    )
  );
};

export default WhySparkpicoContent;
