/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getFrontmatter } from 'src/utility/helpers';
import Title from 'src/styles/styledComponents/title';
import ButtonOutlined from 'src/styles/styledComponents2/buttonOutlined';
import PlansProductCard from 'src/styles/styledComponents2/plansProductCard';
import Container, { Wrapper } from '../../styles/components/plan/products';

const PlansProducts = (currentPlan = null) => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "plans_products" } } }
        ) {
          nodes {
            frontmatter {
              title
              content_list {
                title
                button_text
                link
                ios_link
                android_link
                image {
                  childImageSharp {
                    gatsbyImageData(height: 180)
                  }
                }
              }
              mobile_message
            }
            id
          }
        }
      }
    `),
    { content_list, mobile_message } = getFrontmatter(data);

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    return 'unknown';
  }
  var OS = getMobileOperatingSystem();

  let headless =
    typeof window !== 'undefined' &&
    window.location.search.includes('headless'),
    access_token =
      typeof window !== 'undefined' &&
        window.location.search.includes('access_token=')
        ? new URL(window.location.href).searchParams.get('access_token')
        : false,
    plan_id = null,
    authenticated = null;

  if (headless && access_token) {
    plan_id = currentPlan?.currentPlan?.plan_id;

    if (plan_id != null) {
      authenticated = Boolean(plan_id);
    }
  }

  return (
    <Container>
      {mobile_message && mobile_message !== '' && <h3>{mobile_message}</h3>}
      <Wrapper>
        {content_list.map((content, index) => (
          <PlansProductCard key={index}>
            <Title
              size={'34px'}
              marginBottom={'30px'}
              dangerouslySetInnerHTML={{ __html: content.title }}
            />
            <GatsbyImage alt={'product'} image={getImage(content.image)} />
            <div className={'plan_product__btn'}>
              {(!authenticated ||
                (authenticated && content.button_text != 'Sign up')) && (
                  <ButtonOutlined className="plans_product_button">
                    {OS == 'iOS' && (
                      <a href={content.ios_link} className="plans_product_link">
                        {content.button_text}
                      </a>
                    )}
                    {OS == 'Android' && (
                      <a
                        href={content.android_link}
                        className="plans_product_link"
                      >
                        {content.button_text}
                      </a>
                    )}
                    {OS == 'unknown' && (
                      <a href={content.link} className="plans_product_link">
                        {content.button_text}
                      </a>
                    )}
                  </ButtonOutlined>
                )}
            </div>
          </PlansProductCard>
        ))}
      </Wrapper>
    </Container>
  );
};

export default PlansProducts;
