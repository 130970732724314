import { graphql, useStaticQuery } from 'gatsby';

export const getPlanCards = (plan_id, status) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            section_name: { eq: "current_plan" }
            show: { eq: true }
          }
        }
      ) {
        nodes {
          frontmatter {
            plan_id
            header_subtitle
            header_title
            description
            button
            order
            url
            show
            type
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.nodes
    .map((n) => n.frontmatter)
    .filter((n) => {
      //Here the plan Ids are taken from TINA CMS and compare with backend response to show the plan cards
      const planIdFromCMS = n.plan_id.split(',');
      return planIdFromCMS.includes(plan_id) || planIdFromCMS.includes(`${plan_id}_${status}`)
    });
};
