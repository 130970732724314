import React from 'react';
import styled from 'styled-components';
import { highlightGreen, responsiveWidth } from 'src/styles/constants';

const Topic = styled.div`
  font-size: ${(props) => props.size || '26px'};
  font-weight: ${(props) => props.weight || 'bold'};
  color: ${(props) => props.color || highlightGreen};
  margin-bottom: ${(props) => props.bottom || '5px'};
  width: ${(props) => props.width || '100%'};
  padding: ${(props) => props.padding || '0'};
  box-sizing: border-box;
  letter-spacing: ${(props) => props.letterSpace || 'none'};
  line-height: ${(props) => props.lineHeight || 'inherit'};
  &.underline {
    border-bottom: 2px solid ${(props) => props.underColor};
    display: block;
    width: 200px;
  }

  &.topic-discover {
    color: #ffa303;
  }

  .page-title & {
    margin-bottom: 0;
  }
  @media ${responsiveWidth.mobile} {
    font-size: 22px;
    margin-bottom: ${(props) => props.mobileBottom};
    &.signup-topic {
      font-size: 20px;
    }
    &.challenges-topic {
      font-size: 26px;
      line-height: 30px;
    }
    .underline {
      width: 150px;
    }
  }
`;
export default Topic;
