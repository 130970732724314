import React, { useRef, useState } from 'react';
import SlickCard from 'src/styles/styledComponents/slickCard';
import Card from 'src/styles/styledComponents/card';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import Dot from 'src/styles/styledComponents/dot';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import Slider from 'react-slick';
import DotsWithArrows from 'src/styles/styledComponents2/dotsWithArrows';

const UseCases = () => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "why_sparkpico_use_cases" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              navigation_buttons
              description
              show
              title
              use_case_tabs {
                detailed_description
                description
                template
                title
                image {
                  base
                }
              }
            }
          }
        }
      }
    `),
    { title, use_case_tabs, description, show } =
      data.allMarkdownRemark.nodes[0].frontmatter;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect: true,
    adaptiveHeight: true,
    variableWidth: false,
    centerMode: true,
    centerPadding: '10px',
    beforeChange: (current, next) => setSliderIndex(next),
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };
  const focusTheSlick = (id) => {
    if (id !== sliderIndex) {
      setSliderIndex(id);
    }
  };
  const sliderRef = useRef();

  return (
    show === true && (
      <Card color={'#fbf8f5'}>
        <div className={'container challenges-container-spark'}>
          <Title
            size={'38px'}
            padding={'0 25px'}
            align={'left'}
            marginBottom={'20px'}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Description
            padding={'0 25px'}
            marginBottom={'30px'}
            align={'left'}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className={'slider-container'}>
            <Slider ref={sliderRef} {...settings}>
              {use_case_tabs.map((tab, index) => (
                <div key={index}>
                  <SlickCard
                    type={'why-sparkpico'}
                    className={sliderIndex === index ? 'focussed' : ''}
                    onClick={() => focusTheSlick(index)}
                    id={`id-${index}`}
                  >
                    <img
                      alt={'use-case'}
                      src={
                        require(`../../../static/img/${tab.image?.base}`)
                          .default
                      }
                      width={'100%'}
                    />
                    <Description
                      className={'description'}
                      width={'auto'}
                      top={'20px'}
                      color={'#c5bdbc'}
                      size={'18px'}
                      dangerouslySetInnerHTML={{ __html: tab.description }}
                    />
                  </SlickCard>
                </div>
              ))}
            </Slider>
          </div>
          <div
            className={'arrow-button left'}
            onClick={() => {
              sliderRef.current.slickPrev();
            }}
          >
            <StaticImage
              src={'../../images/arrow-left.png'}
              width={48}
              alt={'arrow'}
            />
          </div>
          <div
            className={'arrow-button right'}
            onClick={() => {
              sliderRef.current.slickNext();
            }}
          >
            <StaticImage
              src={'../../images/arrow-right.png'}
              width={48}
              alt={'arrow'}
            />
          </div>
          <DotsWithArrows>
            <div className={'dot'}>
              {use_case_tabs.map((tab, index) => (
                <Dot
                  className={index === sliderIndex ? 'active' : 'unactive'}
                  key={index}
                  activeColor={'#ff7a68'}
                />
              ))}
            </div>
          </DotsWithArrows>
        </div>
      </Card>
    )
  );
};

export default UseCases;
