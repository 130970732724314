import React from 'react';
import styled from 'styled-components';

const ModalButton = styled.button`
  border-radius: 4px;
  padding: 10px;
  border: none;
  background-color: ${(props) => (props.type === 'ok' ? '#25b8f4' : '#f2f4f9')};
  color: ${(props) => (props.type === 'ok' ? '#fff' : '#000')};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;
export default ModalButton;
