import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import Card from 'src/styles/styledComponents/card';
import SlickCard from 'src/styles/styledComponents/slickCard';
import Title from 'src/styles/styledComponents/title';
import Topic from 'src/styles/styledComponents/topic';
import Description from 'src/styles/styledComponents/description';
import { graphql, useStaticQuery } from 'gatsby';
import Arrow from '../../styles/styledComponents2/arrow';

const Challenges = () => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "challenges" } } }
        ) {
          nodes {
            id
            frontmatter {
              title
              navigation_button
              topic
              challenge_list {
                description
                title
                topic
                dilemma_title
              }
            }
          }
        }
      }
    `),
    { title, topic, challenge_list } =
      data.allMarkdownRemark.nodes[0].frontmatter;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect: true,
    // adaptiveHeight: true,
    variableWidth: false,
    beforeChange: (current, next) => setSliderIndex(next),
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '10px',
        },
      },
    ],
  };
  const focusTheSlick = (id) => {
    if (id !== sliderIndex) {
      setSliderIndex(id);
    }
  };
  const sliderRef = useRef();
  return (
    <Card padding={'100px 0 30px 0'}>
      <div className={'container challenges-container'}>
        <Topic
          size={'35px'}
          className={'challenges-topic'}
          lineHeight={'41px'}
          color={'#FFA303'}
          dangerouslySetInnerHTML={{ __html: topic }}
        />
        <Title
          lineHeight={'52px'}
          className={'challenges-title'}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className={'slider-container'}>
          <Slider ref={sliderRef} {...settings}>
            {challenge_list.map((challenge, index) => (
              <div key={index} style={{ width: '350px' }}>
                <SlickCard
                  className={sliderIndex === index ? 'focussed' : ''}
                  onClick={() => focusTheSlick(index)}
                  id={`id-${index}`}
                >
                  <div className={'challenges-card'}>
                    <Title
                      size={'30px'}
                      lineHeight={'35px'}
                      className={'challenge-card-title'}
                      padding={'0 0 7px !important'}
                      marginBottom={'0'}
                      dangerouslySetInnerHTML={{
                        __html: challenge.dilemma_title,
                      }}
                    />
                    <Description
                      size={'30px'}
                      className={'challenge-card-description'}
                      lineHeight={'35px'}
                      marginBottom={'20px'}
                      color={'inherit'}
                      dangerouslySetInnerHTML={{ __html: challenge.title }}
                    />
                    <Arrow
                      className={sliderIndex === index ? 'arrow-focussed' : ''}
                      rotate={'rotate(-180deg)'}
                    />
                  </div>
                  <div className={'additional-info'}>
                    <Description
                      size={'30px'}
                      weight={'bold'}
                      lineHeight={'35px'}
                      className={'challenge-card-description'}
                      color={'#FFA303'}
                      dangerouslySetInnerHTML={{ __html: challenge.topic }}
                    />
                    <Description
                      size={'18px'}
                      lineHeight={'27px'}
                      color={'#515459'}
                      mobileLine={'21px'}
                      dangerouslySetInnerHTML={{
                        __html: challenge.description,
                      }}
                    />
                  </div>
                </SlickCard>
              </div>
            ))}
          </Slider>
        </div>
        <div className={'slick-arrows'}>
          <div
            className={'arrow-button'}
            onClick={() => {
              sliderRef.current.slickPrev();
            }}
          >
            <img
              src={require('../../images/arrow-left-blue.png').default}
              width={48}
              height={48}
              alt={'arrow'}
            />
          </div>
          <div
            className={'arrow-button'}
            onClick={() => {
              sliderRef.current.slickNext();
            }}
          >
            <img
              src={require('../../images/arrow-right-blue.png').default}
              width={48}
              height={48}
              alt={'arrow'}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Challenges;
