import styled from 'styled-components';

const DotsWithArrows = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  .arrow {
    cursor: pointer;
  }
`;
export default DotsWithArrows;
