export const getFrontmatter = (data) =>
  data.allMarkdownRemark.nodes[0].frontmatter;

export const getFrontmatters = (data) => {
  if (
    !data.allMarkdownRemark.nodes ||
    data.allMarkdownRemark.nodes.length < 1
  ) {
    return false;
  }
  data.allMarkdownRemark.nodes[0].frontmatter;
};

export const getFrontmatterOf = (data, key) => data[key].nodes[0].frontmatter;

export const kebabCase = (str) =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .join('-')
    .toLowerCase();

export const generateRandomId = () => {
  const randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
  const uniqid = randLetter + Date.now();
  return uniqid;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getCookie = (cname) => {
  let name = `${cname}=`;
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const navigateToNewTab = (url) => {
  window.open(url, '_blank');
};

export const isMobileDevice = (platform) => {
  const userAgent = navigator.userAgent,
    mobilePatterns = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

  return ['ios','android'].includes(platform) || mobilePatterns.some((pattern) => pattern.test(userAgent));
};