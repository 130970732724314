/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import {
  PlanCardContainer,
  BuyPlan,
  Container,
  Wrapper,
} from '../../styles/components/plan/currentPlan';
import { getPlanCards } from '../../queries/graphql/plans/cards';
import { getPlanCardsANAAlert } from '../../queries/graphql/plans/ana_alert';
import { getWarningPopUp } from '../../queries/graphql/plans/warning_popup';
import useFetchData from './fetchPlan.js';
import moment from 'moment';
import CustomModal from './customModal';
import { Loader } from '../preloader/loader';

const PlanCard = (currentPlan = null) => {
  const [loading, setLoading] = useState(false),
    [showAlert, setShowAlert] = useState(false),
    [planType,setPlanType] = useState(null);

  let headless = typeof window !== 'undefined' &&
    window.location.search.includes('?headless'),
    platform =
      typeof window !== 'undefined' &&
        window.location.search.includes('platform=')
        ? new URL(window.location.href).searchParams.get('platform')
        : false,
    access_token =
      typeof window !== 'undefined' &&
        window.location.search.includes('access_token=')
        ? new URL(window.location.href).searchParams.get('access_token')
        : false,
    plans = [],
    plan_id = null,
    modal = null,
    warning = null,
    purchased_from = null;

  if (headless && access_token) {
    currentPlan = currentPlan?.currentPlan;
    plan_id = currentPlan?.plan_id;
  } else {
    // else if there is one cookie about the plan
    // ToDo: get plan_id from cookie
  }

  if (typeof plan_id === 'string') {
    plans = getPlanCards(plan_id, currentPlan?.current_plan_status?.toLowerCase());
    purchased_from = currentPlan?.purchased_from;
    modal = getPlanCardsANAAlert(purchased_from);
    warning = getWarningPopUp(currentPlan?.current_plan_status?.toLowerCase());
  }

  const planStatusBadge = (plan) => {
    let badgeContainerClass = '';
    const currentPlanStatus = currentPlan?.current_plan_status;
    if( currentPlanStatus === null || plan?.header_subtitle === 'Payment Information' || currentPlanStatus === ' ') {
      return null;
    } else if (currentPlanStatus === 'Cancelled') {
      badgeContainerClass = 'cancel_status';
    } else if (currentPlanStatus === 'Active' || currentPlanStatus === 'In Trial') {
      badgeContainerClass = 'active_status';
    } else if (currentPlanStatus === 'Changed' || currentPlan.current_plan_id.includes('trial')) {
      badgeContainerClass = 'active_status';
    }
    
    return (<p className={`${badgeContainerClass} _status`}>{currentPlan?.current_plan_status}</p>);
  }

  return (
    plans &&
    plans.sort((planA, planB) => (parseInt(planA.order) - parseInt(planB.order))).map((plan, index) => {
      let description = plan.description
          .replaceAll('{{build-date}}', moment(currentPlan?.next_billing_date).format('MMM DD YYYY'))
          .replaceAll('{{days-left}}', moment(currentPlan?.next_billing_date).format('MMM DD YYYY'))
          .replaceAll('{{renewal_price}}', currentPlan?.plan_renewal_price)
          .replaceAll('{{renewal_price_duration}}', currentPlan?.current_plan_duration?.split(' ')[1])
          .replaceAll('{{purchased_price}}', currentPlan?.current_plan_purchased_price)
          .replaceAll('{{plan_duration}}', currentPlan?.current_plan_duration)
          .replaceAll('{{expiry-date}}', moment(currentPlan?.plan_expired_on).format('MMM DD YYYY'))
          .replaceAll('{{scheduled_subscription_info.plan_id}}', (currentPlan?.scheduled_subscription_info?.plan_id)?.split('_')[1])
          .replaceAll('{{scheduled_subscription_info.plan_price}}', currentPlan?.scheduled_subscription_info?.plan_price),
        url = plan.url.replaceAll('{{plan_id}}', currentPlan?.plan_id);

      return (
        plan.show && (
          <div key={index}>
            {headless === true && access_token != false && platform != false ? (
              <PlanCardContainer >
                <div className='plan-card' />
                <BuyPlan>
                  <Container>
                    <Wrapper>
                      <div className='container'>
                        <p className='header_subtitle'>
                          {plan.header_subtitle}
                        </p>
                        <h1 className='header_title'>{plan?.header_title}</h1>
                        {planStatusBadge(plan)}
                        {plan.description === null ||
                        plan.description == ' ' ||
                        description == '' ? (
                          false
                        ) : (
                          <div
                            className='description' dangerouslySetInnerHTML={{ __html: description }}
                          />
                        )}
                        {plan.button === '' ? (
                          false
                        ) : (platform.toLocaleLowerCase() != purchased_from.toLocaleLowerCase()) ? (
                          <>
                            <button className='button' onClick={() => setShowAlert(true)}>
                              {plan.button}
                            </button>
                            {modal &&
                              <CustomModal
                                visible={showAlert} setVisible={setShowAlert}
                                data={{
                                  title: modal.title,
                                  content: modal.description,
                                  button: modal.button,
                                }}
                                type={plan.button === 'cancel plan' ? 'cancelSubscription' : 'removeCancellation'}
                              />
                            }
                          </>
                        ) : platform.toLocaleLowerCase() == purchased_from.toLocaleLowerCase() &&
                        platform.toLocaleLowerCase() === 'web' ? (
                          <>
                            <button
                              className="button"
                              onClick={() => {
                                { plan.button.toLocaleLowerCase() === 'manage payment' ?
                                  (window.parent.postMessage(
                                    {
                                      'url': 'https://billing.sparkpico.com?action=manage_subscription',
                                      'type': 'manage_subscription',
                                    },
                                    '*')) :
                                  setShowAlert(true)
                                }
                                setPlanType(plan.type);
                              }}
                            >
                              {plan.button}
                            </button>
                            {warning &&
                            <CustomModal
                              onClick={() => {
                                setTimeout(function () {
                                  window.parent.postMessage(
                                    {
                                      'type': `${plan.type}`,
                                      'planId': `${plan.plan_id}`,
                                    }, '*');
                                }, 500)}
                              }
                              visible={showAlert} setVisible={setShowAlert}
                              data={{
                                title: warning.title,
                                content: warning.description.replaceAll('{{:end_of_term_date}}', moment(currentPlan?.next_billing_date).format('MMM DD YYYY')),
                                ok_button: warning.ok_button,
                                cancel_button: warning.cancel_button,
                              }}
                              url={warning.url}
                              type={planType}
                            />
                            }
                            {loading ? <Loader /> : null}
                          </>
                        ) : platform.toLocaleLowerCase() == purchased_from.toLocaleLowerCase() && plan.button.toLocaleLowerCase() === 'cancel plan' || plan.button.toLocaleLowerCase() === 'remove cancellation' ? (
                          <>
                            <button
                              className="button"
                              onClick={() => {
                                setShowAlert(true);
                              }}
                            >
                              {plan.button}
                            </button>
                            {warning &&
                            <CustomModal
                              onClick={() =>
                                setTimeout(function () {
                                  window.location.href = url;
                                }, 500)
                              }
                              visible={showAlert} setVisible={setShowAlert}
                              data={{
                                title: warning.title,
                                content: warning.description.replaceAll('{{:end_of_term_date}}', moment(currentPlan?.next_billing_date).format('MMM DD YYYY')),
                                  ok_button: warning.ok_button,
                                  cancel_button: warning.cancel_button,
                                }}
                                type={plan.button === 'cancel plan' ? 'cancelSubscription' : 'removeCancellation'}
                                url={warning.url}
                              />
                            }
                            {loading ? <Loader /> : null}
                          </>

                        ) : platform.toLocaleLowerCase() == purchased_from.toLocaleLowerCase() && (
                          plan.button != null &&
                          <>
                            <button
                              className="button"
                              onClick={() => {
                                setLoading(true);
                                setTimeout(function () {
                                  window.location.href = url;
                                }, 600);
                              }}>
                              {plan.button}
                            </button>
                            {loading ? <Loader /> : null}
                          </>
                        )}
                      </div>
                    </Wrapper>
                  </Container>
                </BuyPlan>
              </PlanCardContainer>
            ) : (
              false
            )
            }
          </div>
        )
      );
    })
  );
};

export default PlanCard;
