import React, { useEffect, useState } from 'react';
import { getFrontmatter } from 'src/utility/helpers';
import PageHelmet from 'src/components/pageHelmet';
import { graphql, useStaticQuery } from 'gatsby';
import {
  GlobalStyle,
  GlobalStyleHome,
  GlobalStyleProductWebClipper,
  GlobalStyleV2,
} from 'src/styles/globalStyles';
import Header from 'src/components/header';
import Footer from 'src/components/footer';
import HeroWebClipper from 'src/components/products/heroWebClipper';
import OtherProductSection from 'src/components/products/otherProductSection';
import ProductFaq from 'src/components/products/productFaq';
import InfoSection from 'src/components/products/infoSection';
import WebClipperFeatures from 'src/components/products/webClipperFeatures';

import { InitialLoader } from '../components/preloader/initialLoader';
import Stores from '../components/home/stores';

const ProductWebClipper = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              section_name: { eq: "products_webclipper_page_detail" }
            }
          }
        ) {
          nodes {
            frontmatter {
              title
              description
            }
          }
        }
      }
    `),
    { title, description } = getFrontmatter(data),
    [loader, setLoader] = useState(true);

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 900);
  }, []);

  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description} />
      <GlobalStyle />
      <GlobalStyleHome />
      <GlobalStyleV2 />
      <GlobalStyleProductWebClipper />
      <Header />
      <HeroWebClipper />
      <WebClipperFeatures />
      <InfoSection of={'webClipper'} />
      <OtherProductSection of={'webClipper'} />
      <ProductFaq of={'webClipper'} />
      <Stores />
      <Footer />
    </>
  );
};

export default ProductWebClipper;
