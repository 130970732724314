import React from 'react';
import styled from 'styled-components';
import Tabs from 'rc-tabs';
import { responsiveWidth } from 'src/styles/constants';
import discover from '../../images/discover-icon.svg';

const HorizontalTabs = styled(Tabs)`
  .rc-tabs-nav-list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .rc-tabs-tab {
    position: relative;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 15px;
    width: 200px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    color: #c4c4c4;
    &:after {
      content: '';
      height: 50%;
      width: 1px;
      position: absolute;
      right: 0;
      top: 12px;
      background-color: #c4c4c4;
    }
    &:nth-child(5)::after {
      display: none;
    }
    &.rc-tabs-tab-active {
      background-color: ${(props) => props.color || cardBackground};
      color: #ffffff;
    }
  }
  .rc-tabs-ink-bar {
    display: none;
  }
  .title {
    font-size: 45px;
    color: #2c3038  ;
    font-weight: bold;
  }
  .tab-content {
    background-color: 
    ${(props) => props.active};
    padding: 35px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    outline: none;
    animation: fadein 2s;

      @keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      .nav_button_link {
        text-decoration: none;
      }
    }

    .tab-head {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
    }

    .media-container {
      img {
        width: 100%;
      }
    }
   .navigation-link {
       margin-top: 15px;
       font-size: 26px;
       font-weight: 600;
     a {
       color: #FFA303;
     }
    }
    @media (max-width: 920px) {
      .tab-content {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    @media ${responsiveWidth.mobile} {
      .navigation-link {
        margin-top: 10px;
        font-size: 20px;
      }
      .rc-tabs-tab {
        font-size: 10px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:after {
         top: 5px;
        }
        .tab-head {
          flex-direction: column;

          span {
            margin-left: 0;
            font-size: 12px;
          }
        }
      }
      .title {
        font-size: 26px;
      }
      .tab-content {
        display: grid;
        grid-template-columns: 1fr;
        text-align: center;

        & > div:nth-child(1) {
          order: 0;
        }

        & > div:nth-child(2) {
          order: 1;
        }

        .typing-text {
          font-size: 32px;
          margin-bottom: 25px;
        }

        .gray-title {
          margin-top: 20px;
          font-size: 16px;
        }
      }
    }
  }
`;
export default HorizontalTabs;
