import React, { useLayoutEffect, useState } from 'react';
import { generateRandomId, validateEmail } from 'src/utility/helpers';
import FormWrapper from 'src/styles/styledComponents/formWrapper';
import Card from 'src/styles/styledComponents/card';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import FormButton from 'src/styles/styledComponents/formButton';
import InputSpark from 'src/components/contact/inputSpark';
import { graphql, useStaticQuery } from 'gatsby';

const WhySparkpicoForm = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "why_sparkpico_contact" } }
          }
        ) {
          nodes {
            frontmatter {
              title
              description
              placeholder
              info
              show
              button_text
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `),
    { title, description, placeholder, info, button_text, show } =
      data.allMarkdownRemark.nodes[0].frontmatter,
    [formValues, setFormValues] = useState({ email: null }),
    [isDisabled, setDisable] = useState(true),
    handleSubmit = (e) => {
      e.preventDefault();
      window.analytics.identify(generateRandomId(), formValues);
      window.analytics.track('Contact submitted', {
        ...formValues,
      });
    },
    handleFieldChange = (e) => {
      setFormValues({ ...formValues, [e.target.id]: e.target.value });
    };
  useLayoutEffect(() => {
    if (validateEmail(formValues.email)) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [formValues]);

  return (
    show == true && (
      <Card align={'center'}>
        <div className={'container'}>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Description dangerouslySetInnerHTML={{ __html: description }} />
          <FormWrapper>
            <div className={'why-sparkpico'}>
              <InputSpark
                className={'spark-input'}
                label={placeholder}
                id={'email'}
                onChange={(e) => handleFieldChange(e)}
              />
              <span className={'mobile-actions'}>
                <FormButton
                  className={'signup-button'}
                  onClick={(e) => handleSubmit(e)}
                >
                  {button_text}
                </FormButton>
                <Description
                  className={'info'}
                  width={'auto'}
                  color={'#70757f'}
                  weight={'bold'}
                  size={'18px'}
                  dangerouslySetInnerHTML={{ __html: info }}
                />
              </span>
            </div>
          </FormWrapper>
        </div>
      </Card>
    )
  );
};

export default WhySparkpicoForm;
