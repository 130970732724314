import React from 'react';
import styled from 'styled-components';
import { breakPoints } from '../../constants';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 36px 0;
  text-align: center;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 34px;
    margin-bottom: 21px;
  }

  button {
    // box-shadow: none;
    font-size: 26px;
    padding: 11px 37px;

    .plans_contact-us_link {
      text-decoration: none;
      color: white;
      font-weight: 600;
      cursor: pointer;
      letter-spacing: -0.5px;
      transition: background-color 0.15s ease 0s;
    }
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    padding: 98px 0 87px 0;

    h1 {
      font-size: 45px;
      margin-bottom: 26px;
    }

    button {
      padding: 18px 60px;
      .plans_contact-us_link {
        font-size: 30px;
      }
    }
  }

  @media (min-width: ${breakPoints.pc}) {
    padding: 127px 0 100px 0;

    h1 {
      font-size: 45px;
      margin-bottom: 33px;
    }

    button {
      padding: 22px 72px;
      .plans_contact-us_link {
        font-size: 36px;
      }
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    padding: 98px 0 65px 0;

    h1 {
      font-size: 45px;
      margin-bottom: 38px;
    }

    button {
      padding: 22px 80px;
      .plans_contact-us_link {
        font-size: 36px;
      }
    }
  }
`;
export default Container;
