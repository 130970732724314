import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const ScrollspyGridFaq = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  &:not(:first-child) {
    margin-top: 100px;
  }
  .content {
    & > div:not(:last-child) {
      margin-bottom: 120px;
    }
  }
  @media ${responsiveWidth.mobile} {
    grid-template-columns: 1fr;
    margin-top: 40px;
    .content {
      order: 1;
      & > div:not(:last-child) {
        margin-bottom: 50px;
      }
    }
  }
`;
export default ScrollspyGridFaq;
