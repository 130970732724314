import React from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div`
  max-width: ${(props) => props.maxWidth || '700px'};
  width: ${(props) => props.width || '100%'};
  border-radius: 10px;
  border: 5px solid #fb6357;
`;
export default VideoWrapper;
