import React, { useEffect, useState } from 'react';
import Card from 'src/styles/styledComponents/card';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery, Link } from 'gatsby';
import CookieBanner from 'src/components/cookieBanner';
// import ProductsPopup from './productsPopup';
import Trigger from 'rc-trigger';
import PopupCard from '../styles/styledComponents2/popupCard';
require('focus-visible');

const Footer = ({ hideBanner }) => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "footer" } } }
        ) {
          nodes {
            frontmatter {
              address
              more {
                about_sparkpico {
                  link
                  text
                  active
                }
                contact_us {
                  link
                  text
                  active
                }
                contributors {
                  link
                  text
                  active
                }
                cookie_policy {
                  link
                  text
                  active
                }
                cookie_preferences {
                  link
                  text
                  active
                }
                privacy_policy {
                  link
                  text
                  active
                }
                terms_of_use {
                  link
                  text
                  active
                }
                title
              }
              quick_links {
                download_app {
                  link
                  text
                  active
                }
                sign_in_sign_up {
                  link
                  text
                  active
                }
                why_spark_pico {
                  link
                  text
                  active
                }
                title
              }
              recent_posts {
                post_1 {
                  link
                  text
                }
                post_2 {
                  link
                  text
                }
                post_3 {
                  link
                  text
                }
                title
                active
              }
              visit_us {
                test_article__1 {
                  icon {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                  link
                  text
                  code {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                }
                test_article__2 {
                  icon {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                  link
                  text
                  code {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                }
                test_article__3 {
                  icon {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                  link
                  text
                  code {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                }
                title
              }
              reading_room {
                test_article__1 {
                  active
                  link
                  text
                }
                test_article__2 {
                  active
                  link
                  text
                }
                test_article__3 {
                  active
                  link
                  text
                }
                title
              }
              copyright_text
            }
            id
          }
        }
      }
    `),
    {
      address,
      more,
      quick_links,
      recent_posts,
      reading_room,
      visit_us,
      copyright_text,
    } = data.allMarkdownRemark.nodes[0].frontmatter,
    moreList = Object.keys(more).filter((key) => key !== 'title'),
    quickLinksList = Object.keys(quick_links).filter((key) => key !== 'title'),
    recentPostList = Object.keys(recent_posts).filter((key) => key !== 'title'),
    readingRoomList = Object.keys(reading_room).filter(
      (key) => key !== 'title'
    ),
    visitUsList = Object.keys(visit_us).filter((key) => key !== 'title'),
    [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function reportWindowSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, []);
  return (
    <>
      <Card className={'footer'}>
        <div className={'container footer-container'}>
          <div>
            <StaticImage
              className={'head'}
              src={'../images/logo-bright.png'}
              alt={'logo'}
              height={40}
            />
            <div className={'list'}>
              {address.map((addressLine, index) => (
                <div key={index}>{addressLine}</div>
              ))}
            </div>
          </div>
          {recent_posts.active == true && (
            <div>
              <div className={'head'}>{recent_posts.title}</div>
              <div className={'list'}>
                {recentPostList.map((item, index) => (
                  <div key={index}>
                    {recent_posts[item].text !== undefined && (
                      <Link to={recent_posts[item].link}>
                        {recent_posts[item].text}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div>
            <div className={'head'}>{quick_links.title}</div>
            <div className={'list'}>
              {quickLinksList.map(
                (item, index) =>
                  quick_links[item].active == true && (
                    <div key={index}>
                      {/* <Link to={quick_links[item].link}>
                        {quick_links[item].text}
                      </Link> */}
                      <a href={quick_links[item].link}>
                        {quick_links[item].text}
                      </a>
                    </div>
                  )
              )}
            </div>
          </div>
          <div>
            <div className={'head'}>{reading_room.title}</div>
            <div className={'list'}>
              {readingRoomList.map(
                (item, index) =>
                  reading_room[item].text !== undefined && (
                    <div key={index}>
                      <a href={reading_room[item].link}>
                        {reading_room[item].text}
                      </a>
                    </div>
                  )
              )}
            </div>
          </div>
          <div>
            <div className={'head'}>{more.title}</div>
            <div className={'list'}>
              {moreList.map(
                (item, index) =>
                  more[item].active == true && (
                    <div key={index}>
                      <Link to={more[item].link}>{more[item].text}</Link>
                    </div>
                  )
              )}
            </div>
          </div>
          <div>
            <div className={'head'}>{visit_us.title}</div>
            {windowWidth > 768 ? (
              <div className={'visit-list'}>
                {visitUsList.map(
                  (item, index) =>
                    visit_us[item].text !== undefined && (
                      <span key={index} className={'visit-icons'}>
                        <a href={visit_us[item].link}>
                          <img
                            src={`${visit_us[item].icon?.childImageSharp.fluid.src}`}
                            alt={'icon'}
                            width={32}
                            height={32}
                          />
                        </a>
                        <Trigger
                          action={['hover']}
                          popup={
                            <PopupCard padding={'5px'} border={'16px'}>
                              <img
                                src={`${visit_us[item].code?.childImageSharp.fluid.src}`}
                                alt={'icon'}
                                width={140}
                                height={140}
                              />
                            </PopupCard>
                          }
                          popupAlign={{
                            points: ['bc', 'tc'],
                            offset: [0, -10],
                          }}
                        >
                          <span className={'qr-code'}>
                            <img
                              src={
                                require('../../static/img/qr-code.png').default
                              }
                              width={32}
                              alt={'icon'}
                            />
                            <img
                              className={'qr-hover'}
                              src={
                                require('../../static/img/qr-code-hover.png')
                                  .default
                              }
                              width={32}
                              alt={'icon'}
                            />
                          </span>
                        </Trigger>
                      </span>
                    )
                )}
              </div>
            ) : (
              <div className={'visit-list'}>
                {visitUsList.map(
                  (item, index) =>
                    visit_us[item].text !== undefined && (
                      <span key={index}>
                        <a href={visit_us[item].link}>
                          <img
                            src={`${visit_us[item].icon?.childImageSharp.fluid.src}`}
                            alt={'icon'}
                            width={24}
                            height={24}
                          />
                        </a>
                      </span>
                    )
                )}
              </div>
            )}
          </div>
        </div>
        <div className={'copyright'}>{copyright_text}</div>
      </Card>
      <CookieBanner hideBanner={hideBanner} />
    </>
  );
};

export default Footer;
