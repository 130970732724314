import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const Title = styled.h1`
  font-size: ${(props) => props.size || '45px'};
  font-weight: ${(props) => props.weight || 'bold'};
  margin: 0 0 ${(props) => props.marginBottom || '30px'};
  margin-top: ${(props) => props.top || '0px'};
  width: ${(props) => props.width || '100%'};
  color: ${(props) => props.color || '#2c3038'};
  text-align: ${(props) => props.align || 'inherit'};
  padding: ${(props) => props.padding || '0'};
  box-sizing: border-box;
  letter-spacing: ${(props) => props.letterSpace || '1px'};
  line-height: ${(props) => props.lineHeight || 'inherit'};
  &.title-discover {
    text-align: center;
    color: #2c3038;
  }
  &.collect,
  &.store,
  &.organize,
  &.create-sparks,
  &.share {
    padding-bottom: 10px !important;
  }
  &.whyspark-title {
    padding: 0 0 22px !important;
    line-height: 43px;
  }
  &.underLine {
    display: inline;
    box-shadow: #ffe4c1 0px -50px 3px -32px inset;
  }
  &.web-contact {
    em {
      color: #fb6357;
    }
  }

  @media ${responsiveWidth.mobile} {
    font-size: 26px;
    width: 100%;
    margin-bottom: 10px;
    letter-spacing: -1px;
    // letter-spacing: ${(props) => (props.letterSpace ? '0' : '-2px')};
    &.title {
      font-size: 30px;
      line-height: 35px;
      padding-bottom: 5px !important;
    }
    &.signup-title {
      margin-top: 15px !important;
    }
    &.challenges-title {
      font-size: 26px;
      line-height: 30px;
    }
    &.challenge-card-title {
      font-size: 22px;
      line-height: 25px;
    }
    &.underLine {
      box-shadow: #ffe4c1 0px -50px 3px -40px inset;
    }
    &.collect-title {
      text-align: left !important;
    }
    &.whyspark-title {
      font-size: 22px;
      padding: 0 0 10px !important;
      line-height: 26px;
    }
  }
`;
export default Title;
