import React from 'react';
import Topic from 'src/styles/styledComponents/topic';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';

const PageTitle = ({ title, background, info, color }) => (
  <Card
    color={color}
    background={background && background.childImageSharp.fluid.src}
    className={'page-title'}
  >
    <Title
      marginBottom={'15px'}
      weight={'600'}
      dangerouslySetInnerHTML={{ __html: title }}
    />
    <Topic
      color={'#2c3038'}
      size={'22px'}
      weight={'100'}
      letterSpace={'-1px'}
      dangerouslySetInnerHTML={{ __html: info }}
    />
  </Card>
);

export default PageTitle;
