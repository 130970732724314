import React, { useEffect, useState } from 'react';
import { getFrontmatterOf } from 'src/utility/helpers';
import PageHelmet from 'src/components/pageHelmet';
import {
  GlobalStyle,
  GlobalStyleHome,
  GlobalStyleProductWeb,
  GlobalStyleV2,
} from 'src/styles/globalStyles';
import { graphql, useStaticQuery } from 'gatsby';
import Footer from 'src/components/footer';
import Header from 'src/components/header';
import CoreFeature from 'src/components/products/coreFeatures';
import Video from 'src/components/products/video';
import UseCases from 'src/components/products/useCases';
import CombinedHeroWeb from 'src/components/products/combinedHero';
import InfoSection from 'src/components/products/infoSection';
import OtherProductSection from 'src/components/products/otherProductSection';
import ProductFaq from 'src/components/products/productFaq';
import { InitialLoader } from '../components/preloader/initialLoader';
import Stores from '../components/home/stores';
import CallToAction from '../components/home/callToAction';
import ProductWebContact from '../components/products/productWebContact';

const ProductsWeb = () => {
  const data = useStaticQuery(graphql`
      query {
        page: allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "products_web_page" } } }
        ) {
          nodes {
            frontmatter {
              title
              description
            }
          }
        }
      }
    `),
    { title, description } = getFrontmatterOf(data, 'page'),
    [loader, setLoader] = useState(true);

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);
  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description}>
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </PageHelmet>
      <GlobalStyle />
      <GlobalStyleHome />
      <GlobalStyleV2 />
      <GlobalStyleProductWeb />
      <Header color={'#fbf8f5'} />
      <CombinedHeroWeb />
      <CoreFeature />
      <ProductWebContact />
      <UseCases />
      <InfoSection of={'web'} />
      <CallToAction />
      <Video />
      <OtherProductSection of={'web'} />
      <ProductFaq of={'web'} />
      <Stores />
      <Footer />
    </>
  );
};

export default ProductsWeb;
