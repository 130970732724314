import React from 'react';
import styled from 'styled-components';
import { breakPoints } from '../../constants';

export const Container = styled.div`
  padding-top: 0 !important;
  background: #ffffff;
`;

export const ContainerIntro = styled.div`
  margin-top: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fbf8f5;

  h1 {
    font-family: 'AvertaStd-Semibold';
    color: #2c3038;
    font-size: 36px;
    line-height: 42px;
    margin: 0;
    margin-bottom: 20px;
  }

  p {
    font-family: 'AvertaStd-Regular';
    margin: 0;
    color: #2c3038;
    font-size: 18px;
    line-height: 21px;
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    padding: 40px 0;
  }

  @media (max-width: ${breakPoints.pcLarge}) {
    padding: 40px 0;
    position: relative;
    top: -11px;
  }

  @media (max-width: ${breakPoints.tablet}) {
    padding: 30px 0;
    h1 {
      font-size: 45px;
    }

    p {
      font-size: 23px;
    }
  }
  @media (max-width: ${breakPoints.mobileLarge}) {
    h1 {
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 5px;
    }

    p {
      font-size: 12px;
      line-height: 14px;
    }
  }

  @media (max-width: ${breakPoints.mobileSmall}) {
    h1 {
      font-size: 16px;
      line-height: 19px;
    }

    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const ContentWrapper = styled.div`
  margin: auto;

  h2 {
    font-family: 'AvertaStd-Semibold';
    color: #2c3038;
    margin: 0;
  }
  p {
    font-family: 'AvertaStd-Regular';
    color: #2c3038;
    margin: 0;
  }

  & > span:last-child {
    & > :last-child {
      margin-bottom: 30px !important;
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    max-width: 1680px;
    padding: 47px 0;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 32px;
      margin-bottom: 20px;
    }

    p {
      font-size: 20px;
      margin-bottom: 23px;
    }
  }
  @media (max-width: ${breakPoints.pcLarge}) {
    max-width: 1200px;
    padding: 47px 0;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 32px;
      margin-bottom: 20px;
    }

    p {
      font-size: 20px;
      margin-bottom: 23px;
    }
  }
  @media (max-width: ${breakPoints.pc}) {
    max-width: 868px;
    padding: 47px 0;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 32px;
      margin-bottom: 21px;
    }
    p {
      font-size: 20px;
      margin-bottom: 34px;
    }
  }
  @media (max-width: ${breakPoints.pcSmall}) {
    max-width: 750px;
    padding: 47px 0;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 32px;
      margin-bottom: 21px;
    }
    p {
      font-size: 20px;
      margin-bottom: 34px;
    }
  }
  @media (max-width: ${breakPoints.tablet}) {
    max-width: 670px;
    padding: 25px 0;
    margin: 0 30px;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 18px;
      line-height: 21px;
    }
    p {
      font-size: 14px;
      line-height: 16px;
    }
  }
  @media (max-width: ${breakPoints.mobileLarge}) {
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: 'AvertaStd-Bold';
      margin-bottom: 15px;
      margin-top: 34px;
    }
  }
  @media (max-width: ${breakPoints.mobileSmall}) {
    max-width: 250px;
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 16px;
      line-height: 19px;
      margin-top: 30px;
      margin-bottom: 15px;
    }
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const ProccesesTable = styled.div`
  margin: auto;

  @media (min-width: ${breakPoints.pcLarge}) {
    max-width: 1055px;
    margin-bottom: 74px;
    padding-top: 73px;
  }
  @media (max-width: ${breakPoints.pcLarge}) {
    max-width: 1120px;
    margin-bottom: 54px;
    margin-top: 70px;
  }
  @media (max-width: ${breakPoints.pcSmall}) {
    margin-top: 20px;
    margin-bottom: 50px;
  }
`;

export const TableHead = styled.ul`
  display: grid;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fbf8f5;
  border: 1px solid #77716b;
  grid-template-columns: repeat(4, 1fr);

  li {
    border-right: 1px solid #77716b;
    padding: 15px 10px;
    text-transform: capitalize;
    font-family: 'AvertaStd-Bold';

    &:last-child {
      border-right: none;
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    li {
      font-size: 20px;
      line-height: 23px;
    }
  }
  @media (max-width: ${breakPoints.pcLarge}) {
    li {
      font-size: 20px;
      line-height: 23px;
    }
  }
  @media (max-width: ${breakPoints.pc}) {
    li {
      font-size: 22px;
    }
  }
  @media (max-width: ${breakPoints.pcSmall}) {
    li {
      font-size: 20px;
      line-height: 23px;
    }
  }
  @media (max-width: ${breakPoints.tablet}) {
    li {
      font-size: 16px;
    }
  }
  @media (max-width: ${breakPoints.mobileLarge}) {
    border: none;
    background: #ffffff;
    margin-left: -7px;
    li {
      border: none;
      padding-bottom: 9px;
      font-size: 12px;
      line-height: 14px;
      font-family: 'AvertaStd-Regular';
    }
  }
  @media (max-width: ${breakPoints.mobileSmall}) {
    li {
      font-size: 11px;
    }
  }
`;

export const TableBody = styled.div``;

export const TableRow = styled.ul`
  display: grid;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #77716b;
  border-top: none;
  grid-template-columns: repeat(4, 1fr);

  li {
    border-right: 1px solid #77716b;
    font-family: 'AvertaStd-Regular';

    &:last-child {
      border-right: none;
    }
    a {
      text-decoration: none;
      color: #000;
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    li {
      font-size: 20px;
      line-height: 23px;
      padding: 20px 16px;
    }
  }
  @media (max-width: ${breakPoints.pcLarge}) {
    li {
      font-size: 20px;
      line-height: 23px;
      padding: 20px 16px;
    }
  }
  @media (max-width: ${breakPoints.pc}) {
    li {
      font-size: 20px;
      line-height: 23px;
      padding: 20px 10px;
    }
  }
  @media (max-width: ${breakPoints.pcSmall}) {
    li {
      font-size: 17px;
      line-height: 23px;
      padding: 20px 10px;
    }
  }

  @media (max-width: ${breakPoints.tablet}) {
    li {
      font-size: 13px;
      padding: 20px 10px;
    }
  }
  @media (max-width: ${breakPoints.mobileLarge}) {
    border: none;
    background: #ffffff;
    border-top: 2px solid #dfdfdf;
    li {
      border: none;
      font-size: 12px;
      line-height: 14px;
      padding: 8px;
      font-family: 'AvertaStd-Regular';
    }
  }
  @media (max-width: ${breakPoints.mobileSmall}) {
    li {
      border: none;
      font-size: 9px;
      padding: 9px 1px;

      &:nth-child(2) {
        padding-left: 40%;
      }
      &:last-child {
        padding-left: 40%;
      }
    }
  }
`;
