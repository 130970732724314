import React from 'react';
import StyledModal from 'src/styles/styledComponents/styledModal';
import Backdrop from 'src/styles/styledComponents/backDrop';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import VideoApp from 'src/components/home/videoApp';
import VideoWrapper from 'src/styles/styledComponents/videoWrapper';

const videoModal = ({ visible, setVisible }) => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "video" } } }
        ) {
          nodes {
            frontmatter {
              video_link
            }
            id
          }
        }
      }
    `),
    renderBackdrop = (props) => <Backdrop {...props} />,
    { video_link } = getFrontmatter(data);

  return (
    <StyledModal
      show={visible}
      onHide={setVisible}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
      width={'700px'}
    >
      <VideoWrapper>
        <VideoApp autoplay={true} playsInline={true} src={video_link} />
      </VideoWrapper>
    </StyledModal>
  );
};

export default videoModal;
