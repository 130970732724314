import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PolicyContent from 'src/components/policyPages/policyContent';
import Card from 'src/styles/styledComponents/card';
import ScrollspyGrid from 'src/styles/styledComponents/scrollspyGrid';
import { kebabCase } from 'src/utility/helpers';
import ScrollspyNav from 'react-scrollspy-nav';

const PolicyLayout = ({ sectionName }) => {
  const data = useStaticQuery(graphql`
    query {
      terms: allMarkdownRemark(
        filter: { frontmatter: { page_name: { eq: "terms_of_use_section" } } }
      ) {
        edges {
          node {
            html
            frontmatter {
              title
              section_name
              position
            }
          }
        }
      }
      privacy: allMarkdownRemark(
        filter: { frontmatter: { page_name: { eq: "privacy_sections" } } }
      ) {
        edges {
          node {
            html
            frontmatter {
              title
              section_name
              position
            }
          }
        }
      }
      cookie: allMarkdownRemark(
        filter: { frontmatter: { page_name: { eq: "cookie_policy_section" } } }
      ) {
        edges {
          node {
            html
            frontmatter {
              title
              section_name
              position
            }
          }
        }
      }
    }
  `);

  const scrollTargetId = data[sectionName].edges.map((sectionItem) =>
    kebabCase(sectionItem.node.frontmatter.section_name)
  );
  return (
    <Card bottom={'0px'} mobileBottom={'0'}>
      <div className={'container'}>
        <ScrollspyGrid>
          <div className={'content'}>
            {data[sectionName].edges.map((section, index) => {
              const filteredSection = data[sectionName].edges.filter(
                (sectionItem) =>
                  sectionItem.node.frontmatter.position === `${index}`
              )[0];
              return <PolicyContent key={index} content={filteredSection} />;
            })}
          </div>
          <div className={'buttons'}>
            <div className={'sticky'}>
              {/*<ScrollSpy>*/}
              {typeof window !== 'undefined' && (
                <ScrollspyNav
                  scrollTargetIds={scrollTargetId}
                  activeNavClass="is-active"
                  scrollDuration="300"
                >
                  {data[sectionName].edges.map((section, index) => {
                    const filteredSection = data[sectionName].edges.filter(
                      (sectionItem) =>
                        sectionItem.node.frontmatter.position === `${index}`
                    )[0];
                    return (
                      <a
                        href={`#${kebabCase(
                          filteredSection.node.frontmatter.section_name
                        )}`}
                        ref={React.createRef()}
                        key={index}
                      >
                        <div>
                          {filteredSection.node.frontmatter.section_name}
                        </div>
                      </a>
                    );
                  })}
                  {/*</ScrollSpy>*/}
                </ScrollspyNav>
              )}
            </div>
          </div>
        </ScrollspyGrid>
      </div>
    </Card>
  );
};

export default PolicyLayout;
