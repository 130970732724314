import React from 'react';
import loadable from '@loadable/component';

const Policy = loadable(() => import('./policyLoad'));
const PolicyLayout = ({ sectionName }) => (
  <div>
    <Policy sectionName={sectionName} />
  </div>
);
export default PolicyLayout;
