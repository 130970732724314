import React from 'react';
import Title from 'src/styles/styledComponents/title';
import Container from '../../styles/components/plan/contact';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import NavButton from '../../styles/styledComponents/navButton';

const ContactUsSection = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "plans_contact_us" } } }
        ) {
          nodes {
            frontmatter {
              title
              link
              button_text
            }
            id
          }
        }
      }
    `),
    { title, button_text, link } = getFrontmatter(data);
  return (
    <Container>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <div className={'actions'}>
        <NavButton
          marginTop={'30px'}
          className="plans_contact-us_link"
          onClick={() => navigate(link)}
        >
          {button_text}
        </NavButton>
      </div>
    </Container>
  );
};

export default ContactUsSection;
