import React from 'react';
import Button from 'src/styles/styledComponents/button';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';

const CallToAction = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "about_us_call_to_action" } }
          }
        ) {
          nodes {
            frontmatter {
              titles
              description
              button_text
              link
            }
          }
        }
      }
    `),
    { titles, button_text, link } = getFrontmatter(data);
  return (
    <Card align={'center'}>
      <Title weight={'bold'}>{titles[0]}</Title>
      <Button onClick={() => navigate(link)}>{button_text}</Button>
    </Card>
  );
};

export default CallToAction;
