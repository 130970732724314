import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';
import Description from 'src/styles/styledComponents/description';
import Topic from 'src/styles/styledComponents/topic';

const AboutUsInfo = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "about_us_info" } } }
        ) {
          nodes {
            id
            frontmatter {
              topic
              title
              description
            }
          }
        }
      }
    `),
    { topic, title, description } = getFrontmatter(data);
  return (
    <Card align={'center'}>
      <Topic
        size={'35px'}
        weight={'600'}
        color={'#201f18'}
        bottom={'45px'}
        mobileBottom={'5px'}
        letterSpace={'-2px'}
        dangerouslySetInnerHTML={{ __html: topic }}
      />
      <Title
        marginBottom={'40px'}
        weight={'bold'}
        size={'38px'}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <Description
        weight={'600'}
        size={'25px'}
        color={'#201f18'}
        letterSpace={'-2px'}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Card>
  );
};

export default AboutUsInfo;
