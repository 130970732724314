/* eslint-disable prettier/prettier */
import React from 'react';
import StyledComparisonTabs from 'src/styles/styledComponents/styledComparisonTabs';
import Title from 'src/styles/styledComponents/title';
import PlanCard from 'src/styles/styledComponents/planCard';
import { StaticImage } from 'gatsby-plugin-image';
import ReactTooltip from 'react-tooltip';
import { Panel } from 'rc-collapse/es';
import PlanCollapse from 'src/styles/styledComponents2/planCollapse';
import { plansSectionColors } from 'src/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';

const ComparisonTabs = ({
  basicFeatureList,
  premiumFeatureList,
}) => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "plans_intro" } } }
        ) {
          nodes {
            frontmatter {
              plans {
                actual_price
                button
                description
                discount_duration
                discount_text
                label
                name
                features_list {
                  feature_texts
                  tooltip_text
                }
                navigation_button
                modal_data {
                  cancel_button
                  content
                  ok_button
                  title
                }
              }
            }
          }
        }
      }
    `),
    { plans } = getFrontmatter(data),
    basicPlan = plans[0],
    premiumPlan = plans[1];

  return (
    <StyledComparisonTabs>
      <PlanCard>

        <div className={'plan-info'}>
          <div
            className={'name'}
            dangerouslySetInnerHTML={{ __html: basicPlan.name }}
          />
          <div>
            <span className={'price'}>
              <span className={'dollar'}>{basicPlan.actual_price[0]}</span>
              {basicPlan.actual_price[1]}
            </span>
          </div>
          <div
            className={'description'}
            dangerouslySetInnerHTML={{ __html: basicPlan.description }}
          />
        </div>

        <PlanCollapse
          accordion={true}
          expandIcon={() => (
            <div className={'collapse-arrow'}>
              <StaticImage
                src={'../../images/arrow.png'}
                alt={'arrow'}
                height={24}
              />
            </div>
          )}
        >
          {basicFeatureList.map((list, index) => (
            <Panel
              header={
                <Title
                  color={plansSectionColors[index]}
                  dangerouslySetInnerHTML={{ __html: list.name }}
                />
              }
              key={index}
              showArrow={true}
            >
              {list.features_list.map((feature, index) => (
                <div key={index}>
                  {feature.bold_text === 'true' ? (
                    <div>
                      <StaticImage
                        src={'../../images/icon-spark-active.svg'}
                        alt={'active'}
                        width={30}
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: feature.normal_text,
                        }}
                      ></span>
                    </div>
                  ) : feature.bold_text === 'false' ? (
                    <>
                      <StaticImage
                        src={'../../images/icon-spark-inactive.svg'}
                        alt={'inactive'}
                        width={30}
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: feature.normal_text,
                        }}
                      ></span>
                    </>
                  ) : (
                    <span>
                      <strong
                        dangerouslySetInnerHTML={{ __html: feature.bold_text }}
                      ></strong>
                      &nbsp;
                      <span
                        dangerouslySetInnerHTML={{
                          __html: feature.normal_text,
                        }}
                      ></span>
                    </span>
                  )}
                </div>
              ))}
            </Panel>
          ))}
        </PlanCollapse>

      </PlanCard>

      <PlanCard>
        <div className={'plan-info'}>
          <div
            className={'name'}
            dangerouslySetInnerHTML={{ __html: premiumPlan.name }}
          />
          <div>
            <span className={'price'}>
              <span className={'dollar'}>{premiumPlan.actual_price[0]}</span>
              {premiumPlan.actual_price[1]}
              <span className={'duration'}>{premiumPlan.actual_price[2]}</span>
            </span>
          </div>
          <div
            className={'discount'}
            dangerouslySetInnerHTML={{ __html: premiumPlan.discount_text }}
          />
          <div
            className={'discount'}
            dangerouslySetInnerHTML={{ __html: premiumPlan.discount_duration }}
          />
        </div>
        <PlanCollapse
          accordion={true}
          expandIcon={() => (
            <div className={'collapse-arrow'}>
              <StaticImage
                src={'../../images/arrow.png'}
                alt={'arrow'}
                height={24}
              />
            </div>
          )}
        >
          {premiumFeatureList.map((list, index) => (
            <Panel
              header={
                <Title
                  color={plansSectionColors[index]}
                  dangerouslySetInnerHTML={{ __html: list.name }}
                />
              }
              key={index}
              showArrow={true}
            >
              {list.features_list.map((feature, index) => (
                <div key={index}>
                  {feature.bold_text === 'true' ? (
                    <>
                      <StaticImage
                        src={'../../images/icon-spark-active.svg'}
                        alt={'active'}
                        width={30}
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: feature.normal_text,
                        }}
                      ></span>
                    </>
                  ) : feature.bold_text === 'false' ? (
                    <>
                      <StaticImage
                        src={'../../images/icon-spark-inactive.svg'}
                        alt={'inactive'}
                        width={30}
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: feature.normal_text,
                        }}
                      ></span>
                    </>
                  ) : (
                    feature.bold_text !== 'true' &&
                    feature.bold_text !== '' && (
                      <span>
                        <strong
                          dangerouslySetInnerHTML={{
                            __html: feature.bold_text,
                          }}
                        ></strong>
                        <span> </span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: feature.normal_text,
                          }}
                        ></span>
                      </span>
                    )
                  )}
                </div>
              ))}
            </Panel>
          ))}
        </PlanCollapse>
      </PlanCard>
      <ReactTooltip id={'comparison-tab'} />
    </StyledComparisonTabs>
  );
};

export default ComparisonTabs;
