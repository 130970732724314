import React from 'react';
import styled from 'styled-components';
import { buttonStyles, responsiveWidth } from 'src/styles/constants';

const FormButton = styled.button`
  border-radius: 10px;
  background-color: #fe6152;
  padding: 7px 30px;
  border: none;
  font-size: ${(props) => (props.size === 'sm' ? '16px' : '20px')};
  color: ${(props) => buttonStyles[props.type || 'primary'].color};
  font-weight: 600;
  cursor: pointer;
  margin-top: ${(props) => props.top || '0'};
  font-family: inherit;
  ${(props) =>
    props.isDisabled &&
    'pointer-events: none; opacity: 0.6; cursor: not-allowed;'};
  transition: background-color 0.15s;
  &:hover {
    background-color: #000;
  }
  @media ${responsiveWidth.mobile} {
    font-size: 18px;
  }
`;
export default FormButton;
