/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import Header from 'src/components/header';
import Footer from 'src/components/footer';
import {
  GlobalStyle,
  GlobalStylePlans,
  GlobalStyleV2,
} from 'src/styles/globalStyles';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import PageHelmet from 'src/components/pageHelmet';
import { InitialLoader } from '../components/preloader/initialLoader';
import VoiceCommander from 'src/components/voiceCommands/voiceCommands';


const Plans = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "commands_page_data" } } }
        ) {
          nodes {
            frontmatter {
              title
              description
              page_title
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `),
    { title, description } = getFrontmatter(data),
    [loader, setLoader] = useState(true),
    [customModal, setCustomModal] = useState(false);

  let headless =
    typeof window !== 'undefined' &&
    window.location.search.includes('headless'),
    modal = null;

  useEffect(() => {
    window.analytics.page();
    setCustomModal(Boolean(modal));
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);

  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description} />
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStylePlans />
      {!headless && <Header />}
      <VoiceCommander />
      {!headless && <Footer />}
    </>
  );
};

export default Plans;
