import React from 'react';
import styled from 'styled-components';
import { breakPoints } from '../../constants';

export const Card = styled.div`
  border-radius: 10px;
  border: 1px solid #c8c8c8;
  padding: 20px 27px;
  margin: 30px 0;

  @media (min-width: ${breakPoints.pc}) {
    max-width: 830px;
  }

  @media (min-width: ${breakPoints.mobileLarge}) {
    padding: 20px 15px;
  }

  @media (min-width: ${breakPoints.tablet}) {
    padding: 15px 24px;
  }

  @media (min-width: ${breakPoints.mobileSmall}) {
    padding: 15px 12px;
    margin: 20px 0;
  }
  @media (min-width: ${breakPoints.pcLarge}) {
    padding-left: 37px;
    padding-right: 37px;
  }
  @media (max-width: ${breakPoints.pcLarge}) {
    padding-left: 35px;
    padding-right: 35px;
  }
  @media (max-width: ${breakPoints.pc}) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: ${breakPoints.pcSmall}) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media (max-width: ${breakPoints.mobileLarge}) {
    padding: 15px 10px;
    margin: 12px 0px 20px 0px;
  }
`;

export const CardDetails = styled.div`
  display: flex;
  align-item: center;
`;

const categoryColor = {
  feature: {
    background: '#c6f6d5',
    color: '#038f72',
  },
  improvements: {
    background: '#acd1f1',
    color: '#007ded',
  },
  'bug-fix': {
    background: '#fcd2ce',
    color: '#fa4038',
  },
  fallback: {
    background: '#b7b7b7',
    color: '#322e2e',
  },
};

export const CardCategory = styled.div`
  display: : flex;
  padding: 6px 19px;
  border-radius: 10px;
  font-weight: bold;
  background-color: ${(props) =>
    props.className
      ? categoryColor[props.className].background
      : categoryColor['fallback'].background};
  color: ${(props) =>
    props.className
      ? categoryColor[props.className]
      : categoryColor['fallback'].color};


  @media (min-width: ${breakPoints.mobileSmall}) {
    max-width: 66px;
    max-height: 25px;
    font-size: 9px;
  }
  
  @media (min-width: ${breakPoints.tablet}) {
    max-width: 66px;
    max-height: 28px;
    font-size: 9px; 
  }

  @media (min-width: ${breakPoints.mobileLarge}) {
    max-width: 123px;
    max-height: 35px;
    font-size: 13px;
  }

  @media (max-width: ${breakPoints.mobileSmall}) {
    max-width: 66px;
    max-height: 28px;
    font-size: 9px;
    padding: 4px 10px;

  }
`;

export const Author = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  margin-left: 16px;
  font-size: 17px;
  color: #322e2e;

  @media (max-width: ${breakPoints.mobileSmall}) {
    font-size: 11px;
  }

  @media (min-width: ${breakPoints.mobileSmall}) {
    font-size: 11px;
  }
`;

export const ContentWrapper = styled.div`
  h3,
  div {
    margin: 0;
    color: #322e2e;
    line-height: 1.4;
  }
  
  h3 {
    font-weight: 599;
  }

  @media (max-width: ${breakPoints.mobileSmall}) {
    h3 {
      margin: 13px 0 15px 0;
      font-size: 14px;
    }
    div {
      font-size: 12px;
    }
  }

  @media (min-width: ${breakPoints.mobileSmall}) {
    h3 {
      margin: 13px 0 15px 0;
      font-size: 14px;
    }
    div {
      font-size: 12px;
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    h3 {
      font-size: 30px;
      marin-bottom: 21px;
      margin-top: 18px;
    }

    div {
      font-size: 20px;
    }
  }

  @media (min-width: ${breakPoints.tablet}) {
    h3 {
      margin: 11px 0 15px 0;
      font-size: 20px
    }
    div {
      font-size: 13px;
    }

  @media(max-width: ${breakPoints.pcLarge}) {
    div {
      font-size: 14px; 
    }
  }
  @media (max-width: ${breakPoints.pcSmall}) {
    h3 {
      font-size: 20px;
    }
    div {
      font-size: 13px;
    }
  }
  @media (max-width: ${breakPoints.mobileLarge}) {
    h3 {
      font-size: 20px;
      margin: 15px 0 18px 0;
    }
    div {
      font-size: 16px;
    }
  }
`;
