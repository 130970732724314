import styled from 'styled-components';

const NoteWithQuote = styled.div`
  position: relative;
  margin: ${(props) => props.margin || '0'};
  margin-top: ${(props) => props.marginTop || '0'};
  padding: ${(props) => props.padding || '0 40px'};
  line-height: ${(props) => props.lineHeight || '27px'};
  font-size: ${(props) => props.size || '18px'};
  &:before {
    content: '"';
    transform: rotate(180deg);
    font-size: 100px;
    position: absolute;
    top: -24px;
    left: 0;
    font-weight: bold;
  }
  &:after {
    content: '"';
    transform: rotate(1deg);
    font-size: 100px;
    position: absolute;
    font-weight: bold;
    bottom: ${(props) => props.bottom};
  }
`;
export default NoteWithQuote;
