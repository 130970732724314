import React from 'react';
import Card from 'src/styles/styledComponents/card';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import Button from 'src/styles/styledComponents/button';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import Grid from 'src/styles/styledComponents2/grid';
import ProductCard from 'src/styles/styledComponents2/productCard';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const OtherProductSection = ({ of }) => {
  const data = useStaticQuery(graphql`
      {
        web: allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "product_web_others" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              description
              content_list {
                description
                title
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                button_text
                link
              }
            }
          }
        }
        mobile: allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "products_mobile_others" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              description
              content_list {
                description
                title
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                button_text
                link
              }
            }
          }
        }
        webClipper: allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "products_webclipper_others" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              description
              content_list {
                description
                title
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                button_text
                link
              }
            }
          }
        }
      }
    `),
    { title, description, content_list } = data[of].nodes[0].frontmatter;
  return (
    <Card align={'center'} padding={of === 'webClipper' && '100px 70px 0px'}>
      <div className={'container-product'}>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Description
          marginBottom={'30px'}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <Grid column={'1fr 1fr'} gap={'50px'} padding={'0 60px'}>
          {content_list.map((content, index) => (
            <ProductCard key={index}>
              <GatsbyImage alt={'product'} image={getImage(content.image)} />
              <div className={'content'}>
                <Title
                  size={'24px'}
                  marginBottom={'15px'}
                  dangerouslySetInnerHTML={{ __html: content.title }}
                />
                <Description
                  dangerouslySetInnerHTML={{ __html: content.description }}
                />
                {content.button_text && (
                  <Button onClick={() => navigate(content.link)}>
                    {content.button_text}
                  </Button>
                )}
              </div>
            </ProductCard>
          ))}
        </Grid>
      </div>
    </Card>
  );
};
OtherProductSection.defaultProps = {
  of: 'mobile',
};
export default OtherProductSection;
