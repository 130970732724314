import React from 'react';
import PopupCard from 'src/styles/styledComponents2/popupCard';
import Grid from 'src/styles/styledComponents2/grid';
import { getFrontmatter } from 'src/utility/helpers';
import { graphql, useStaticQuery, navigate } from 'gatsby';

const FeaturesPopup = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "header" } } }
        ) {
          nodes {
            frontmatter {
              features_list {
                image {
                  childImageSharp {
                    gatsbyImageData(width: 60)
                  }
                  base
                }
                description
                title
                section_name
              }
            }
            id
          }
        }
      }
    `),
    { features_list } = getFrontmatter(data);
  return (
    <PopupCard>
      <Grid column={'1fr 1fr 1fr'} gap={'20px'}>
        {features_list.map((feature, index) => (
          <div
            className={'item'}
            key={index}
            onClick={() => navigate(`/features#${feature.section_name}`)}
          >
            <img
              alt={'discover'}
              src={require(`../../static/img/${feature.image.base}`).default}
              width={60}
            />
            <div>
              <div className={'title'}>{feature.title}</div>
              <div className={'description'}>{feature.description}</div>
            </div>
          </div>
        ))}
      </Grid>
    </PopupCard>
  );
};

export default FeaturesPopup;
