import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container } from '../../../styles/components/contributors/content';

const Content = ({ title, image, body, subtitle }) => (
  <>
    <Container>
      <div className="container__contributors hero-container">
        <div className="container__contributors--wrapper">
          <div className="header">
            <h1
              className="header_title"
              dangerouslySetInnerHTML={{ __html: title }}
            ></h1>
            <p className="header_subtitle">{subtitle}</p>
          </div>
          <div className="hero-image-container">
            {image.childImageSharp != null ? (
              <GatsbyImage alt={getImage(image.name)} image={getImage(image)} />
            ) : (
              <img src={image.publicURL} alt={image.name} />
            )}
          </div>
        </div>
      </div>
      <div className="content--about__wrapper">
        <div
          className="content--about"
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
      </div>
    </Container>
  </>
);

Content.protoTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Content;
