import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { getFrontmatterOf } from 'src/utility/helpers';
import Grid from 'src/styles/styledComponents2/grid';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Button from '../../styles/styledComponents/button';

const ProductDetailSection = ({ of }) => {
  const data = useStaticQuery(graphql`
      {
        web: allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "product_web_detail_section" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(width: 307)
                }
              }
            }
          }
        }
        mobile: allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "products_mobile_detail" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              description
              button_text
              link
              image {
                childImageSharp {
                  gatsbyImageData(width: 500)
                }
              }
              detailed_description_in_points {
                point_1
                point_2
                point_3
                point_4
              }
            }
          }
        }
      }
    `),
    {
      title,
      description,
      image,
      detailed_description_in_points,
      button_text,
      link,
    } = getFrontmatterOf(data, of);
  return (
    <Card color={of === 'web' ? '#fbf8f5' : '#fff'}>
      <div className={'container'}>
        <Grid column={'1fr 1fr'} gap={'30px'} verticalAlign={'center'}>
          <div>
            <GatsbyImage alt={'product'} image={getImage(image)} />
          </div>
          <div>
            <Title
              marginBottom={'0'}
              color={of === 'web' ? '#000' : '#000'}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className={'description-points'}>
              {Object.values(detailed_description_in_points)
                .filter((value) => value !== '')
                .map((description, index) => (
                  <div className={'description-card'} key={index}>
                    <img
                      src={
                        require('../../images/spark-fill-yellow.svg').default
                      }
                      className={'spark-icon'}
                      alt={'spark-fill'}
                    />
                    <span
                      className={'description'}
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </div>
                ))}
            </div>
            {description && (
              <div className={'description-card'}>
                <img
                  src={require('../../images/spark-fill-yellow.svg').default}
                  className={'spark-icon'}
                  alt={'spark-fill'}
                />
                <span
                  className={'description'}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            )}
            <div className={'actions'}>
              <Button onClick={() => navigate(link)}>{button_text}</Button>
            </div>
          </div>
        </Grid>
      </div>
    </Card>
  );
};

export default ProductDetailSection;
