// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-announcements-js": () => import("./../../../src/pages/announcements.js" /* webpackChunkName: "component---src-pages-announcements-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contributors-js": () => import("./../../../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-contributors-list-js": () => import("./../../../src/pages/contributors-list.js" /* webpackChunkName: "component---src-pages-contributors-list-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-cookie-preferences-js": () => import("./../../../src/pages/cookie-preferences.js" /* webpackChunkName: "component---src-pages-cookie-preferences-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-plans-buy-js": () => import("./../../../src/pages/plans-buy.js" /* webpackChunkName: "component---src-pages-plans-buy-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-mobile-js": () => import("./../../../src/pages/products-mobile.js" /* webpackChunkName: "component---src-pages-products-mobile-js" */),
  "component---src-pages-products-webapp-js": () => import("./../../../src/pages/products-webapp.js" /* webpackChunkName: "component---src-pages-products-webapp-js" */),
  "component---src-pages-products-webclipper-js": () => import("./../../../src/pages/products-webclipper.js" /* webpackChunkName: "component---src-pages-products-webclipper-js" */),
  "component---src-pages-release-notes-js": () => import("./../../../src/pages/release-notes.js" /* webpackChunkName: "component---src-pages-release-notes-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-subprocessors-js": () => import("./../../../src/pages/subprocessors.js" /* webpackChunkName: "component---src-pages-subprocessors-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thirdparty-js": () => import("./../../../src/pages/thirdparty.js" /* webpackChunkName: "component---src-pages-thirdparty-js" */),
  "component---src-pages-voice-commands-js": () => import("./../../../src/pages/voice-commands.js" /* webpackChunkName: "component---src-pages-voice-commands-js" */),
  "component---src-pages-why-sparkpico-js": () => import("./../../../src/pages/why-sparkpico.js" /* webpackChunkName: "component---src-pages-why-sparkpico-js" */)
}

