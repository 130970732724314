import styled from 'styled-components';
import { responsiveWidth } from '../constants';

const AnnouncementCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffffff;
  overflow-x: auto;
  padding: 40px 50px;
  .title {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }
  .header {
    display: flex;
    justify-content: flex-end;
    margin-top: 28px;
    padding: 0 30px;
    font-size: 16px;
    font-weight: 500;
    color: #2b2b2b;
    & > span {
      display: flex;
      align-items: center;
      .web {
        display: block;
      }

      .mobile {
        display: none;
      }
    }
    .header-sider {
      display: grid;
      grid-template-columns: 100px 150px;
      justify-items: center;
      cursor: pointer;
    }
    .filter {
      margin-left: 10px;
    }
  }
  .list {
    margin-top: 30px;
    .announcement {
      padding: 30px 18px;
      display: grid;
      align-items: center;
    }
  }
  @media ${responsiveWidth.mobile} {
    padding: 10px 15px;
    .header {
      padding: 0px;
      font-size: 12px;
      & > span {
        display: flex;
        align-items: center;
        .web {
          display: none;
        }
        .mobile {
          display: block;
        }
      }
      .header-sider {
        grid-template-columns: 75px 100px !important;
      }
    }
    .list {
      margin-top: 5px;
      .announcement {
        padding: 10px;
      }
    }
  }
`;

export default AnnouncementCard;
