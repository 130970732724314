/* eslint-disable indent */
import React, { useState } from 'react';
import VoiceCommanders from '../../styles/styledComponents/voiceCommands';
import collapse from '../../../static/img/collapseall.svg';
import extend from '../../../static/img/expandall.svg';
import commands from '../../../static/img/commands.svg';
import conversation from '../../../static/img/conversation.svg';
import Collapse from '@kunukn/react-collapse';
import Down from './voiceDown';
import { graphql, useStaticQuery, Link, navigate } from 'gatsby';

const initialState = [
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
];
function reducer(state, { type, index }) {
  switch (type) {
    case 'expand-all':
      return [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
    case 'collapse-all':
      return [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ];
    case 'toggle':
      state[index] = !state[index];
      return [...state];

    default:
      throw new Error();
  }
}

function Block({ isOpen, title, onToggle, children }) {
  return (
    <div className="block">
      <button className="btn toggle" onClick={onToggle}>
        {title}
        <Down isOpen={isOpen} />
      </button>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
}

function VoiceCommander() {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "voice_commands" } } }
          sort: { order: ASC, fields: frontmatter___type }
        ) {
          nodes {
            frontmatter {
              category
              type
              card_detail {
                title
                description
              }
            }
          }
        }
      }
    `),
    [state, dispatch] = React.useReducer(reducer, initialState),
    [viewConversation, setViewConversation] = useState(false);

  const cardsData = data.allMarkdownRemark.nodes.map((card) => ({
    ...card.frontmatter,
  }));

  return (
    <VoiceCommanders>
      {viewConversation === false ? (
        <div className="voice_commands">
          <div className="header">
            <h2 className="header-title">Voice Commands for PiCO Assistant</h2>
            <div className="format">
              <div className="view-images">
                <img
                  src={commands}
                  alt={'missing commands image'}
                  className=" image active-command"
                  onClick={() => setViewConversation(false)}
                />
                <img
                  src={conversation}
                  alt={'missing conversation image'}
                  className="image passive_right"
                  onClick={() => setViewConversation(true)}
                />
              </div>
              <div className="sort-images">
                <img
                  src={extend}
                  alt={'missing arrow down image'}
                  className="image"
                  onClick={() => dispatch({ type: 'collapse-all' })}
                  disabled={state.every((s) => s === true)}
                />
                <img
                  src={collapse}
                  alt={'missing arrow up image'}
                  className="image"
                  onClick={() => dispatch({ type: 'expand-all' })}
                  disabled={state.every((s) => s === false)}
                />
              </div>
            </div>
          </div>
          <div className="card_container">
            {cardsData.map((item, index) => (
              <div className="card" key={index}>
                {item.category === 'Voice Commands for PiCO Assistant' && (
                  <Block
                    title={
                      <div className="title">
                        <h2 className="card-title">{item.type}</h2>
                      </div>
                    }
                    isOpen={state[index]}
                    onToggle={() => dispatch({ type: 'toggle', index: index })}
                  >
                    <div className="card-data">
                      {item.card_detail.map((detail, index) => (
                        <div key={index}>
                          <div className="card_title">● {detail.title}</div>
                          <div
                            className="card_description"
                            dangerouslySetInnerHTML={{
                              __html: detail.description,
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </Block>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="voice_commands">
          <div className="header">
            <h2 className="header-title">Conversations</h2>
            <div className="format">
              <div className="view-images">
                <img
                  src={commands}
                  alt={'missing commands image'}
                  className="image passive_left"
                  onClick={() => setViewConversation(false)}
                />
                <img
                  src={conversation}
                  alt={'missing conversation image'}
                  className="image active-conversation"
                  onClick={() => setViewConversation(true)}
                />
              </div>
              <div className="sort-images">
                <img
                  src={extend}
                  alt={'missing arrow down image'}
                  className="image"
                  onClick={() => dispatch({ type: 'collapse-all' })}
                  disabled={state.every((s) => s === true)}
                />
                <img
                  src={collapse}
                  alt={'missing arrow up image'}
                  className="image"
                  onClick={() => dispatch({ type: 'expand-all' })}
                  disabled={state.every((s) => s === false)}
                />
              </div>
            </div>
          </div>
          <div className="card_container">
            {cardsData.map((item, index) => (
              <div className="card" key={index}>
                {item.category === 'Conversations' && (
                  <Block
                    title={
                      <div className="title">
                        <h2 className="card-title">{item.type}</h2>
                      </div>
                    }
                    isOpen={state[index]}
                    onToggle={() => dispatch({ type: 'toggle', index: index })}
                  >
                    <div className="card-data">
                      {item.card_detail.map((detail, index) => (
                        <div key={index}>
                          <div className="card_title">● {detail.title}</div>
                          <div
                            className="card_description"
                            dangerouslySetInnerHTML={{
                              __html: detail.description,
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </Block>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </VoiceCommanders>
  );
}

export default VoiceCommander;
