import { graphql, useStaticQuery } from 'gatsby';

export const getPlanCardsAlerts = (alertId) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { section_name: { eq: "plan_card_alert" } } }
      ) {
        nodes {
          frontmatter {
            plan_id
            title
            description
            button
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.nodes
    .map((n) => n.frontmatter)
    .find((n) => n.plan_id == alertId);
};
