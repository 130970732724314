import styled from 'styled-components';

const MobileTabs = styled.div`
  display: grid;
  margin-bottom: 10px;
  text-align: left;
  padding: ${(props) => props.padding || '10px 10px 0'};
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
  color: #9a9faa;
  font-weight: bold;
  transition: all 0.2s;
  cursor: pointer;
  font-size: 18px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1),
    15px 21px 27px 0 rgba(0, 0, 0, 0.05),
    inset 0 -4px 0 0 rgba(232, 235, 241, 1);
  &.active-tab {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1),
      15px 21px 27px 0 rgba(0, 0, 0, 0.05),
      inset 0 -9px 0 0 ${(props) => props.active || '#fb6357'};
    padding: 9px 9px 30px;
    overflow: hidden;
    background-color: #ffffff;
    color: #515459;
    margin-bottom: 20px !important;
    .title {
      padding: 15px 15px 0;
      font-size: 20px;
      color: ${(props) => props.active || '#fb6357'};
      margin-bottom: 0px;
    }
    .description-card {
      display: flex;
      align-items: flex-start;
      margin-top: 10px;
    }
    .description {
      font-weight: normal;
      padding: 0px 5px;
      font-size: 14px;
      margin-left: 0px;
      line-height: 21px;
    }
    .spark-icon {
      margin-top: 2px;
      padding: 0 0 0 15px;
      height: 12px;
      width: 12px;
    }
    .tab-head-image {
      display: block;
      img {
        border-radius: 15px;
      }
    }
  }
`;
export default MobileTabs;
