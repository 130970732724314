/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import StyledModal from 'src/styles/styledComponents/styledModal';
import Backdrop from 'src/styles/styledComponents/backDrop';
import { StaticImage } from 'gatsby-plugin-image';
import ModalButton from 'src/styles/styledComponents/modalButton';
import ModalButton1 from 'src/styles/styledComponents/modalButton1';
import { Loader } from '../preloader/loader';

const CustomModal = ({ visible, setVisible, data, url, currentPlan = null, type }) => {
  const [loading, setLoading] = useState(false),
    renderBackdrop = (props) => <Backdrop {...props} />,
    access_token =
      typeof window !== 'undefined' &&
        window.location.search.includes('access_token=')
        ? new URL(window.location.href).searchParams.get('access_token')
        : false,
    platform =
      typeof window !== 'undefined' &&
        window.location.search.includes('platform=')
        ? new URL(window.location.href).searchParams.get('platform')
        : false;
  let plan_id = null;

  if (access_token) {
    plan_id = currentPlan?.currentPlan?.plan_id;
  }

  return (
    <StyledModal
      show={visible}
      onHide={setVisible}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <div>
        <div className={'image-container'}>
          <div className={'water-mark'}>
            <StaticImage
              src={'../../images/spark-watermark.png'}
              alt={'watermark'}
            />
          </div>
          <div className={'info-icon'}>
            <StaticImage src={'../../images/info.png'} alt={'info'} />
          </div>
        </div>
        <div className={'content'}>
          <div
            className={'title'}
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>

        {data.ok_button && (
          <div className={'actions'}>
            {data.cancel_button && (
              <ModalButton onClick={() => setVisible(false)}>
                {data.cancel_button}
              </ModalButton>
            )}
            {data.ok_button != 'undefined' && access_token && platform == 'web' ? (
              <>
                <ModalButton
                  onClick={() => {
                    setLoading(true);
                    if(type === 'remove_cancellation'){
                      window.parent.postMessage(
                        {
                          'url': url || 'https://billing.sparkpico.com?action=remove_cancellation',
                          'type': 'remove_cancellation',
                        },
                        '*'
                      );
                    } else if ( type === 'cancel_subscription') {
                      window.parent.postMessage(
                        {
                          'url': url || 'https://billing.sparkpico.com?action=cancel_subscription',
                          'type': 'cancel_subscription',
                        },
                        '*'
                      );
                    } else if ( type === 'manage_subscription') {
                      window.parent.postMessage(
                        {
                          'url': url || 'https://billing.sparkpico.com?action=manage_subscription',
                          'type': 'manage_subscription',
                        },
                        '*'
                      );
                    } else {
                      window.parent.postMessage(
                        {
                          'url': `https://billing.sparkpico.com?action=buy&plan_id=${currentPlan?.chosen_plan_id || currentPlan?.current_plan_id}`,
                          'type': 'buy',
                          'planId': currentPlan?.chosen_plan_id || currentPlan?.current_plan_id,
                        },
                        '*'
                      );
                    }
                  }}
                  type={'ok'}
                >
                  {data.ok_button}
                </ModalButton>
                {loading ? <Loader /> : null}
              </>
            ) : (
              <>
                <ModalButton
                  onClick={() => {
                    setLoading(true);
                    setVisible(true);
                    window.location.href = url;
                  }}
                  type={'ok'}
                >
                  {data.ok_button}
                </ModalButton>
                {loading ? <Loader /> : null}
              </>
            )}
          </div>
        )}
        {data.button && (
          <ModalButton1 className={'blue'} onClick={() => setVisible(false)}>
            {data.button}
          </ModalButton1>
        )}
      </div>
    </StyledModal>
  );
};

export default CustomModal;
