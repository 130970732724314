import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const ScrollspyGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  &:not(:first-child) {
    margin-top: 100px;
  }
  .content {
    & > div:not(:last-child) {
      margin-bottom: 100px;
    }
    a {
      word-break: break-all;
    }
  }
  .buttons {
    text-align: right;

    .sticky {
      position: sticky;
      top: 60px;
      max-height: 50vh;
      overflow: auto;
    }
    a {
      text-decoration: none;
      font-size: 22px;
      color: #000;
      div {
        padding: 15px 30px;
        border-radius: 8px;
      }
      &.is-active {
        div {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  @media ${responsiveWidth.mobile} {
    grid-template-columns: 1fr;
    margin-top: 40px;
    .buttons {
      order: 0;
      text-align: left;
      .sticky {
        max-height: unset;
      }
      a {
        font-size: 16px;
        div {
          padding: 10px 15px;
          border-radius: 8px;
        }
      }
    }
    .content {
      order: 1;
      & > div:not(:last-child) {
        margin-bottom: 50px;
      }
    }
  }
`;
export default ScrollspyGrid;
