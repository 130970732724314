import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { getFrontmatterOf } from 'src/utility/helpers';
import Card from 'src/styles/styledComponents/card';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import StyledCollapse from '../../styles/styledComponents/styledCollapse';
import { StaticImage } from 'gatsby-plugin-image';
import { Panel } from 'rc-collapse';
import HorizontalLine from '../../styles/styledComponents/horizontalLine';
import NavButton from '../../styles/styledComponents/navButton';

const ProductFaq = ({ of }) => {
  const data = useStaticQuery(graphql`
      {
        web: allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "product_web_faq" } } }
        ) {
          nodes {
            id
            frontmatter {
              title
              show
              description
              faq_list {
                question
                answers
              }
              navigation_button
              bottom_text
              link
            }
          }
        }
        mobile: allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "products_mobile_faq" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              link
              show
              description
              faq_list {
                question
                answers
              }
              navigation_button
              bottom_text
            }
          }
        }
        webClipper: allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "products_webclipper_faq" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              link
              show
              description
              faq_list {
                question
                answers
              }
              navigation_button
              bottom_text
            }
          }
        }
      }
    `),
    { title, description, faq_list, navigation_button, link, show } =
      getFrontmatterOf(data, of);
  return show === true ? (
    <Card padding={of === 'webClipper' && '84px 70px 70px'}>
      <div className={'container faq-container'}>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <StyledCollapse
          accordion={true}
          defaultActiveKey={'0'}
          expandIcon={() => (
            <div className={'collapse-arrow'}>
              {/* <StaticImage
                src={'../../images/arrow.png'}
                alt={'arrow'}
                height={24}
              /> */}
            </div>
          )}
        >
          {faq_list.map((content, index) => (
            <Panel header={content.question} key={index} showArrow={true}>
              <HorizontalLine
                line={'-10px'}
                width={'100%'}
                height={'2px'}
                color={'#EBEEF3'}
              />
              <div dangerouslySetInnerHTML={{ __html: content.answers }} />
            </Panel>
          ))}
        </StyledCollapse>
        <div className={'actions'}>
          <NavButton marginTop={'0'} onClick={() => navigate(link)}>
            {navigation_button}
          </NavButton>
        </div>
      </div>
    </Card>
  ) : (
    false
  );
};
ProductFaq.defaultProps = {
  of: 'mobile',
};
export default ProductFaq;
