import styled from 'styled-components';
import React from 'react';
import { breakPoints } from '../../constants';

const ReleaseNoteButton = styled.div`
  font-family: 'AvertaStd-Semibold';
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 268px;
  max-height: 46px;
  box-shadow: 1px 1px 10px rgba(107, 107, 107, 0.41);
  line-height: 28px;
  color: #fe6152;
  background: #ffffff;
  border-radius: 10px;
  padding: 19px;
  font-size: 24px;
  position: relative;
  cursor: pointer;
  @media (max-width: ${breakPoints.tablet}) {
    box-shadow: 1px 1px 10px rgba(224, 224, 224, 0.41);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    max-height: 34px;
    font-size: 15px;
    line-height: 18px;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  &:hover {
    background: rgb(254, 98, 83);
    color: #ffffff;
    transition: all 0.3s ease 0s;
  }
`;
export default ReleaseNoteButton;
