import React, { useEffect, useState } from 'react';
import { getFrontmatter } from 'src/utility/helpers';
import PageHelmet from 'src/components/pageHelmet';
import {
  GloablStyleFeatures,
  GlobalStyle,
  GlobalStyleHome,
  GlobalStyleV2,
} from 'src/styles/globalStyles';
import { graphql, useStaticQuery } from 'gatsby';
import Footer from 'src/components/footer';
import Header from 'src/components/header';
import OverlayHero from 'src/components/features/overlayHero';
import FeatureContent from 'src/components/features/featuresContent';

import { InitialLoader } from '../components/preloader/initialLoader';
import Stores from '../components/home/stores';
import CallToAction from '../components/home/callToAction';

const Features = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "features_page" } } }
        ) {
          nodes {
            frontmatter {
              title
              description
            }
          }
        }
      }
    `),
    { title, description } = getFrontmatter(data),
    [loader, setLoader] = useState(true);

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);
  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description} />
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStyleHome />
      <GloablStyleFeatures />
      <Header />
      <OverlayHero />
      <FeatureContent />
      {/*<FeaturesInfoSection />*/}
      <CallToAction page={'features'} />
      <Stores page={'Features'} />
      <Footer />
    </>
  );
};

export default Features;
