import React, { useState } from 'react';
import AnnouncementCard from '../../styles/styledComponents/announcementCard';
import { StaticImage } from 'gatsby-plugin-image';
import NotificationCard from '../../styles/styledComponents/notificationCard';
import NotifyButton from '../../styles/styledComponents/notifyButton';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from '../../utility/helpers';
import moment from 'moment';
import {
  DropDownContent,
  DropDownList,
  StyledMenu,
  SubMenu,
} from '../../styles/styledComponents/select';

const NotificationsCard = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "announcements_list" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              titles
              announcements_list {
                button
                content
                date
                title
                type
              }
            }
          }
        }
      }
    `),
    { title, announcements_list, titles } = getFrontmatter(data),
    [filtersValue, setFiltersValue] = useState('All'),
    [filter, setFilter] = useState(
      announcements_list ? announcements_list : ' '
    );

  const filters = [
    { key: 1, value: 'Today' },
    { key: 2, value: 'Last week' },
    { key: 3, value: 'Last 30 Days' },
    { key: 4, value: 'All' },
  ];

  const filterToday = () => {
    setFiltersValue('Today');
    const today = announcements_list.filter((list) =>
      moment().isSame(list?.date, 'day')
    );
    setFilter(today);
  };
  const filterWeek = () => {
    setFiltersValue('Last week');
    const todayDate = new Date();
    const startDayOfPrevWeek = moment(todayDate)
      .subtract(1, 'week')
      .startOf('week')
      .format('LLLL');
    const lastDayOfPrevWeek = moment(todayDate)
      .subtract(1, 'week')
      .endOf('week')
      .format('LLLL');
    const filterWeekDay = announcements_list?.filter((list) =>
      moment(list?.date).isBetween(startDayOfPrevWeek, lastDayOfPrevWeek)
    );
    setFilter(filterWeekDay);
  };
  const filterMonth = () => {
    setFiltersValue('Last 30 Days');
    const lastDayOfMonth = new Date();
    const startDayOfMonth = moment().subtract(30, 'd').format('YYYY-MM-DD');
    const filterMonthDay = announcements_list?.filter((list) =>
      moment(list?.date).isBetween(startDayOfMonth, lastDayOfMonth)
    );
    setFilter(filterMonthDay);
  };
  const filterAll = () => {
    setFiltersValue('All');
    setFilter(announcements_list);
  };

  const handleClick = (value) => {
    switch (value) {
    case 1:
      return filterToday();
    case 2:
      return filterWeek();
    case 3:
      return filterMonth();
    case 4:
      return filterAll();
    default:
      return filterAll();
    }
  };

  return (
    <AnnouncementCard>
      <div className={'container'}>
        <div className={'title'}>{title}</div>
        <div className={'header'}>
          <span>
            <span className={'web'}>{titles[1]}</span>
            <span className={'mobile'}>{titles[0]}</span>
            <DropDownList>
              <StyledMenu>
                {filtersValue}
                {/* <StaticImage
                  src={'../images/arrow.png'}
                  alt={'arrow'}
                  width={18}
                /> */}
              </StyledMenu>
              <DropDownContent>
                {filters.map((filter, index) => (
                  <div key={index}>
                    <SubMenu
                      onClick={() => handleClick(filter.key)}
                      active={filter.value === filtersValue}
                    >
                      {filter.value}
                    </SubMenu>
                  </div>
                ))}
              </DropDownContent>
            </DropDownList>
          </span>
        </div>

        <div className={'list'}>
          {filter.length ? (
            filter.map((list, index) => (
              <NotificationCard key={index} type={list.type}>
                <div className={'time'}>
                  <div>{moment(list?.date).format('HH:mm')}</div>
                  <div>{moment(list?.date).format('MMM DD')}</div>
                </div>
                <div className={'announcement'}>
                  <div className={'content'}>
                    <div className={'title-announcement'}>{list?.title}</div>
                    <div>{list?.content}</div>
                  </div>
                  <div>
                    {list?.button && <NotifyButton>{list.button}</NotifyButton>}
                  </div>
                </div>
              </NotificationCard>
            ))
          ) : (
            <NotificationCard className={'results'}>
              No results
            </NotificationCard>
          )}
        </div>
      </div>
    </AnnouncementCard>
  );
};
export default NotificationsCard;
