import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const SlickCard = styled.div`
  max-width: ${(props) => props.width || 'unset'};
  border-radius: 16px;
  box-shadow: ${(props) =>
    props.type === 'why-sparkpico'
      ? 'none'
      : '0 12px 24px 0 rgba(0, 0, 0, 0.1), 30px 42px 54px 0 rgba(0, 0, 0, 0.05),  inset 0 -13px 0 0 #E8EBF1'};
  background-color: ${(props) =>
    props.type !== 'why-sparkpico' ? '#fff' : 'transparent'};
  padding: ${(props) => (props.type !== 'why-sparkpico' ? '40px' : '0')};
  flex: none;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-align: left;
  .gatsby-image-wrapper {
    margin-bottom: 20px;
  }
  h1 {
    color: rgba(0, 0, 0, 0.3);
  }
  &:hover {
    color: rgba(0, 0, 0, 0.6);
    h1 {
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .additional-info {
    display: none;
  }
  &.focussed {
    color: #000;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1),
      30px 42px 54px 0 rgba(0, 0, 0, 0.05), inset 0 -13px 0 0 #ffa303;
    h1 {
      color: #ffa303;
    }
    .arrow-focussed {
      color: #ffa303;
    }
    .additional-info {
      display: block;
    }
  }
  box-sizing: border-box;
  .topic {
    //text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 0;
  }
  @media ${responsiveWidth.mobile} {
    padding: 20px;
    color: #000;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1),
      30px 42px 54px 0 rgba(0, 0, 0, 0.05), inset 0 -9px 0 0 #e8ebf1;
    &.focussed {
      color: #000;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1),
        30px 42px 54px 0 rgba(0, 0, 0, 0.05), inset 0 -9px 0 0 #ffa303;
      h1 {
        color: #000;
      }
      .additional-info {
        display: block;
      }
    }
  }
`;
export default SlickCard;
