import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchData = (access_token) => {
  const [plan, setPlan] = useState({});
  let componentMounted = true;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.GATSBY_API_URL}user/plan/current_plan_detail?token=${access_token}`
        );
        if (componentMounted) {
          setPlan(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      componentMounted = false;
    };
  }, []);

  return plan;
};

export default useFetchData;
