import React, { useEffect, useState } from 'react';
import { GlobalStyle, GlobalStyleV2 } from 'src/styles/globalStyles';
import PageTitle from 'src/components/pageTitle';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import PolicyLayout from 'src/components/policyPages/policyLayout';
import { GlobalStylePolicy } from 'src/styles/globalStyles';
import PageHelmet from 'src/components/pageHelmet';
import Footer from 'src/components/footer';
import Header from 'src/components/header';

import { InitialLoader } from '../components/preloader/initialLoader';

const PrivacyPolicy = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "privacy_policy" } } }
        ) {
          nodes {
            frontmatter {
              title
              description
              page_title
              info
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `),
    { title, page_title, background, info, description } = getFrontmatter(data),
    [loader, setLoader] = useState(true);

  let headless =
    typeof window !== 'undefined' &&
    window.location.search.includes('headless');

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 900);
  }, []);

  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description} />
      <GlobalStyle />
      <GlobalStyleV2 />
      {!headless && <Header />}
      <GlobalStylePolicy />
      <PageTitle title={page_title} background={background} info={info} />
      <PolicyLayout sectionName={'privacy'} />
      {!headless && <Footer />}
    </>
  );
};

export default PrivacyPolicy;
