import styled from 'styled-components';
import { responsiveWidth } from '../constants';

const CoreFeatureCard = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1),
    30px 42px 54px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  font-size: 18px;
  color: #515459;
  font-weight: 400;
  .card-image {
    background-color: #fcfbfb;
    display: flex;
    justify-content: center;
  }
  .gatsby-image-wrapper {
    border-radius: 15px 15px 0 0;
    max-width: 300px;
    height: 305px;
    img {
      object-fit: contain !important;
    }
  }
  & > div:first-child {
    span {
      font-weight: bold;
      font-size: 26px;
      color: #2c3038;
    }
  }
  .mobile-features {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .description-content {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
  }
  & > *:not(.gatsby-image-wrapper) {
    text-align: left;
  }
  .spark-features {
    display: none;
  }
  .spark-icon {
    margin-right: 5px;
    width: 18px;
    height: 18px;
  }
  @media ${responsiveWidth.mobile} {
    .mobile-features {
      position: relative;
      width: 100%;
      .arrow-features {
        left: 50%;
        margin: 0;
        transform: translate(-50%, 7%) rotate(180deg);
        position: absolute;
        border-radius: 2px;
        border-bottom: 30px solid #ffa303;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        z-index: 1;
      }
      .spark-features {
        display: block;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -25%);
      }
      .card-image {
        text-align: center;
      }
      .spark-icon {
        width: 12px;
        height: 12px;
      }
      .description {
        font-size: 22px;
      }
    }
  }
`;
export default CoreFeatureCard;
