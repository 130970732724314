import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

const Grid = styled.div`
  display: grid;
  padding: ${(props) => props.padding || 'none'};
  grid-template-columns: ${(props) => props.column || '1fr'};
  grid-gap: ${(props) => props.gap || '0'};
  align-items: ${(props) => props.verticalAlign || 'inherit'};
  & + * {
    margin-top: ${(props) => props.bottom || '30px'};
  }
  .actions {
    margin-top: 15px;
  }
  .description-points {
    font-size: 19px;
    display: grid;
    grid-gap: 10px;
    line-height: 28px;
    .description {
      margin-left: 10px;
    }
  }
  @media ${responsiveWidth.mobile} {
    grid-template-columns: ${(props) => props.mobileColumn || '1fr'};
    grid-gap: 10px;
    padding: 0px;
    justify-items: center;
    & + * {
      margin-top: 15px;
    }
    .description-points {
      font-size: 14px;
    }
    .actions {
      display: flex;
      justify-content: center;
    }
  }
`;
export default Grid;
