import React, { useState } from 'react';
import InputWrapperSpark from 'src/styles/styledComponents2/inputWrapperSpark';

const InputSpark = ({ label, id, ...props }) => {
  const [isFocus, setFocus] = useState(false);

  return (
    <InputWrapperSpark isFocus={isFocus}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        onFocus={() => setFocus(true)}
        onBlur={(e) => !e.target.value && setFocus(false)}
        {...props}
      />
    </InputWrapperSpark>
  );
};

export default InputSpark;
