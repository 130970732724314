import React from 'react';
import styled from 'styled-components';
import { breakPoints } from '../../constants';

export const Container = styled.div`
  margin-top: calc(-160px + 68px) !important;
  background: white;
`;

export const SearchContainer = styled.div`
  background-color: #fbf8f5;
  padding-top: 140px;
  padding-bottom: 60px;
  margin-top: -70px;

  @media (min-width: ${breakPoints.pcLarge}) {
    padding-left: 188px;
  }
  @media (max-width: ${breakPoints.pcLarge}) {
    padding-left: calc(1px + 210px);
  }
  @media (max-width: ${breakPoints.pc}) {
    padding-left: 0;
  }
`;

export const SearchWrapper = styled.div`
  position: relative;
  margin: auto;
  align-items: center;

  .gatsby-image-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    left: 35px;
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    max-width: 1077px;
    max-height: 85px;
  }

  @media (min-width: ${breakPoints.pc}) {
    max-width: 1077px;
    max-height: 85px;
  }

  @media (max-width: ${breakPoints.pc}) {
    max-width: 900px;
    .gatsby-image-wrapper {
      left: 100px;
    }
  }

  @media (max-width: ${breakPoints.pcSmall}) {
    max-width: 600px;

    .gatsby-image-wrapper {
      left: -52px;
      top: 65px;
      max-width: 16px;
      max-height: 15px;
    }
  }

  @media (max-width: ${breakPoints.tablet}) {
    .gatsby-image-wrapper {
      left: 30px;
      top: 58px;
      max-width: 15px;
    }
  }

  @media (max-width: ${breakPoints.mobileLarge}) {
    .gatsby-image-wrapper {
      max-width: 18px;
      max-height: 18px;
      left: 48px;
      top: 56px;
    }
  }

  @media (max-width: ${breakPoints.mobileSmall}) {
    .gatsby-image-wrapper {
      left: 40px;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 1077px;
  height: 85px;

  @media (min-width: ${breakPoints.pcLarge}) {
    max-width: 988px;
    max-height: 78px;
  }

  @media (max-width: ${breakPoints.pcLarge}) {
    max-width: 964px;
    max-height: 75px;
  }
  @media (max-width: ${breakPoints.pc}) {
    max-width: 835px;
    margin-left: 70px;
  }

  @media (max-width: ${breakPoints.pcSmall}) {
    max-width: 732px;
    padding-top: 40px;
    margin-left: -70px;
    max-height: 48px;
  }

  @media (max-width: ${breakPoints.tablet}) {
    max-width: 530px;
    margin: 0 20px;
    max-height: 50px;
  }

  @media (max-width: ${breakPoints.mobileLarge}) {
    max-width: 465px;
    width: 80%;
    max-height: 45px;
    margin: 0 34px;
  }

  @media (max-width: ${breakPoints.mobileSmall}) {
    max-width: 260px;
    max-height: 45px;
    margin: auto;
  }
`;

export const SearchInput = styled.input`
  border-radius: 10px;
  box-shadow: 0 0 18px 4px rgb(5 5 5 / 18%);
  margin: 0;
  display: block;
  border: none;
  width: 100%;
  height: 100%;
  font-size: 30px;
  padding-left: 86px;

  &::placeholder {
    color: #dfdfdf;
    font-family: 'AvertaStd', serif;
  }

  &:focus {
    outline: none;
  }
  @media (max-width: ${breakPoints.tablet}) {
    font-size: 16px;
    height: 70%;
    padding-left: 35px;
  }

  @media (max-width: ${breakPoints.mobileLarge}) {
    font-size: 18px;
    height: 70%;
    padding-left: 35px;
  }

  @media (max-width: ${breakPoints.pcSmall}) {
    font-size: 14px;
    padding-left: 43px;
  }
`;

export const Content = styled.div`
  display: grid;
  margin: auto;
  padding: 46px 0;
  column-gap: 60px;

  > div {
    .search_posts_date {
      margin: 0 25px;

      @media (max-width: ${breakPoints.pcLarge}) {
        font-size: 32px;
      }
      @media (max-width: ${breakPoints.pc}) {
        font-size: 24px;
      }
      @media (max-width: ${breakPoints.pcSmall}) {
        font-size: 20px;
      }
      @media (max-width: ${breakPoints.tablet}) {
        font-size: 28px;
      }
      @media (max-width: ${breakPoints.mobileLarge}) {
        font-size: 24px;
        margin: 0;
      }
      @media (max-width: ${breakPoints.mobileSmall}) {
        font-size: 16px;
        margin: 0;
      }
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    max-width: 1233px;
    grid-template-columns: 220px 1fr;
  }

  @media (min-width: ${breakPoints.pc}) {
    max-width: 1140px;
    grid-template-columns: 200px 1fr;
    -webkit-column-gap: 50px;
  }

  @media (max-width: ${breakPoints.pc}) {
    max-width: 900px;
    grid-template-columns: 180px 1fr;
    -webkit-column-gap: 50px;
  }

  @media (max-width: ${breakPoints.pcSmall}) {
    max-width: 810px;
    grid-template-columns: 180px 1fr;
    -webkit-column-gap: 50px;
    margin: 30px 40px;
    margin-right: 20px;
  }

  @media (max-width: ${breakPoints.tablet}) {
    max-width: 600px;
    grid-template-columns: 1fr;
    padding: 0 20px;
  }

  @media (max-width: ${breakPoints.mobileLarge}) {
    grid-template-columns: 1fr;
    padding: 0;
    max-width: 465px;
    margin: 0 21px;
  }

  @media (max-width: ${breakPoints.mobileSmall}) {
    grid-template-columns: 1fr;
    padding: 0;
    max-width: 465px;
    margin: 0 21px;
  }
`;

export const FilterSideBar = styled.div`
  h4 {
    color: #b7b7b7;
    font-weight: normal;
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    h4 {
      margin-bottom: 22px;
    }
  }

  @media (max-width: ${breakPoints.mobileLarge}) {
    display: none;
  }
  @media (max-width: ${breakPoints.tablet}) {
    display: none;
  }
`;

export const FilterItems = styled.ul`
  list-style: none;
  padding: 0;

  li {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-bottom: 20px;

    &.active {
      .all {
        color: #eaa470;
      }

      .select_all_category_features {
        color: #028f72;
      }

      .select_all_category_improvements {
        color: #2e93ef;
      }

      .select_all_category_bug-fix {
        color: #db3f17;
      }
    }
    .all {
      height: 5px;
    }

    .select_all_category_features {
      height: 5px;
    }

    .select_all_category_improvements {
      height: 5px;
    }

    .select_all_category_bug-fix {
      height: 5px;
    }

    .release_notes__checkmark {
      border-radius: 50%;
      border: 1px solid #322e2e;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  .improvements {
    > div {
      width: 20px;
      height: 20px;
    }
  }

  .bug-fix {
    > div {
      width: 20px;
      height: 20px;
    }
  }

  @media (min-width: ${breakPoints.pc}) {
    li {
      margin-bottom: 15px;
      > div {
        margin-right: 5px;
      }
    }
  }

  @media (min-width: ${breakPoints.tablet}) {
    li {
      margin-bottom: 15px;
      > div {
        margin-right: 5px;
      }
    }
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    li {
      margin-bottom: 15px;
    }
  }
`;

export const Posts = styled.div``;
