import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import Card from 'src/styles/styledComponents/card';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import CoreFeatureCard from 'src/styles/styledComponents2/coreFeatureCard';
import MasonaryList from '../../styles/styledComponents/masonaryList';
import Grid from '../../styles/styledComponents2/grid';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import HorizontalLine from '../../styles/styledComponents/horizontalLine';

const CoreFeaturesMobile = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "products_mobile_features" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              description
              content_list {
                description
                title
                image {
                  childImageSharp {
                    gatsbyImageData(width: 300)
                  }
                }
              }
            }
          }
        }
      }
    `),
    { title, content_list } = getFrontmatter(data);
  return (
    <Card align={'center'} className={'core-features'}>
      <div className={'container'}>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Grid column={'1fr 1fr 1fr'} gap={'50px'}>
          {content_list.map((content, index) => (
            <CoreFeatureCard key={index}>
              <div className={'mobile-features'}>
                <div className={'card-image'}>
                  <GatsbyImage
                    alt={'core-feature'}
                    image={getImage(content.image)}
                  />
                </div>
                <HorizontalLine
                  line={'-10px'}
                  width={'100%'}
                  height={'1px'}
                  color={'#f7f7f7'}
                />
                <div className={'description-content'}>
                  <div className={'description-card'}>
                    <img
                      src={
                        require('../../images/spark-fill-yellow.svg').default
                      }
                      className={'spark-icon'}
                      alt={'spark-fill'}
                    />
                    <span
                      className={'description'}
                      dangerouslySetInnerHTML={{ __html: content.title }}
                    />
                  </div>
                  <Description
                    size={'14px'}
                    lineHeight={'21px'}
                    mobileLine={'21px'}
                    top={'10px'}
                    dangerouslySetInnerHTML={{ __html: content.description }}
                  />
                </div>
                {/*{index === content_list.length - 1 ? (*/}
                {/*  <img*/}
                {/*    className={'spark-features'}*/}
                {/*    src={require('../../images/spark-features.svg').default}*/}
                {/*    alt={'spark-fill'}*/}
                {/*  />*/}
                {/*) : (*/}
                {/*  <div className={'arrow-features'} />*/}
                {/*)}*/}
              </div>
            </CoreFeatureCard>
          ))}
        </Grid>
      </div>
    </Card>
  );
};

export default CoreFeaturesMobile;
