import styled from 'styled-components';
import { responsiveWidth } from '../constants';

export const StyledUl = styled.ul`
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0px;
  background-color: #ffffff;
  color: black;
  font-weight: bold;
`;

export const StyledList = styled.li`
  padding: 10px;
  cursor: pointer;
  min-width: 115px;
`;

export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  min-width: 134px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
`;

export const DropDownList = styled(StyledList)`
  display: inline-block;
  &:hover {
    .gatsby-image-wrapper-constrained {
      transform: rotate(180deg);
    }
    ${DropDownContent} {
      display: block;
    }
  }
`;

export const StyledMenu = styled.a`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  @media ${responsiveWidth.mobile} {
    display: flex;
    align-items: center;
  }
`;

export const SubMenu = styled(StyledMenu)`
  text-decoration: none;
  display: block;
  text-align: left;
  color: ${(props) => (props.active ? '#F39E2B' : '#2b2b2b')};
  background-color: #ffffff;
  padding: 10px;
`;
