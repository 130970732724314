import React from 'react';
import styled from 'styled-components';

const PreferencesSectionWrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  .header {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    font-size: 26px;
    font-weight: bold;
    align-items: center;
  }
  .content {
    padding: 20px;
    font-size: 22px;
    & > *:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;
export default PreferencesSectionWrapper;
