import React, { useEffect, useState } from 'react';
import { GlobalStyle, GlobalStyleV2 } from 'src/styles/globalStyles';
import PageTitle from 'src/components/pageTitle';
import Header from 'src/components/header';
import Footer from 'src/components/footer';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import { GlobalStylePreferences } from 'src/styles/globalStyles';
import PageHelmet from 'src/components/pageHelmet';
import PreferencesContent from 'src/components/cookiePreferences/preferencesContent';

import { InitialLoader } from '../components/preloader/initialLoader';

const CookiePreferences = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "cookie_preferences" } }
          }
        ) {
          nodes {
            frontmatter {
              title
              description
              page_title
              info
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `),
    { title, page_title, background, info, description } = getFrontmatter(data),
    [loader, setLoader] = useState(true);

  let headless =
    typeof window !== 'undefined' &&
    window.location.search.includes('headless');

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);
  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description} />
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStylePreferences />
      {!headless && <Header />}
      <PageTitle title={page_title} info={info} color={'#fbf8f5'} />
      <PreferencesContent />
      {!headless && <Footer hideBanner={true} />}
    </>
  );
};

export default CookiePreferences;
