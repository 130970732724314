import styled from 'styled-components';
import React from 'react';

const FeaturesTab = styled.div`
  margin-bottom: 8px;
  padding: 8px 22px;
  border-radius: 4px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  border-left: 8px solid ${(props) => props.color};
  background-color: #ffffff;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  &.active {
    color: ${(props) => props.color};
    padding: 12px 30px;
    border-radius: 8px;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
  }
`;
export default FeaturesTab;
