import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';

export const FlashCard = styled.div`
  border-radius: ${(props) => props.radius || '15px'};
  box-shadow: ${(props) =>
    props.shadow ||
    '0 12px 24px 0 rgba(0, 0, 0, 0.1), 30px 42px 54px 0 rgba(0, 0, 0, 0.05)'};
  background-color: ${(props) => props.color || '#ffffff'};
  display: flex;
  justify-content: center;
  align-items: ${(props) => props.align || 'center'};
  padding: ${(props) => props.padding || '35px'};
  flex-direction: column;
  position: relative;
  &.tab {
    padding: 0;
    overflow: hidden;
    border-radius: 15px;
    .description {
      padding: 20px;
    }
  }
  @media ${responsiveWidth.mobile} {
    padding: 15px;
  }
`;
export default FlashCard;
