import React, { useEffect, useState } from 'react';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';
import Button from 'src/styles/styledComponents/button';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ButtonOutlined from 'src/styles/styledComponents2/buttonOutlined';
import { navigateToNewTab } from 'src/utility/helpers';

const Stores = ({ page }) => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "stores" } } }
        ) {
          nodes {
            frontmatter {
              title
              icon {
                childImageSharp {
                  gatsbyImageData(width: 112)
                }
              }
              mobile {
                title_1
                title_2
                android_link
                ios_link
                banner_image {
                  childImageSharp {
                    gatsbyImageData(width: 700)
                  }
                }
                tablet_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                android_store_image {
                  childImageSharp {
                    gatsbyImageData(width: 150)
                  }
                }
                apple_store_image {
                  childImageSharp {
                    gatsbyImageData(width: 150)
                  }
                }
                android_image {
                  childImageSharp {
                    gatsbyImageData(width: 150)
                  }
                }
                apple_image {
                  childImageSharp {
                    gatsbyImageData(width: 150)
                  }
                }
              }
              web {
                chrome_extension
                chrome_extension_link
                title_1
                title_2
                webapp
                webapp_link
                banner_image {
                  childImageSharp {
                    gatsbyImageData(width: 783)
                  }
                }
              }
              description
              text_for_mobile
            }
          }
        }
      }
    `),
    { title, mobile, web, text_for_mobile } =
      data.allMarkdownRemark.nodes[0].frontmatter;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function reportWindowSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, []);
  return (
    <div>
      <Card
        bottom={'0px'}
        mobileBottom={'0px'}
        align={'center'}
        color={'#fbf8f5'}
        shadow={page && 'none'}
      >
        <Title marginBottom={'0'} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={'stores-container'}>
          <div className={'store-grid'}>
            <div className={'title'}>
              <span>{web.title_1}</span> <span>{web.title_2}</span>
            </div>
            <GatsbyImage alt={'web'} image={getImage(web.banner_image)} />
            <div className={'action action-buttons web'}>
              <Button className={'stores-button'}>
                <a href={web.webapp_link} target="_blank">
                  {web.webapp}
                </a>
              </Button>
              <ButtonOutlined className={'stores-button'}>
                <a href={web.chrome_extension_link} target="_blank">
                  {web.chrome_extension}
                </a>
              </ButtonOutlined>
            </div>
          </div>
          <div className={'store-grid'}>
            <div className={'title mobile'}>
              <span dangerouslySetInnerHTML={{ __html: mobile.title_1 }} />{' '}
              <span dangerouslySetInnerHTML={{ __html: mobile.title_2 }} />
            </div>
            {windowWidth > 768 ? (
              <GatsbyImage
                alt={'mobile'}
                className={'tablet-image'}
                image={getImage(mobile.tablet_image)}
              />
            ) : (
              <GatsbyImage
                alt={'mobile'}
                image={getImage(mobile.banner_image)}
              />
            )}
            <div className={'action action-buttons'}>
              {windowWidth > 768 ? (
                <>
                { mobile.android_store_image ?
                    <a href={mobile.android_link} target="_blank">
                    <GatsbyImage
                      alt={'android'}
                      image={getImage(mobile.android_store_image)}
                    />
                  </a> : ''}
                { mobile.apple_store_image ?
                  <a href={mobile.ios_link} target="_blank">
                    <GatsbyImage
                      alt={'apple'}
                      image={getImage(mobile.apple_store_image)}
                    />
                  </a> : ''}
                </>
              ) : (
                <>
                  <a href={mobile.ios_link} target="_blank">
                    <GatsbyImage
                      alt={'apple'}
                      image={getImage(mobile.apple_image)}
                    />
                  </a>
                  <a href={mobile.android_link} target="_blank">
                    <GatsbyImage
                      alt={'android'}
                      image={getImage(mobile.android_image)}
                    />
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
      <Card mobileBottom={'0px'} className={'text-for-mobile'}>
        <div>{text_for_mobile}</div>
      </Card>
    </div>
  );
};

export default Stores;
