import styled from 'styled-components';
import { Link } from 'gatsby';
import { responsiveWidth } from 'src/styles/constants';

const LinkText = styled(Link)`
  color: #fd1e16;
  & + * {
    margin-top: 80px;
  }
  @media ${responsiveWidth.mobile} {
    & + * {
      margin-top: 50px;
    }
  }
`;
export default LinkText;
