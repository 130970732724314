import React from 'react';
import PropTypes from 'prop-types';

import {
  ProccesesTable,
  TableHead,
  TableBody,
  TableRow,
} from '../../../styles/components/subprocessors/content';

const SubprocessorTable = ({ processes, deviceType }) => {
  const subprocesses = processes
    .filter((item) => item.device === deviceType && item.status === 'Published')
    .map((e) => ({
      device: e.device,
      title: e.title,
      activities: e.activities,
      license_link: e.license_link,
      license_name: e.license_name,
      location: e.location,
    }));

  return (
    <ProccesesTable>
      <TableHead>
        <li>Subrocessors</li>
        <li>Activity</li>
        <li>License</li>
        <li>Location</li>
      </TableHead>

      <TableBody>
        {subprocesses.map((item, index) => {
          const { title, activities, license_link, license_name, location } =
            item;
          return (
            <TableRow key={index}>
              <li>{title}</li>
              <li>{activities}</li>
              <li>
                <a href={license_link} target="_blank">
                  {license_name}
                </a>
              </li>
              <li>{location}</li>
            </TableRow>
          );
        })}
      </TableBody>
    </ProccesesTable>
  );
};

SubprocessorTable.protoTypes = {
  processes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      activities: PropTypes.string.isRequired,
      license_link: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      device: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
  deviceType: PropTypes.string.isRequired,
};

export default SubprocessorTable;
