import React, { useLayoutEffect, useState } from 'react';
import Input from 'src/components/contact/input';
import TextArea from 'src/components/contact/textArea';
import {
  generateRandomId,
  getFrontmatter,
  validateEmail,
} from 'src/utility/helpers';
import { graphql, useStaticQuery } from 'gatsby';
import Title from 'src/styles/styledComponents/title';
import Description from 'src/styles/styledComponents/description';
import FormWrapper from 'src/styles/styledComponents/formWrapper';
import Button from 'src/styles/styledComponents/button';
import Card from 'src/styles/styledComponents/card';

const ContactForm = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "contact_us" } } }
        ) {
          nodes {
            frontmatter {
              description
              contact_form {
                button_text
                email
                first_name
                last_name
                message
                phone
              }
              title
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `),
    [formValues, setFormValues] = useState({
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      message: null,
    }),
    [isDisabled, setDisable] = useState(true),
    { title, description, contact_form } = getFrontmatter(data),
    handleSubmit = (e) => {
      e.preventDefault();
      window.analytics.identify(generateRandomId(), formValues);
      window.analytics.track('Contact submitted', {
        ...formValues,
      });
    },
    handleFieldChange = (e) => {
      setFormValues({ ...formValues, [e.target.id]: e.target.value });
    };
  useLayoutEffect(() => {
    if (validateEmail(formValues.email)) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [formValues]);
  return (
    <Card>
      <div className={'container contact-us'}>
        <Title
          dangerouslySetInnerHTML={{ __html: title }}
          className={'title'}
        />
        <Description
          dangerouslySetInnerHTML={{ __html: description }}
          className={'description'}
        />
        <FormWrapper>
          <div className={'names'}>
            <Input
              label={contact_form.first_name}
              id={'first_name'}
              onChange={(e) => handleFieldChange(e)}
            />
            <Input
              label={contact_form.last_name}
              id={'last_name'}
              onChange={(e) => handleFieldChange(e)}
            />
          </div>
          <div className={'contact'}>
            <Input
              label={contact_form.email}
              type={'email'}
              id={'email'}
              onChange={(e) => handleFieldChange(e)}
            />
            <Input
              label={contact_form.phone}
              type={'tel'}
              id={'phone'}
              onChange={(e) => handleFieldChange(e)}
            />
          </div>
          <div className={'message'}>
            <TextArea
              label={'Message'}
              style={{ width: 'calc(100% - 40px)', minHeight: '220px' }}
              id={'message'}
              onChange={(e) => handleFieldChange(e)}
            />
          </div>
          <Button isDisabled={isDisabled} onClick={(e) => handleSubmit(e)}>
            {contact_form.button_text}
          </Button>
        </FormWrapper>
      </div>
    </Card>
  );
};

export default ContactForm;
