export const featuresSection = [
  'collect',
  'organize',
  'create-sparks',
  'store',
  'share',
];

export const productsPages = [
  'products-webapp',
  'products-mobile',
  'products-webclipper',
];

export const Statuses = Object.freeze({
  PUBLISHED: 'Published',
  DRAFT: 'Draft',
  IN_APPROVAL: 'In Approval',
});

export const planIds = Object.freeze({
  freePlan: 'sp_free',
  freeTrailPlan: 'free_7_day_premium_trial',
  payNowMothlySubscriptionWithDiscount: 'pay_now_monthly_subscription_discount',
  payNowAnnualSubscriptionWithDiscount: 'pay_now_annual_subscription_discount',
  premiumMonthly: 'premium_monthly',
  premiumAnnually: 'premium_annual_with_trial',
  payNowMothlySubscription: 'pay_now_monthly_subscription',
  payNowAnnualSubscription: 'pay_now_annual_subscription',
  mobilePremiumAnnually: 'premium_annual',
});
