import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';
import { breakPoints } from '../constants';

const StyledComparison = styled.div`
  width: 100%;
  text-align: left;
  .comparison_component {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    .pricing-card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      border-radius: 10px;
      .feature {
        display: grid;
        grid-template-columns: 350px 300px 300px;

        @media (min-width: ${breakPoints.pcSmall}) {
          grid-template-columns: 350px 160px 240px;
        }

        @media (min-width: ${breakPoints.pc}) {
          grid-template-columns: 350px 160px 240px;
          .features {
            .info {
              margin-right: 10px;
            }
          }
        }

        @media (min-width: ${breakPoints.pcLarge}) {
          grid-template-columns: 350px 160px 240px;
        }
      }
    }
    & > div {
      display: grid;
      grid-template-columns: 450px 200px 200px;

      @media (min-width: ${breakPoints.pcSmall}) {
        grid-template-columns: 450px 200px 200px;
      }

      @media (min-width: ${breakPoints.pc}) {
        grid-template-columns: 425px 200px 225px;
        .features {
          .info {
            margin-right: 10px;
          }
        }
      }

      @media (min-width: ${breakPoints.pcLarge}) {
        grid-template-columns: 450px 200px 200px;
      }
    }
  }
  .head {
    .basic,
    .premium {
      padding: 10px;
      & > div {
        height: 137px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .premium {
      border-left: 1px solid #f2f4f9;
    }
    .basic > div {
      border-radius: 4px;
      background-image: linear-gradient(114deg, #e1e5e6, #8e9eab);
    }
    .premium > div {
      border-radius: 4px;
      background-image: linear-gradient(114deg, #ffe259, #ffa751);
    }
    .name {
      font-size: 22px;
      color: #fff;
      font-weight: bold;
    }
    .price {
      font-size: 32px;
      font-weight: bold;
      color: #000;
    }
    .duration {
      font-size: 22px;
      color: rgba(0, 0, 0, 0.3);
    }
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    font-size: 35px;
    font-weight: bold;
    padding: 30px;
    text-transform: uppercase;
    &.plan {
      padding: 20px;
      font-size: 30px;
      height: 200px;
    }
  }
  .title-container {
    .free-plan {
      border-right: 2px solid #dfdfdf;
    }
    .heading {
      padding: 10px 5px;
      color: #2c3038;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &:not(:first-child) {
        @media (min-width: ${breakPoints.pcLarge}) {
          margin-top: 50px;
        }
      }

      & > *:not(:first-child) {
        margin-top: 50px;
        text-align: center;
        justify-content: center;
      }
    }
  }
  .feature {
    .info {
      margin-left: 10px;
      margin-top: 5px;
      position: relative;
      display: inline-block;
      cursor: pointer;
      padding-right: 12px;

      .info-hover {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        @media (min-width: ${breakPoints.pcLarge}) {
          top: 3px;
        }
      }
      &.info:hover .info-hover {
        display: inline;
        padding: 0px !important;
      }
    }

    & > div {
      padding: 13px 10px;
      font-size: 22px;
      display: flex;
      align-items: center;
      &:first-child {
        justify-content: flex-start;
        .gatsby-image-wrapper {
          margin-right: 15px;
        }
      }
      &:not(:first-child) {
        text-align: center;
        justify-content: center;
        font-weight: 600;
      }
    }
  }
  .action {
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px 0;
      &.__react_component_tooltip {
        padding: 8px;
        width: 200px;
      }
    }
  }
  // @media ${responsiveWidth.mobile} {
  //   display: none;
  // }
`;
export default StyledComparison;
