import { createGlobalStyle } from 'styled-components';

export const InitialLoaderStyle = createGlobalStyle`
  body {
    overflow: auto;
  }
  #preloader {
    width: 98%;
    height: 98%;
    transition: opacity 0.5s ease-in-out;
  }

  #loader {
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
  }

  .initial-loading {
    opacity: 1;
    z-index: 100;

    .anticon-spin {
      display: inline-block;
      animation: loadingCircle 1s infinite linear;
    }
  }
  .ant-spin {
    color: #ffb22b;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background:  #ffffff;
    position: absolute;
    padding: 0;
    box-sizing: border-box;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86); 
  }
`;
