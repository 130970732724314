/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import StyledModal from 'src/styles/styledComponents/styledModal';
import Backdrop from 'src/styles/styledComponents/backDrop';
import { StaticImage } from 'gatsby-plugin-image';
import ModalButton from 'src/styles/styledComponents/modalButton';
import ModalButton1 from 'src/styles/styledComponents/modalButton1';
// import { Loader } from '../preloader/loader';

const InfoModal = ({ visible, setVisible, data, onOk, currentPlan = null }) => {
  const [loading, setLoading] = useState(false),
    renderBackdrop = (props) => <Backdrop {...props} />;

  return (
    <StyledModal
      show={visible}
      onHide={setVisible}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <div>
        <div className={'image-container'}>
          <div className={'water-mark'}>
            <StaticImage
              src={'../../images/spark-watermark.png'}
              alt={'watermark'}
            />
          </div>
          <div className={'info-icon'}>
            <StaticImage src={'../../images/info.png'} alt={'info'} />
          </div>
        </div>
        <div className={'content'}>
          <div
            className={'title'}
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>

        {data.ok_button && (
          <div className={'actions'}>
            {data.cancel_button && (
              <ModalButton onClick={() => setVisible(false)}>
                {data.cancel_button}
              </ModalButton>
            )}
            {data.ok_button != 'undefined' && (
              <ModalButton
                onClick={() => {
                  onOk();
                  setLoading(true);
                }}
                type={'ok'}
              >
                {data.ok_button}
              </ModalButton>
            )}
            {/*{loading ? <Loader /> : null}*/}
          </div>
        )}

        {data.button && (
          <ModalButton1 className={'blue'} onClick={() => setVisible(false)}>
            {data.button}
          </ModalButton1>
        )}

      </div>
    </StyledModal>
  );
};

export default InfoModal;
