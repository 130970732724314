/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import React, { useState } from 'react';
import Card from 'src/styles/styledComponents/card';
import Button from 'src/styles/styledComponents/button';
import PreferencesSection from 'src/components/cookiePreferences/preferencesSection';
import { getFrontmatter } from 'src/utility/helpers';
import { graphql, useStaticQuery } from 'gatsby';

const PreferencesContent = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "preferences_sections" } }
          }
        ) {
          nodes {
            frontmatter {
              button_text
              preferences_sections {
                descriptions
                title
                key
              }
            }
          }
        }
      }
    `),
    { button_text, preferences_sections } = getFrontmatter(data);
  const [cookieForm, setCookieForm] = useState({
    strictly_necessary_cookies: true,
    functionality_cookies: false,
    tracking_and_performance_cookies: false,
    marketing_cookies: false,
    do_not_sell_my_information: false,
  }),
    setCookieSection = (value) => {
      setCookieForm({ ...cookieForm, ...value });
    },
    activateCookie = () => {
      console.log(cookieForm);
    };
  return (
    <Card color={'#fbf8f5'}>
      {preferences_sections.map((section, index) => (
        <PreferencesSection
          key={index}
          title={section.title}
          descriptions={section.descriptions}
          sectionKey={section.key}
          setCookieSection={setCookieSection}
        />
      ))}
      <Button onClick={activateCookie}>{button_text}</Button>
    </Card>
  );
};

export default PreferencesContent;
