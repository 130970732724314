import React from 'react';
import Title from 'src/styles/styledComponents/title';
import { graphql, useStaticQuery } from 'gatsby';
import BreadCrumb from '../breadCrumb';
import { getFrontmatterOf } from '../../utility/helpers';

const OverlayHero = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "features_hero" } } }
        ) {
          nodes {
            id
            frontmatter {
              titles
              background {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 2.5)
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
        header: allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "header" } } }
        ) {
          nodes {
            frontmatter {
              action_buttons
            }
            id
          }
        }
      }
    `),
    { titles } = data.allMarkdownRemark.nodes[0].frontmatter,
    { action_buttons } = getFrontmatterOf(data, 'header');
  const breadCrumb = [
    { label: 'Home page', path: '/' },
    { label: action_buttons[1] },
  ];

  return (
    <div className={'features-hero'}>
      <BreadCrumb breadCrumb={breadCrumb} />
      <Title marginBottom={'0'} className={'underLine'}>
        {titles[0]}
      </Title>
    </div>
  );
};

export default OverlayHero;
