import React, { useEffect, useState } from 'react';
import {
  GlobalStyle,
  GlobalStyleHome,
  GlobalStyleV2,
} from 'src/styles/globalStyles';
import Header from 'src/components/header';
import Features from 'src/components/home/features';
import Footer from 'src/components/footer';
import Transformation from 'src/components/home/transformation';
import Stores from 'src/components/home/stores';
import Faq from 'src/components/home/faq';
import Discover from 'src/components/home/discover';
import UseCase from 'src/components/home/useCases';
import Hero from 'src/components/home/hero';
import Challenges from 'src/components/home/challenges';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatterOf } from 'src/utility/helpers';
import PageHelmet from 'src/components/pageHelmet';
import CallToAction from 'src/components/home/callToAction';
import CallToActionSignup from 'src/components/home/callToActionSignup';
import { InitialLoader } from '../components/preloader/initialLoader';

function IndexOld() {
  const data = useStaticQuery(graphql`
      query {
        page: allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "home_page_detail" } } }
        ) {
          nodes {
            frontmatter {
              title
              description
            }
          }
        }
      }
    `),
    { title, description } = getFrontmatterOf(data, 'page'),
    [loader, setLoader] = useState(true);

  let headless =
    typeof window !== 'undefined' &&
    window.location.search.includes('headless');

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 400);
    const script = document.createElement('script');

    script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
    script.id = 'jiraWidget';
    script.setAttribute('async', true);
    script.setAttribute('data-jsd-embedded', '');
    script.setAttribute('data-key', '3d04fff2-8ce2-4192-ac3c-ca86cc5581e3');
    script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');

    !headless && document.body.appendChild(script);

    script.addEventListener('load', (event) => {
      window.document.dispatchEvent(
        new Event('DOMContentLoaded', {
          bubbles: true,
          cancelable: true,
        })
      );
    });
    return () => {
      document.body.removeChild(script);
    };
    // const cookies = new Cookies();
    // const isAuth = cookies.get('user_name_sparkpico');
    // const noRedirect = window.location.search.includes('?noredirect');
    // const isDesktop = screen.width > 768;
    // const appUrl = process.env.GATSBY_APP_URL;

    // if (isAuth && !noRedirect && isDesktop) {
    //   window.location.replace(appUrl);
    // }
  }, []);

  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description}>
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </PageHelmet>
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStyleHome />
      <Header />
      <Hero />
      <Transformation />
      <Discover />
      <CallToActionSignup />
      <Features />
      <UseCase />
      <Challenges />
      <CallToAction />
      <Faq />
      <Stores />
      <Footer />
      {/* <Video />
      <MeetSparkpico /> */}
    </>
  );
}

export default IndexOld;
