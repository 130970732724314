import 'src/css/typography.css';

// eslint-disable-next-line prettier/prettier
export const basicFont = '\'AvertaStd\', serif';

export const cardShadow = '0 3px 9px 0 rgba(0, 0, 0, 0.07)';

export const highlightGreen = '#00ad87';

export const borderRadius = '8px';

export const cardBackground = '#ffffff';

export const buttonStyles = {
  primary: {
    backgroundColor: '#fe6152',
    shadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    color: '#ffffff',
    hoverColor: '#e44637',
  },
  secondary: {
    backgroundColor: '#fbf8f5',
    shadow: 'unset',
    color: '#FB6357',
    hoverColor: '#fbf8f5cc',
  },
  pricing_free: {
    backgroundColor: '#46c0a4',
    shadow: 'unset',
    color: '#fff',
    hoverColor: '#46c0a4cc',
  },
  pricing_premium: {
    backgroundColor: '#ffa303',
    shadow: 'unset',
    color: '#fff',
    hoverColor: '#ffa303cc',
  },
};

export const containerWidth = '1150px';

export const responsiveWidth = {
  mobile: '(max-width : 768px)',
  heroLayout: '(max-width : 1070px)',
  mobileHeroLayout: '(max-width : 1000px)',
};

export const breakPoints = {
  mobileSmall: '320px',
  mobileLarge: '576px',
  tablet: '768px',
  pcSmall: '992px',
  pc: '1200px',
  pcLarge: '1600px',
};

export const cardColors = [
  '#7b68ee',
  '#fd71af',
  '#49ccf9',
  '#27ae60',
  '#ffc800',
];

export const plansSectionColors = [
  '#fb6357',
  '#89b9ff',
  '#FEC75D',
  '#1cb699',
  '#587FCB',
];

export const plansSectionShadow = [
  '#fb635708',
  '#89b9ff0f',
  '#fbf8F5',
  '#1cb69908',
  '#587FCB08',
];

export const discoverColors = [
  '#3fc1a4',
  '#45cfe3',
  '#ff99b1',
  '#ffc853',
  '#fe6155',
];

export const discoverBackground = [
  '#f9fffe',
  '#f8feff',
  '#fffbfc',
  '#fffefb',
  '#fffdfb',
];

export const contentColors = ['#0090ed', '#ff5c4c', '#f47693'];

export const transformationColors = ['#56CAAD', '#89B9FF', '#FFD482'];

export const roadmapColors = ['#72BE47', '#FFA303', '#4988E3'];

export const shareColors = ['#46C0A4', '#89B9FF', '#F8DA99'];

export const storeColors = ['#FB6357', '#FEC75D', '#FD9AB2'];

export const featureColors = ['#46C0A4', '#89B9FF', '#FD9AB2', '#FEC75D'];
