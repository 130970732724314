import React from 'react';
import styled from 'styled-components';
import { responsiveWidth } from 'src/styles/constants';
import { breakPoints } from '../constants';

const PlanCard = styled.div`
  position: relative;
  box-shadow: ${(props) =>
    props.type === 'recommended'
      ? '0 2px 7px 2px rgba(255, 163, 3, 0.16)'
      : '0 2px 7px 2px rgba(28, 152, 128, 0.1)'};
  background-color: #fff;
  margin-top: 40px;
  padding: 0 20px 24px 20px;
  border-radius: 8px;
  color: #616161;
  box-sizing: border-box;
  .mt-38 {
    margin-top: 38px !important;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }
  .name {
    font-size: 40px;
    font-weight: bold;
    color: ${(props) => (props.type === 'recommended' ? '#FFA303' : '#46C0A4')};
  }
  .dollar {
    font-size: 38px;
    font-weight: bold;
    color: ${(props) => (props.type === 'recommended' ? '#FFA303' : '#46C0A4')};
    position: absolute;
    left: -30px;
    top: 30px;
  }
  .price {
    color: ${(props) => (props.type === 'recommended' ? '#FFA303' : '#46C0A4')};
    position: relative;
  }
  .description {
    font-size: 32px;
    font-weight: 600;
  }
  .duration {
    font-size: 22px;
    font-weight: 600;
    color: #000;
  }
  .discount {
    font-size: 14px;
  }
  .badge {
    position: absolute;
    transform: translate(45px, -10px);
  }
  .features {
    align-items: center;
    margin-top: 50px;
    margin-bottom: 30px;
    cursor: pointer;
    & > div:not(:last-child) {
      margin-bottom: 10px;
    }
    span {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .pricing-detail {
    margin: 0 -40px;
  }
  .pricing-rate {
    display: flex;
    flex-direction: column;
    margin-top: -90px;
    .premium-label {
      color: #ffffff;
      font-weight: bold;
      font-size: 14px;
    }
    .premium-discount {
      font-size: 12px;
      color: #ffffff;
    }
    .premium-amount {
      color: #ffffff;
      font-size: 40px;
      font-weight: bold;
      position: relative;
      .pricing-dollar {
        font-size: 24px;
        position: absolute;
        left: 94px;
        @media (min-width: 1440px) {
          left: 84px;
        }
      }
      .pricing-period {
        font-size: 10px;
        font-weight: 500;
      }
    }
  }
  button {
    height: 37px;
    max-width: 182px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: AvertaStd-Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: auto;
    border-radius: 100px;
  }

  .recommend-ribbon {
    position: absolute;
    top: -32px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
    border-radius: 4px;
    background: #a393f5;
    box-shadow: 0px 4px 24px 0px rgba(123, 97, 255, 0.31);
    min-width: 133px;
    height: 32px;
    color: #fff;
    font-family: AvertaStd-Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.best-seller {
      background: #FB6357;
    }
  }

  &[type='recommended'] {
    margin-top: 15px;
    padding: 0;
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 27px;
      border-radius: 8px 8px 0px 0px;
      background-color: #ffa303;
      margin-bottom: 8px;
    }
    .content {
      padding: 0 20px 24px 20px;
    }
    .toggle-switch {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffa303;
      .plan-duration {
        color: #ffffff;
        font-family: AvertaStd-Bold;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .price-container {
      .discounts {
        position: relative;
        .instant-discount-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          max-height: 49px;
          padding: 16px 17px;
          border-radius: 4px;
          background: #ffa303;
          box-shadow: 0 4px 6px 0 rgba(255, 147, 0, 0.4);
          position: absolute;
          left: -40px;
          top: -10px;
          
          &.savings-active {
            background: #FFE4C1;
            .discount-details {
              color: #F09000;
            }
            
            .switch-label {
              border: none;
              .switch-button {
                background: #FB6357 !important;
              }
            }
          }

          .discount-details {
            color: #fff;
            text-align: center;
            font-family: AvertaStd-Semibold;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          &.savings-active {
            &::before {
              border-color: transparent #FFE4C1 transparent transparent;
            }
          }

          &::before {
            content: '';
            position: absolute;
            top: 20px;
            left: 0;
            border-width: 20px 20px 25px 0;
            border-style: solid;
            border-color: transparent #ffa303 transparent transparent;
          }

          .toggle-switch {
            .switch-label {
              .switch-button {
                background: #a393f5 !important;
              }
            }
          }
        }
      }
    }
    .dollar {
      text-align: center;
      font-family: AvertaStd-Bold;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      left: -20px;
      top: 0;
    }
    .name {
      margin-bottom: 16px;
      font-size: 32px;
      text-transform: uppercase;
      &.savings-active {
        margin-top: 13px;
        color: #FFA303;
        font-family: AvertaStd-Bold;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }
    }
    .price {
      font-family: AvertaStd-Bold;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 16px;
    }
    .features {
      margin-top: 58px;
    }
    .duration {
      color: #616161;
      font-family: AvertaStd-Semibold;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .price-payment-info {
      color: #616161;
      text-align: center;
      font-family: AvertaStd-Regular;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 30px;
      .highlight {
        color: #616161;
        font-family: AvertaStd-Bold;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .instant-discount-wrapper {
      .switch-button {
        background: #a393f5;
      }
    }

    .plans_intro_button {
      box-shadow: none !important;
    }
  }

  &[type='free'] {
    padding: 24px 20px;
    border-top: 20px solid #c6ddff;
    .name {
      color: #000000;
      font-size: 24px;
      margin-bottom: 16px;
    }
    .dollar {
      color: #c6ddff;
      text-align: center;
      font-family: AvertaStd-Bold;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      left: -20px;
      top: 0;
    }

    .price {
      color: #c6ddff;
      font-family: AvertaStd-Bold;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .description {
      color: #616161;
      font-family: AvertaStd-Semibold;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .plans_intro_button {
      background-color: #c6ddff;
    }

    .features {
      margin-top: 34px;
    }
  }

  &[type='freeTrial'] {
    padding: 24px 20px;
    border-top: 20px solid #46c0a4;
    .name {
      font-size: 24px;
      margin-bottom: 16px;
    }
    .price-payment-info {
      font-family: AvertaStd-Regular;
      font-size: 12px;
      font-weight: 400;
    }
    .dollar {
      text-align: center;
      font-family: AvertaStd-Bold;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      left: -20px;
      top: 0;
    }
    .price {
      font-family: AvertaStd-Bold;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 16px;
    }
    .description {
      font-family: AvertaStd-Semibold;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .features {
      margin-top: 34px;
    }
  }

  @media (min-width: ${breakPoints.pcLarge}) {
    padding: 30px 48px;

    .discount {
      font-size: 17px;
    }

    .description {
      font-size: 36px;
    }

    .label {
      transform: rotate(90deg);
      position: relative;
      top: 338px;
      left: 363px;
    }

    button {
      font-size: 22px;
    }

    .features {
      margin: 58px 0 0 0;

      div {
        margin-bottom: 14px;

        span {
          font-size: 31px;
        }
      }
    }
    .price-container {
      .instant-discount-wrapper {
        left: -70px;
      }
    }
  }

  @media (min-width: ${breakPoints.pc}) {
    padding: 20px 40px;

    .discount {
      font-size: 15px;
    }

    .description {
      font-size: 32px;
    }

    .label {
      transform: rotate(90deg);
      position: relative;
      top: 320px;
      left: 322px;
    }

    .features {
      margin: 47px 0 0 0;

      div {
        margin-bottom: 13px;

        span {
          font-size: 27px;
        }
      }
    }
    .price-container {
      .instant-discount-wrapper {
        left: -60px;
      }
    }
  }

  @media (min-width: ${breakPoints.pcSmall}) {
    padding: 30px 27px;

    .discount {
      font-size: 12px;
    }

    .description {
      font-size: 26px;
    }

    .label {
      transform: rotate(90deg);
      position: relative;
      top: 300px;
      left: 304px;
    }

    button {
      font-size: 14px;
    }

    .features {
      margin: 43px 0 16px 0;
      div {
        margin-bottom: 10px;
        span {
          font-size: 20px;
        }
      }
    }

    .price-container {
      .instant-discount-wrapper {
        left: -48px;
      }
    }
  }

  @media ${responsiveWidth.mobile} {
    transform: translateX(0);
    padding: 20px;
    text-align: center;
    margin-bottom: 100px;
    .label {
      transform: rotate(0) translate(-50%, -100%);
      top: 0;
      left: 50%;
      border-radius: 8px 8px 0 0;
    }
    .badge {
      transform: translate(45px, 25px);
    }
    .pricing-detail {
      display: none;
    }
    .price-container {
      .instant-discount-wrapper {
        left: -40px;
      }
    }
    .features {
      margin: 43px 0 16px 0;
    }
    button {
      font-size: 14px;
    }
  }
`;
export default PlanCard;
