import { Link } from 'gatsby';
import React from 'react';
import VerticalLine from '../styles/styledComponents2/verticalLine';

const BreadCrumb = ({ breadCrumb, padding }) => (
  <nav style={{ display: 'flex', justifyContent: 'center' }}>
    <div className={'container breadcrumb'} style={{ paddingBottom: padding }}>
      {breadCrumb.map((breadCrumb, index) => (
        <div key={index} className={'navigation'}>
          {breadCrumb.path ? (
            <>
              <Link
                to={breadCrumb.path.toString()}
                className={'breadCrumb-text'}
              >
                {breadCrumb.label}
              </Link>
              {breadCrumb.length - 1 === index ? (
                ' '
              ) : (
                <VerticalLine left={'20px'} color={'#C4C4C4'} />
              )}
            </>
          ) : (
            <p>{breadCrumb.label}</p>
          )}
        </div>
      ))}
    </div>
  </nav>
);
export default BreadCrumb;
