/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import Header from 'src/components/header';
import Footer from 'src/components/footer';
import {
  GlobalStyle, GlobalStyleHome,
  GlobalStylePlans,
  GlobalStyleV2,
} from 'src/styles/globalStyles';
import PricingFaq from 'src/components/plans/pricingFaq';
import PlansIntro from 'src/components/plans/plansIntro';
import PlansDetailed from 'src/components/plans/plansDetailed';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter, getFrontmatterOf } from 'src/utility/helpers';
import PageHelmet from 'src/components/pageHelmet';
import { InitialLoader } from '../components/preloader/initialLoader';
import PlanCard from 'src/components/plans/currentPlan';
import CustomModal from '../components/plans/customModal';
import { getPlanCardsAlerts } from '../queries/graphql/plans/alert';
import useFetchData from '../components/plans/fetchPlan';
import Stores from '../components/home/stores';
import BreadCrumb from '../components/breadCrumb';
import PlansCallToAction from '../components/plans/callToAction';

const Plans = () => {
  const data = useStaticQuery(graphql`
      query {
  allMarkdownRemark(
    filter: {frontmatter: {section_name: {eq: "plans_page_data"}}}
  ) {
    nodes {
      frontmatter {
        title
        description
        page_title
        background {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
  header: allMarkdownRemark(filter: {frontmatter: {section_name: {eq: "header"}}}) {
    nodes {
      frontmatter {
        action_buttons
      }
      id
    }
  }
}
    `),
    { title, description } = getFrontmatter(data),
    { action_buttons } = getFrontmatterOf(data, 'header'),
    [loader, setLoader] = useState(true),
    [customModal, setCustomModal] = useState(false);

  let headless =
    typeof window !== 'undefined' &&
    window.location.search.includes('headless'),
    access_token =
      typeof window !== 'undefined' &&
        window.location.search.includes('access_token=')
        ? new URL(window.location.href).searchParams.get('access_token')
        : false,
    modalId = null,
    modal = null,
    plan_cancellation =
      typeof window !== 'undefined' &&
      window.location.search.includes('plan_cancellation'),
    remove_cancellation =
      typeof window !== 'undefined' &&
      window.location.search.includes('remove_cancellation'),
    purchase_status =
      typeof window !== 'undefined' &&
      window.location.search.includes('purchase_status'),
    plan_id = null,
    payment_plan = null,
    hide = false,
    current_plan_type = null;

  if (plan_cancellation || remove_cancellation || purchase_status) {
    if (plan_cancellation) modalId = 'plan_cancellation';

    if (remove_cancellation) modalId = 'remove_cancellation';

    if (purchase_status) modalId = 'purchase_status';

    modal = getPlanCardsAlerts(modalId);
  }

  useEffect(() => {
    window.analytics.page();
    setCustomModal(Boolean(modal));
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);

  if (headless && access_token) {
    payment_plan = useFetchData(access_token);
    plan_id = payment_plan.plan_id;
    current_plan_type = payment_plan.current_plan_type;

    if (current_plan_type == 'premium') {
      hide = Boolean(current_plan_type);
    }
  }

  const breadCrumb = [{ label: 'Home page', path: '/' }, { label: action_buttons[2] }];


  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description} />
      {/*Styles*/}
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStyleHome />
      <GlobalStylePlans />
      {/*Page Contents component starts here*/}
      {!headless && <Header />}
      {!headless && <div className={'pricing'}><BreadCrumb breadCrumb={breadCrumb} padding={'0px'}/></div>}
      {/* The below component is used to display the current plan details in Mobile and Webapp's settings page */}
      <PlanCard currentPlan={payment_plan} />
      {/*Shows the plans details for selection*/}
      <PlansIntro currentPlan={payment_plan} />
      {!hide && <PlansDetailed />}
      {!headless && <PlansCallToAction />}
      <PricingFaq headless={!headless}/>
      <div className={'plans-page-stores'}><Stores /></div>
      {!headless && <Footer />}

      {modal && (
        <CustomModal
          visible={customModal}
          setVisible={setCustomModal}
          data={{
            title: modal.title,
            content: modal.description,
            button: modal.button,
          }}
          currentPlan={payment_plan}
        />
      )}
    </>
  );
};

export default Plans;
