import styled from 'styled-components';
import Card from 'src/styles/styledComponents/card';
import { responsiveWidth, breakPoints } from 'src/styles/constants';

const CombinedHeroMobileWrapper = styled(Card)`
  padding: 0px;
  background-image: linear-gradient(#fff 50%, #fbf8f5 40%);
  .image {
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    & > img :nth-child(2) {
      margin-left: 20px;
    }
  }
  .container {
    padding: 0px 90px 10px 10px;
    box-sizing: border-box;
    &:nth-child(2) {
      > div:first-child {
        position: relative;
        top: 21px;
      }
    }
  }
  .image-mobile {
    position: absolute;
    right: 0px;
    width: 446px;
    top: -50px;
    transform: translateY(-50%);
    .gatsby-image-wrapper {
      img {
        object-fit: contain !important;
        max-height: 575px !important;
      }
    }
  }

  & > div {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    position: relative;
    grid-gap: 20px;
  }
  @media ${responsiveWidth.heroLayout} {
    .container {
      padding: 0px 90px 10px 70px;
    }
  }
  @media ${responsiveWidth.mobile} {
    padding: 20px;
    .container-image {
      display: grid;
      grid-gap: 10px;
      img {
        height: 100px;
      }
    }
    .container-description {
      display: flex;
      flex-direction: column;
      .images {
        display: grid;
        grid-gap: 10px;
        align-items: center;
        justify-content: center;
      }
    }
    .quotes {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;
export default CombinedHeroMobileWrapper;
