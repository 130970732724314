import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from '../utility/helpers';
import { InitialLoader } from '../components/preloader/initialLoader';
import PageHelmet from '../components/pageHelmet';
import {
  GlobalStyle,
  GlobalStylePlans,
  GlobalStyleV2,
} from '../styles/globalStyles';
import Header from '../components/header';
import Footer from '../components/footer';
import NotificationsCard from '../components/announcements/notificationsCard';
const Announcements = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "announcement_page_data" } }
          }
        ) {
          nodes {
            frontmatter {
              page_title
            }
            id
          }
        }
      }
    `),
    { page_title } = getFrontmatter(data),
    [loader, setLoader] = useState(true);
  let headless =
    typeof window !== 'undefined' &&
    window.location.search.includes('headless');

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);
  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={page_title} />
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStylePlans />
      {!headless && <Header />}
      <NotificationsCard />
      {!headless && <Footer />}
    </>
  );
};

export default Announcements;
