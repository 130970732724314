import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import Collect from 'src/components/features/collect';
import Organize from 'src/components/features/organize';
import ShareAndSpark from 'src/components/features/shareAndSpark';
import ScrollSpy from 'react-scrollspy-navigation';
import FeatureScrollspy from 'src/styles/styledComponents/featureScrollspy';

const FeatureContent = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "features_page_content" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              tab_list {
                title
                description
                button
                link
                content_list {
                  description
                  title
                  image {
                    base
                  }
                  detailed_description_in_points {
                    point_1
                    point_2
                    point_3
                    point_4
                  }
                }
              }
            }
          }
        }
      }
    `),
    { tab_list } = getFrontmatter(data);
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  return (
    <>
      <FeatureScrollspy>
        <ScrollSpy offsetTop={123}>
          <a href={'#collect'} ref={React.createRef()}>
            <div>{tab_list[0].title}</div>
          </a>
          <a href={'#store'} ref={React.createRef()}>
            <div>{tab_list[1].title}</div>
          </a>
          <a href={'#organize'} ref={React.createRef()}>
            <div>{tab_list[2].title}</div>
          </a>
          <a href={'#create-sparks'} ref={React.createRef()}>
            <div>{tab_list[3].title}</div>
          </a>
          <a href={'#share'} ref={React.createRef()}>
            <div>{tab_list[4].title}</div>
          </a>
        </ScrollSpy>
      </FeatureScrollspy>
      <Collect
        data={tab_list[0]}
        id={'collect'}
        color={'#fcf8f5'}
        titles={'Collect'}
        bottom={'65px'}
      />
      <Collect
        color={'#fcf8f5'}
        data={tab_list[1]}
        id={'store'}
        titles={'Organize'}
        bottom={'80px'}
      />
      <Collect
        data={tab_list[2]}
        id={'organize'}
        titles={'Create Sparks'}
        color={'#fcf8f5'}
        bottom={'65px'}
      />
      <Organize data={tab_list[3]} />
      <ShareAndSpark data={tab_list[4]} />
    </>
  );
};

export default FeatureContent;
