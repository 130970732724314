import React from 'react';
import styled from 'styled-components';

const CookieBannerWrapper = styled.div`
  padding: 20px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 99;
  background-color: #fff;
  transform: translateY(
    ${(props) => (props.visible ? '0' : 'calc(100% + 20px)')}
  );
  transition: transform 0.5s;
  max-width: 300px;
  border: 1px solid black;
  text-align: center;
  #gatsby-focus-wrapper & {
    border-radius: 10px;
  }
  .gatsby-image-wrapper {
    margin-bottom: 10px;
  }
  .description {
    margin-bottom: 20px;
  }
`;

export default CookieBannerWrapper;
