import React, { useState } from 'react';
import InputWrapper from 'src/styles/styledComponents/inputWrapper';

const Input = ({ label, id, ...props }) => {
  const [isFocus, setFocus] = useState(false);

  return (
    <InputWrapper isFocus={isFocus}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        onFocus={() => setFocus(true)}
        onBlur={(e) => !e.target.value && setFocus(false)}
        {...props}
      />
    </InputWrapper>
  );
};

export default Input;
