import React, { useEffect, useState } from 'react';
import Button from 'src/styles/styledComponents/button';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';
import Description from 'src/styles/styledComponents/description';

const PlansCallToAction = ({ page }) => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "home_call_to_action" } }
          }
        ) {
          nodes {
            frontmatter {
              description
              titles
              button_text
              navigation_link
              register_link
            }
            id
          }
        }
      }
    `),
    { titles, description, button_text, navigation_link, register_link } =
      getFrontmatter(data);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function reportWindowSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, []);
  const deviceType = () => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      typeof window !== 'undefined' && (window.location.href = navigation_link);
    }
    if (/iPad|iPhone|iPod/i.test(userAgent)) {
      typeof window !== 'undefined' && (window.location.href = navigation_link);
    }
  };

  return (
    <Card align={'center'} color={page ? '#ffffff' : '#fbf8f5'}>
      <Title marginBottom={'20px'}>
        <span style={{ color: '#fe6252' }}>{titles[0]}</span>
        <span> {titles[1]}</span>
      </Title>
      <Description
        marginBottom={'20px'}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {windowWidth > 900 ? (
        <Button>
          <a href={register_link} className="home_call_to_action_link">
            {button_text}
          </a>
        </Button>
      ) : (
        <Button onClick={() => deviceType()}>
          <a className="home_call_to_action_link">{button_text}</a>
        </Button>
      )}
    </Card>
  );
};

export default PlansCallToAction;
