import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import ImageWithTitleDescription from 'src/styles/styledComponents2/imageWithTitleDescription';
import Topic from 'src/styles/styledComponents/topic';
import ContentWrapper from 'src/styles/styledComponents2/contentWrapper';
import ContentCard from 'src/styles/styledComponents/ContentCard';
import { contentColors } from 'src/styles/constants';
import Card from 'src/styles/styledComponents/card';

const AboutUsContent = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "about_us_content" } } }
        ) {
          nodes {
            frontmatter {
              content_list {
                description
                title
                section_name
                image {
                  base
                }
              }
            }
          }
        }
      }
    `),
    { content_list } = getFrontmatter(data);
  return (
    <Card
      color={'#fbf8f5'}
      align={'center'}
      padding={'100px 90px'}
      id={'content'}
    >
      <div className={'container'}>
        <ContentWrapper>
          {content_list.map((content, index) => (
            <ImageWithTitleDescription
              column={'1fr 1fr'}
              bottom={'140px'}
              gap={'10%'}
              key={index}
            >
              <ContentCard color={contentColors[index]}>
                <div className={'box'}>
                  <div className={'imageBox'} />
                  <img
                    className={'gatsby-image-wrapper'}
                    alt={'content'}
                    src={
                      require(`../../../static/img/${content.image?.base}`)
                        .default
                    }
                  />
                </div>
              </ContentCard>
              <div className={'content'}>
                <Topic
                  color={'#201f18'}
                  underColor={contentColors[index]}
                  size={'22px'}
                  weight={'normal'}
                  bottom={'15px'}
                  dangerouslySetInnerHTML={{ __html: content.section_name }}
                  className={'underline'}
                />
                <Title
                  size={'32px'}
                  marginBottom={'20px'}
                  dangerouslySetInnerHTML={{ __html: content.title }}
                />
                <Description
                  dangerouslySetInnerHTML={{ __html: content.description }}
                />
              </div>
            </ImageWithTitleDescription>
          ))}
        </ContentWrapper>
      </div>
    </Card>
  );
};

export default AboutUsContent;
