import React from 'react';
import Card from 'src/styles/styledComponents/card';
import Description from 'src/styles/styledComponents/description';
import Title from 'src/styles/styledComponents/title';
import Button from 'src/styles/styledComponents/button';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Hero = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "why_sparkpico_hero" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              navigation_buttons
              titles
              link
              description
              info
              show
              image {
                childImageSharp {
                  gatsbyImageData(width: 900)
                }
              }
            }
          }
        }
      }
    `),
    { titles, description, navigation_buttons, info, image, link, show } =
      data.allMarkdownRemark.nodes[0].frontmatter;

  return (
    show === true && (
      <Card color={'#fbf8f5'}>
        <div className={'container hero-container-spark'}>
          <div>
            <Title size={'40px'} marginBottom={'20px'}>
              <span>{titles[0]} </span>
              {titles[1]} <span style={{ color: '#ff98a9' }}>{titles[2]} </span>{' '}
              {titles[3]}
            </Title>
            <Description
              size={'20px'}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className={'actions'}>
              <Button onClick={() => navigate(link)}>
                {navigation_buttons[0]}
              </Button>
            </div>
            <Description
              color={'rgba(0,0,0,.3)'}
              dangerouslySetInnerHTML={{ __html: info }}
            />
          </div>
          <div className={'hero-image-container'}>
            <GatsbyImage alt={'hero'} image={getImage(image)} />
          </div>
        </div>
      </Card>
    )
  );
};

export default Hero;
