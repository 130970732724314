import styled from 'styled-components';

const VerticalLine = styled.div`
  background-color: ${(props) => props.color || '#70757f'};
  height: 20px;
  width: 1px;
  margin-right: 20px !important;
  margin-left: ${(props) => props.left};
  transform: translateY(-2px);
`;
export default VerticalLine;
