import React, { useEffect, useState } from 'react';
import { getFrontmatter } from 'src/utility/helpers';
import {
  GlobalStyle,
  GlobalStyleContact,
  GlobalStyleV2,
} from 'src/styles/globalStyles';
import Header from 'src/components/header';
import PageTitle from 'src/components/pageTitle';
import Footer from 'src/components/footer';
import { graphql, useStaticQuery } from 'gatsby';
import ContactForm from 'src/components/contact/contactForm';
import PageHelmet from 'src/components/pageHelmet';

import { InitialLoader } from '../components/preloader/initialLoader';

const ContactUs = () => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "contact_page_data" } } }
        ) {
          nodes {
            frontmatter {
              title
              description
              page_title
              background {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `),
    { title, page_title, background, description } = getFrontmatter(data),
    [loader, setLoader] = useState(true);

  useEffect(() => {
    window.analytics.page();
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);
  return loader ? (
    <InitialLoader />
  ) : (
    <>
      <PageHelmet title={title} description={description} />
      <GlobalStyle />
      <GlobalStyleV2 />
      <GlobalStyleContact />
      <Header />
      <PageTitle title={page_title} background={background} />
      <ContactForm />
      <Footer />
    </>
  );
};

export default ContactUs;
