import React from 'react';
import Button from 'src/styles/styledComponents/button';
import Card from 'src/styles/styledComponents/card';
import Title from 'src/styles/styledComponents/title';
import Topic from 'src/styles/styledComponents/topic';

import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';

const CallToActionSignup = () => {
  const data = useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: {
            frontmatter: { section_name: { eq: "home_call_to_action_sign_up" } }
          }
        ) {
          nodes {
            frontmatter {
              description
              titles
              link
              button_text
            }
            id
          }
        }
      }
    `),
    { titles, description, link, button_text } = getFrontmatter(data);
  return (
    <Card align={'center'} color={'#fbf8f5'}>
      <Topic
        color={'#70757f'}
        letterSpace={'-0.5px'}
        weight={'600'}
        className={'signup-topic'}
      >
        {titles[0]}
      </Topic>
      <Title
        className={'signup-title'}
        marginBottom={'20px'}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Button>
        <a href={link} className="change_begins_today_sign_up">
          {button_text}
        </a>
      </Button>
    </Card>
  );
};

export default CallToActionSignup;
